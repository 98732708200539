@charset "UTF-8";
/* var */
/* mixin */
/* reset */
html {
  height: 100%;
}

body {
  height: 100%;
  background: #fff;
  color: #0d1240;
  font-size: 16px;
  font-family: "SFUIDisplay", "NotoSansCJKkr", "Roboto", "Droid Sans", "Malgun Gothic", "Helvetica", "Apple-Gothic", "애플고딕", "Tahoma", dotum, "돋움", gulim, "굴림", sans-serif;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
}
@media (min-width: 786px) {
  body {
    font-size: 14px;
  }
}

strong {
  font-weight: bold;
}

body, div,
dl, dt, dd, ul, ol, li,
h1, h2, h3, h4, h5, h6,
pre, form, fieldset, input, select, textarea, p, blockquote, th, td, legend, button, strong, a {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
  color: #0d1240;
}

article, section, nav, header, footer, main {
  display: block;
}

table {
  width: 100%;
  border-spacing: 0;
}
table th {
  font-weight: normal;
}

form, fieldset, img {
  border: 0 none;
}

ul, ol, li {
  list-style: none outside;
}

a {
  text-decoration: none;
  color: #0d1240;
}

i, em, address {
  font-style: normal;
}

button {
  cursor: pointer;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  font-size: 14px;
  font-family: "SFUIDisplay", "NotoSansCJKkr", "Roboto", "Droid Sans", "Malgun Gothic", "Helvetica", "Apple-Gothic", "애플고딕", "Tahoma", dotum, "돋움", gulim, "굴림", sans-serif;
  background: none;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

legend, caption {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
  position: absolute;
}

hr {
  display: none;
}

input[type=text],
input[type=password],
input[type=number],
input[type=date],
input[type=search],
input[type=tel] {
  width: 100%;
  height: 60px;
  padding: 0 15px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #0d1240;
  font-family: "SFUIDisplay", "NotoSansCJKkr", "Roboto", "Droid Sans", "Malgun Gothic", "Helvetica", "Apple-Gothic", "애플고딕", "Tahoma", dotum, "돋움", gulim, "굴림", sans-serif;
  font-size: 16px;
  outline: 0 none;
  border-radius: 2px;
  border: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
}
input[type=text]:disabled,
input[type=password]:disabled,
input[type=number]:disabled,
input[type=date]:disabled,
input[type=search]:disabled,
input[type=tel]:disabled {
  color: #999;
  background-color: rgba(13, 18, 64, 0.05);
  opacity: 1;
  -webkit-text-fill-color: #999;
}
input[type=text].err,
input[type=password].err,
input[type=number].err,
input[type=date].err,
input[type=search].err,
input[type=tel].err {
  border: 1px solid #ff4e16;
  background-color: rgba(255, 78, 22, 0.05) !important;
}
input[type=text].err2,
input[type=password].err2,
input[type=number].err2,
input[type=date].err2,
input[type=search].err2,
input[type=tel].err2 {
  background-color: rgba(255, 78, 22, 0.05) !important;
}
@media (min-width: 786px) {
  input[type=text],
  input[type=password],
  input[type=number],
  input[type=date],
  input[type=search],
  input[type=tel] {
    height: 56px;
    font-size: 14px;
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  color: rgba(13, 18, 64, 0.4);
}

:-ms-input-placeholder {
  opacity: 0.4 !important;
}

input[type=checkbox] + label {
  display: inline-block;
  position: relative;
  padding: 0 0 0 23px;
  cursor: pointer;
  color: #0d1240;
  font-size: 16px;
  line-height: 26px;
}
input[type=checkbox] + label:before {
  display: block;
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  border: solid 1px rgba(85, 57, 255, 0.4);
  top: 4px;
  left: 0;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
}
@media (min-width: 786px) {
  input[type=checkbox] + label {
    font-size: 14px;
  }
}

input[type=checkbox] {
  position: absolute;
  opacity: 0;
}
input[type=checkbox]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 4px;
  width: 9px;
  height: 6px;
  color: #0d1240;
  border-width: 0 0 2px 2px;
  border-style: solid;
  border-color: #fff;
  transform: rotate(-45deg);
  box-sizing: border-box;
}
input[type=checkbox]:checked + label:before {
  background: #5539ff;
  border: none;
}

input[type=radio] + label {
  display: inline-block;
  align-items: center;
  position: relative;
  padding: 0 0 0 24px;
  cursor: pointer;
  color: rgba(13, 18, 64, 0.6);
  font-family: "SFUIDisplay", "NotoSansCJKkr", "Roboto", "Droid Sans", "Malgun Gothic", "Helvetica", "Apple-Gothic", "애플고딕", "Tahoma", dotum, "돋움", gulim, "굴림", sans-serif;
  line-height: 18px;
}
input[type=radio] + label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgba(85, 57, 255, 0.4);
  background: #fff;
  box-sizing: border-box;
}

input[type=radio] {
  position: absolute;
  opacity: 0;
}
input[type=radio]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  min-width: 10px;
  height: 10px;
  background: #5539ff;
  border-radius: 50%;
}

textarea {
  width: 100%;
  padding: 24px;
  color: rgba(13, 18, 64, 0.4);
  font-family: "SFUIDisplay", "NotoSansCJKkr", "Roboto", "Droid Sans", "Malgun Gothic", "Helvetica", "Apple-Gothic", "애플고딕", "Tahoma", dotum, "돋움", gulim, "굴림", sans-serif;
  font-size: 16px;
  line-height: 26px;
  vertical-align: top;
  outline: 0 none;
  background-color: rgba(85, 57, 255, 0.03);
  border: none;
  border-radius: 2px;
  box-sizing: border-box;
  resize: none;
  white-space: pre-wrap;
}

select {
  height: 60px;
  padding: 0 50px 0 16px;
  background: rgba(85, 57, 255, 0.03) url("../images/icon_arrow.svg") no-repeat 100% 50%;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #0d1240;
  font-size: 22px;
  vertical-align: top;
  box-sizing: border-box;
  border: none;
  outline: 0 none;
}
select:focus {
  border-color: #145646;
}
@media (min-width: 786px) {
  select {
    height: 56px;
  }
}

select::-ms-expand {
  display: none;
}

input[type=file] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* .file-wrap {
	position: relative;
	label {
		display: inline-block;
		//position: absolute;
		min-width: 100px;
		height: 49px;
		border: 1px solid $color-point;
		border-radius: 0;
		white-space: nowrap;
		box-sizing: border-box;
		color: $color-point;
		font-weight: bold;
		text-align: center;
		line-height: 47px;
	}
	&.type2 {
		display: flex;
		flex-direction: row-reverse;
		input {
			border-right: none;
		}
	}
}
.photo-up {
	position: relative;
	label {
		display: inline-block;
		min-width: 80px;
		height: 80px;
		border-radius: 0;
		white-space: nowrap;
		box-sizing: border-box;
		background: #f3f9f6;
		color: $color-point;
		font-size: 12px;
		text-align: center;
		&:before {
			content: '';
			display: block;
			width: 48px;
			height: 48px;
			margin: 8px auto 0;
			background: url('../images/icon_photo.svg') no-repeat 0 0;
		}
	}
} */
@font-face {
  font-family: "SFUIDisplay";
  src: url("../fonts/SFUIDisplay-Regular.woff") format("woff");
  font-weight: normal;
}
@font-face {
  font-family: "SFUIDisplay";
  src: url("../fonts/SFUIDisplay-Bold.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-display: swap;
  src: local("Pretendard Bold"), url("../fonts/Pretendard-Bold.subset.woff2") format("woff2"), url("../fonts/Pretendard-Bold.subset.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-display: swap;
  src: local("Pretendard SemiBold"), url("../fonts/Pretendard-SemiBold.subset.woff2") format("woff2"), url("../fonts/Pretendard-SemiBold.subset.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Regular"), url("../fonts/Pretendard-Regular.subset.woff2") format("woff2"), url("../fonts/Pretendard-Regular.subset.woff") format("woff");
}
#skipNav a {
  position: absolute;
  left: -3000%;
}
#skipNav :focus {
  display: block;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #000;
  color: #fff;
  font-size: 18px;
  text-align: center;
}

.t-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 30;
  width: 100%;
  padding: 20px 24px;
  box-sizing: border-box;
  background: #fff;
}
header .btn-menu {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}
header .btn-menu:before, header .btn-menu:after {
  content: "";
  display: block;
  position: absolute;
  left: calc(50% - 10px);
  width: 20px;
  height: 2px;
  background: #5539ff;
  border-radius: 3px;
}
header .btn-menu:before {
  top: 11px;
}
header .btn-menu:after {
  top: 18px;
}
header .btn-menu span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
  width: 20px;
  height: 16px;
}
header .btn-menu span:before {
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  left: calc(50% - 10px);
  width: 20px;
  height: 2px;
  background: #5539ff;
  border-radius: 3px;
}
header h1 a {
  display: block;
  width: 80px;
  height: 34px;
  background: url("../images/logo.svg") no-repeat 0 0;
  background-size: 100% auto;
}
header h1 a span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
header h1.alpha a {
  width: 122px;
  background-image: url("../images/logo_alpha.svg");
}
header .mem-w {
  display: flex;
  align-items: center;
  margin-top: -5px;
  margin-left: auto;
  padding-right: 30px;
}
header .mem-w a {
  height: 26px;
  line-height: 26px;
}
header .mem-w .mem {
  display: flex;
  padding-right: 8px;
}
header .mem-w .mem li {
  display: flex;
  align-items: center;
  font-size: 14px;
}
header .mem-w .mem li a {
  color: #5539ff;
}
header .mem-w .mem li:before {
  content: "/";
  margin: -1px 3px 0;
  color: #5539ff;
}
header .mem-w .mem li:first-child:before {
  display: none;
}
header .mem-w .profile {
  display: flex;
  align-items: center;
}
header .mem-w .profile li {
  padding: 0 5px;
  font-size: 14px;
}
header .mem-w .profile li a {
  display: block;
  color: #5539ff;
}
header .mem-w .profile li a em {
  font-weight: bold;
}
header .mem-w .profile li a.alm {
  position: relative;
  width: 26px;
  height: 26px;
  background: url("../images/icon_alm.svg") no-repeat 50% 4px;
}
header .mem-w .profile li a.alm span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
header .mem-w .profile li a.alm i {
  position: absolute;
  top: 2px;
  left: 7px;
  width: 16px;
  height: 11px;
  background: #ff4e16;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 10px;
  border-radius: 7px;
}
header .mem-w .alm-list {
  overflow: auto;
  position: fixed;
  top: 74px;
  right: 0;
  width: 100%;
  max-width: 320px;
  height: calc(100vh - 74px);
  background: #fff;
  box-shadow: -1px 0 0 0 rgba(13, 18, 64, 0.1);
  font-size: 14px;
  line-height: 22px;
}
header .mem-w .alm-list ul li {
  box-shadow: 0 1px 0 0 rgba(13, 18, 64, 0.05);
}
header .mem-w .alm-list ul li a {
  display: block;
  height: auto;
  padding: 16px;
  color: rgba(13, 18, 64, 0.4);
}
header .mem-w .alm-list ul li a span {
  display: block;
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.4) !important;
}
header .mem-w .alm-list ul li.new {
  background-color: rgba(85, 57, 255, 0.03);
}
header .mem-w .alm-list ul li.new a {
  color: #5539ff;
}
header .inner {
  display: none;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 60px 40px 40px;
  background: #5539ff;
  box-sizing: border-box;
}
header .inner a {
  color: #fff;
}
header .inner ul li {
  padding: 20px 0;
  font-size: 22px;
}
header .inner .utill {
  margin-top: 28px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
header .inner .btn-close {
  position: fixed;
  top: 40px;
  right: 14px;
  width: 40px;
  height: 40px;
}
header .inner .btn-close > span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
header .inner .btn-close:before, header .inner .btn-close:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: calc(50% - 2px);
  width: 2px;
  height: 22px;
  background: #fff;
  border-radius: 2px;
}
header .inner .btn-close:before {
  transform: rotate(-45deg);
}
header .inner .btn-close:after {
  transform: rotate(45deg);
}
header .inner.selected {
  display: block;
  z-index: 100;
}
header .inner.selected nav a, header .inner.selected .utill a {
  color: #fff;
}
header.bgtype {
  background: #fff;
}
header.bgtype2 {
  background-color: #5539ff;
}
header.bgtype2 h1 a {
  background-image: url("../images/logo_w.svg");
}
header.bgtype2 .inner nav a {
  color: #fff !important;
}
header.bgtype2 .mem-w .mem li:before {
  color: #fff;
}
header.bgtype2 .mem-w li a {
  color: #fff;
}
header.bgtype2 .mem-w li a.alm {
  background-position: 50% -31px;
}
header.bgtype2 .btn-menu:before, header.bgtype2 .btn-menu:after {
  background: #fff;
}
header.bgtype2 .btn-menu > span:before {
  background: #fff;
}
header.sticky {
  background: #f9fdff;
  /* h1 {
  	a {
  		background-image: url('../images/logo.svg');
  	}
  } */
}
header.sticky .btn-menu:before, header.sticky .btn-menu:after {
  background-color: #5539ff;
}
header.sticky .btn-menu > span:before {
  background-color: #5539ff;
}
header.sticky .profile li a {
  color: #5539ff !important;
}
header.sticky .profile li a.alm {
  background-position: 50% 4px;
}
header.sticky .inner a {
  color: #0d1240 !important;
}
header.index {
  background: #f9fdff;
}
@media (min-width: 786px) {
  header {
    display: block;
    height: 72px;
    margin-bottom: -72px;
  }
  header h1 {
    float: left;
  }
  header h1 a {
    width: 75px;
    height: 32px;
  }
  header .mem-w {
    position: absolute;
    top: 22px;
    right: 30px;
    margin-top: 0;
  }
  header .mem-w .mem li {
    padding-left: 40px;
  }
  header .mem-w .mem li:before {
    display: none;
  }
  header .inner {
    display: block;
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    background: none;
  }
  header .inner a {
    color: #0d1240;
  }
  header .inner nav {
    margin: 7px 0 0;
    padding: 0;
    border: none;
  }
  header .inner nav ul {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  header .inner nav ul li {
    padding: 0 0 0 40px;
    font-size: 14px;
  }
  header .inner .utill {
    display: none;
    position: absolute;
    z-index: 10;
    top: 35px;
    right: 25px;
    width: 148px;
    padding: 16px 0;
    background: #fff;
    border: 1px solid #5539ff;
    text-align: center;
  }
  header .inner .utill li {
    padding: 8px 0 !important;
    font-size: 14px !important;
  }
  header .inner .utill li a:hover {
    color: #5539ff;
  }
  header .inner .utill.selected {
    display: block;
  }
  header .inner button {
    display: none;
  }
  header .inner.selected nav a, header .inner.selected .utill a {
    color: #0d1240;
  }
  header.bgtype {
    background: none;
  }
  header.bgtype h1 a {
    background-image: url("../images/logo_w.svg");
  }
  header.bgtype nav ul li a {
    color: #fff;
  }
}

.top-bnr {
  background: #e0fe52;
  text-align: center;
}
.top-bnr a {
  display: block;
  padding: 16px 24px;
  font-size: 14px;
  line-height: 22px;
}
.top-bnr a p.beta {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
}
.top-bnr a p.beta span {
  margin-right: 8px;
  padding: 5px 8px;
  border-radius: 20px;
  border: 1px solid #000;
  font-size: 12px;
}
@media (min-width: 786px) {
  .top-bnr a {
    font-size: 16px;
  }
  .top-bnr a p, .top-bnr a strong {
    display: inline;
  }
  .top-bnr a p {
    font-weight: bold;
  }
  .top-bnr a p.beta {
    font-size: 16px;
  }
  .top-bnr a p.beta > span {
    padding: 6px 10px;
    font-size: 14px;
  }
  .top-bnr a strong {
    font-weight: normal;
    text-decoration: underline;
  }
}

.top-bnr + header {
  position: relative;
}

main {
  flex: 1 0 auto;
}
main.bgtype {
  background: #FDFDFD;
}
main.bgtype2 {
  background: #FCFBFF;
}

footer {
  padding: 64px 40px;
  background: #f9fdff;
}
footer .ftr-inner em.logo {
  display: block;
  width: 75px;
  height: 32px;
  background: url("../images/logo.svg") no-repeat 0 0;
  background-size: 100% auto;
}
footer .ftr-inner em.logo span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
footer .ftr-inner > ul {
  display: flex;
  flex-wrap: wrap;
}
footer .ftr-inner > ul li {
  min-width: 100%;
  margin-top: 24px;
}
footer .ftr-inner > ul li em, footer .ftr-inner > ul li span {
  display: block;
  margin-top: 16px;
  font-size: 14px;
  white-space: nowrap;
}
footer .ftr-inner > ul li em a, footer .ftr-inner > ul li span a {
  color: rgba(13, 18, 64, 0.6);
}
footer .ftr-inner > ul li em {
  font-weight: bold;
}
footer .ftr-inner > ul li:nth-child(1), footer .ftr-inner > ul li:nth-child(2) {
  min-width: 50%;
}
footer .ftr-inner .c-info {
  padding-top: 24px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
  line-height: 22px;
}
footer .ftr-inner .c-info address {
  margin-top: 16px;
}
footer .ftr-inner .c-info p {
  margin-top: 16px;
}
@media (min-width: 786px) {
  footer {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  footer .ftr-inner {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
  }
  footer .ftr-inner > ul {
    margin-top: -16px;
    padding-left: 65px;
  }
  footer .ftr-inner > ul li {
    min-width: auto;
    margin-top: 0;
    padding-right: 64px;
  }
  footer .ftr-inner > ul li:nth-child(1), footer .ftr-inner > ul li:nth-child(2) {
    min-width: auto;
  }
  footer .ftr-inner > ul li:last-child {
    padding-right: 0;
  }
  footer .ftr-inner .c-info {
    width: 40%;
    margin-top: -18px;
    margin-left: auto;
    padding-top: 0;
    padding-left: 64px;
    font-size: 14px;
    box-sizing: border-box;
  }
}

.btn-del {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
}
.btn-del:before, .btn-del:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 1px);
  width: 2px;
  height: 13px;
  background: rgba(13, 18, 64, 0.6);
  border-radius: 2px;
}
.btn-del:before {
  transform: rotate(-45deg);
}
.btn-del:after {
  transform: rotate(45deg);
}
.btn-del span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}

.btn-add {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  margin-top: 24px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
}
.btn-add:before, .btn-add:after {
  content: "";
  display: block;
  position: absolute;
  background: #5539ff;
}
.btn-add:before {
  top: calc(50% - 7px);
  left: calc(50% - 1px);
  width: 2px;
  height: 14px;
}
.btn-add:after {
  top: calc(50% - 1px);
  left: calc(50% - 7px);
  width: 14px;
  height: 2px;
}
.btn-add span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}

.btn-prev a {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  background: url("../images/icon_arrow_back.svg") no-repeat 0 0;
}
.btn-prev a span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}

.btn-sticky {
  position: sticky;
  z-index: 20;
  bottom: 0;
  left: 0;
  margin-top: -1px;
  padding: 24px 0;
  background: #fff;
}
.btn-sticky a, .btn-sticky button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  background: #5539ff;
  border-radius: 2px;
  color: #fff;
}
@media (min-width: 786px) {
  .btn-sticky {
    position: static;
    margin-top: 64px;
    padding: 0;
  }
}

.btn-out {
  margin-top: 24px;
}
.btn-out a {
  color: rgba(13, 18, 64, 0.4);
  font-size: 12px;
  text-decoration: underline;
}
@media (min-width: 786px) {
  .btn-out {
    margin-top: 64px;
  }
}

.btn-more {
  margin-top: 26px;
  text-align: center;
}
.btn-more a {
  color: #5539ff;
  text-decoration: underline;
}

.pw-lo {
  margin-top: 24px;
  text-align: center;
}
.pw-lo a {
  color: rgba(13, 18, 64, 0.4);
  font-size: 12px;
  text-decoration: underline;
}
@media (min-width: 786px) {
  .pw-lo {
    margin-top: 64px;
  }
}

.li-more {
  margin-top: 16px;
  text-align: center;
}
.li-more button {
  color: #5539ff;
  text-decoration: underline;
}

p.err {
  margin-top: 10px;
  color: #f00;
  font-size: 12px;
}

.ness:before {
  content: "*";
}
.ness span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}

button.btn_pw_v,
button.btn_pw_h {
  position: absolute;
  right: 25px;
  top: 42px;
  width: 20px;
  height: 20px;
  background: url("../images/icon_pw.svg") no-repeat 0 0;
}
button.btn_pw_v span,
button.btn_pw_h span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}

button.btn_pw_h {
  background-position: 100% 0;
}

.pjt-more {
  margin-top: 20px;
  text-align: right;
}
.pjt-more a {
  padding-right: 22px;
  font-size: 14px;
  color: #5539ff;
  background: url("../images/icon_arrow02.svg") no-repeat 100% -1px;
  background-size: 20px auto;
  white-space: nowrap;
}

.smtit {
  position: relative;
  padding-left: 15px;
  color: #5539ff;
  font-size: 14px;
}
.smtit:before {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 7px;
  height: 4px;
  margin-right: 8px;
  border-radius: 10px;
  background: #5539ff;
  transform: rotate(-45deg);
}

.top-box {
  overflow: hidden;
  padding: 88px 24px 24px;
  background: #5539ff;
  color: #fff;
  text-align: center;
}
.top-box a {
  color: #fff;
}
.top-box > h2 {
  overflow: hidden;
  color: #fff;
  font-size: 22px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.top-box > h2 em {
  font-weight: bold;
}
.top-box > h2 em:after {
  content: "·";
  margin: 0 5px;
}
.top-box .cho-date {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.top-box .cho-date > label {
  margin-right: 18px;
}
.top-box .cho-date .d-w {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 14px;
  border-radius: 2px;
  background-color: rgba(13, 18, 64, 0.1);
}
.top-box .cho-date .d-w input {
  width: 135px;
  height: auto;
  padding: 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}
.top-box .cho-date .d-w button.cal {
  width: 22px;
  height: 22px;
  background: url("../images/icon_calendar.svg") no-repeat 0 0;
}
.top-box .cho-date .d-w button.cal span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.top-box > p {
  margin-top: 16px;
  line-height: 22px;
}
.top-box > p a {
  display: inline-flex;
  align-items: center;
}
.top-box > p a:after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  margin-left: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: #fff;
  border-radius: 2px;
  transform: rotate(-45deg);
}
.top-box > p a span {
  display: none;
}
.top-box > p > span {
  text-decoration: underline;
}
.top-box .btn-guide {
  margin-top: 19px;
}
.top-box .btn-guide a {
  padding-left: 18px;
  background: url("../images/icon_guide.svg") no-repeat 0 0;
  text-decoration: underline;
  font-size: 12px;
}
.top-box .sch-wrap {
  max-width: 600px;
  margin: 24px auto 0;
}
.top-box .sch-wrap p {
  font-size: 22px;
  text-align: center;
  line-height: 34px;
}
.top-box .sch-wrap p em {
  font-weight: bold;
}
.top-box .sch-wrap fieldset {
  margin-top: 24px;
  padding-bottom: 8px;
}
.top-box .sch-wrap fieldset .sel {
  display: flex;
  justify-content: space-between;
}
.top-box .sch-wrap fieldset .sel select {
  width: calc(50% - 3px);
  background-color: #fff;
  font-size: 16px;
}
.top-box .sch-wrap fieldset .sel select:disabled {
  opacity: 1;
  color: rgba(13, 18, 64, 0.4);
}
.top-box .sch-wrap fieldset .sel select:last-child {
  width: calc(50% - 4px);
}
.top-box .sch-wrap fieldset .int {
  display: flex;
  position: relative;
  margin-top: 8px;
  padding: 8px 8px 8px 0;
  background: #fff;
  border-radius: 2px;
}
.top-box .sch-wrap fieldset .int input {
  height: 40px;
  background: none;
}
.top-box .sch-wrap fieldset .int input::placeholder {
  opacity: 1;
  color: rgba(13, 18, 64, 0.4);
}
.top-box .sch-wrap fieldset .int button {
  position: relative;
  min-width: 40px;
  height: 40px;
}
.top-box .sch-wrap fieldset .int button span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.top-box .sch-wrap fieldset .int button:before, .top-box .sch-wrap fieldset .int button:after {
  content: "";
  display: block;
  position: absolute;
}
.top-box .sch-wrap fieldset .int button.sch {
  background-color: rgba(85, 57, 255, 0.05);
  border-radius: 2px;
}
.top-box .sch-wrap fieldset .int button.sch:before {
  top: 11px;
  left: 11px;
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  border: 2px solid #5539ff;
  border-radius: 50%;
}
.top-box .sch-wrap fieldset .int button.sch:after {
  top: 23px;
  left: 25px;
  width: 2px;
  height: 7px;
  background: #5539ff;
  transform: rotate(-45deg);
}
.top-box .sch-wrap fieldset .int button.delete {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.top-box .sch-wrap fieldset .int button.delete:before, .top-box .sch-wrap fieldset .int button.delete:after {
  top: calc(50% - 9px);
  left: 50%;
  width: 2px;
  height: 19px;
  background-color: rgba(13, 18, 64, 0.6);
  border-radius: 2px;
}
.top-box .sch-wrap fieldset .int button.delete:before {
  transform: rotate(-45deg);
}
.top-box .sch-wrap fieldset .int button.delete:after {
  transform: rotate(45deg);
}
.top-box .sch-wrap .btn-reset {
  width: 100%;
  margin-top: 8px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
}
.top-box.in-prev {
  display: flex;
  justify-content: center;
}
.top-box.in-prev h2 {
  line-height: 34px;
  white-space: normal;
}
@media (min-width: 786px) {
  .top-box {
    position: relative;
    padding-top: 118px;
    padding-bottom: 40px;
  }
  .top-box > p a span {
    display: inline;
  }
  .top-box > p a span:before {
    content: "·";
    margin: 0 5px;
  }
  .top-box .btn-guide {
    position: absolute;
    top: 88px;
    right: 40px;
    margin-top: 0;
  }
  .top-box .sch-wrap {
    margin-top: 0;
  }
  .top-box .sch-wrap fieldset {
    padding-bottom: 0;
  }
  .top-box.in-prev {
    position: relative;
  }
  .top-box.in-prev .btn-prev {
    position: absolute;
    top: 112px;
    left: 40px;
  }
}

.paging {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.paging a, .paging strong {
  min-width: 32px;
  height: 32px;
  line-height: 32px;
}
.paging a > span, .paging strong > span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.paging > a {
  margin: 0 4px;
  background: url("../images/icon_paging.svg") no-repeat 50% 50%;
}
.paging > a.btn-next {
  transform: rotate(180deg);
}
.paging > a.disb {
  opacity: 0.4;
}
.paging > span {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;
}
.paging > span a, .paging > span strong {
  margin: 0 4px;
  border-radius: 4px;
}
.paging > span a {
  background-color: rgba(85, 57, 255, 0.03);
  color: rgba(85, 57, 255, 0.4);
}
.paging > span strong {
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
}

.cate-w {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
}
.cate-w > span, .cate-w > a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  padding: 8px 16px;
  border-radius: 2px;
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
  white-space: nowrap;
  box-sizing: border-box;
  margin: 8px 8px 0 0;
}
.cate-w > a {
  color: #5539ff;
}
.cate-w > a.sns {
  display: inline-flex;
}
.cate-w > a.sns:before {
  content: "";
  min-width: 18px;
  height: 18px;
  margin-right: 9px;
  background: url("../images/icon_sns_p.svg") no-repeat 0 0;
}
.cate-w > a.sns.dribbble:before {
  background-position: -32px 0;
}
.cate-w > a.sns.github:before {
  background-position: -64px 0;
}
.cate-w > a.sns.blog:before {
  background-position: -96px 0;
}
.cate-w > a.sns.behance:before {
  background-position: -128px 0;
}
.cate-w > a.sns.insta:before {
  background-position: -160px 0;
}
.cate-w > a.dir-go:after {
  content: "";
  width: 12px;
  height: 12px;
  margin-left: 8px;
  background: url("../images/icon_dirgo.svg") no-repeat 100% 0;
}

.fm-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 56px;
  padding: 4px 8px 6px;
  background-color: rgba(85, 57, 255, 0.03);
  box-sizing: border-box;
}
.fm-box > span {
  display: flex;
  align-items: center;
  position: relative;
  height: 42px;
  margin: 4px 8px 4px 0;
  padding: 8px 36px 8px 16px;
  background-color: rgba(85, 57, 255, 0.05);
  border: none;
  color: rgba(85, 57, 255, 0.8);
  box-sizing: border-box;
}
.fm-box > span button {
  position: absolute;
  top: 6px;
  right: 5px;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
}
.fm-box > span button:before, .fm-box > span button:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 50%;
  width: 2px;
  height: 13px;
  background: #5539ff;
  border-radius: 2px;
}
.fm-box > span button:before {
  transform: rotate(-45deg);
}
.fm-box > span button:after {
  transform: rotate(45deg);
}
.fm-box > span button span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.fm-box .in-sel {
  position: relative;
  flex: 1;
}
.fm-box .in-sel input[type=text] {
  height: 42px;
  margin-top: 0;
  padding: 4px 0 0 4px;
  background: none;
}
.fm-box .in-sel .opt-li {
  overflow: auto;
  display: none;
  position: absolute;
  top: 42px;
  left: 0;
  background: #fff;
  width: 200px;
  max-height: 210px;
  padding: 8px;
  box-shadow: 4px 8px 16px 0 rgba(13, 18, 64, 0.15);
  box-sizing: border-box;
}
.fm-box .in-sel .opt-li ul li {
  display: block;
  height: 32px;
  padding: 0 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  line-height: 32px;
  border-radius: 2px;
  cursor: pointer;
}
.fm-box .in-sel .opt-li ul li:hover, .fm-box .in-sel .opt-li ul li.selected {
  background-color: rgba(85, 57, 255, 0.03);
}
.fm-box .in-sel.selected {
  z-index: 5;
}
.fm-box .in-sel.selected .opt-li {
  display: block;
}

.int-form {
  position: relative;
  margin-top: 8px;
}
.int-form .file-up {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 40px;
  padding: 0 12px;
  background: #5539ff;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  border-radius: 2px;
}

.num-w {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 0 24px 0 0;
  background-color: rgba(85, 57, 255, 0.03);
  color: #0d1240;
}
.num-w input {
  margin-top: 0 !important;
  background: none;
  font-size: 14px;
  text-align: right;
}
.num-w input::placeholder {
  text-align: left;
}
.num-w span {
  white-space: nowrap;
}

.country-list {
  overflow-y: scroll;
  position: absolute;
  z-index: 2;
  top: 85px;
  left: 0;
  width: 100%;
  max-height: 200px;
  padding: 0;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #ccc;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.country-list .country {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
.country-list .country > span {
  padding-left: 7px;
}
.country-list .country .dial-code {
  color: #999;
}
.country-list .country.highlight, .country-list .country:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.intl-tel-input {
  display: flex;
  position: relative;
  margin-top: 8px;
}
.intl-tel-input .hide {
  display: none;
}
.intl-tel-input .v-hide {
  visibility: hidden;
}
.intl-tel-input .flag-container {
  height: 56px;
}
.intl-tel-input .flag-container .selected-flag {
  display: flex;
  align-items: center;
  position: relative;
  width: 100px;
  height: 100%;
  margin-right: 8px;
  padding-left: 24px;
  background-color: rgba(85, 57, 255, 0.05);
  box-sizing: border-box;
}
.intl-tel-input .flag-container .selected-flag .arrow {
  position: absolute;
  right: 24px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #5539ff;
}
.intl-tel-input .flag-container .selected-flag .arrow.up {
  transform: rotate(180deg);
}
.intl-tel-input .tel {
  flex: 1;
}
.intl-tel-input .tel > div {
  position: relative;
  margin-top: 8px;
}
.intl-tel-input .tel > div input {
  padding-right: 110px;
}
.intl-tel-input .tel > div button {
  position: absolute;
  top: 8px;
  right: 8px;
  min-width: 97px;
  height: 40px;
  background: #5539ff;
  color: #fff;
  line-height: 40px;
  border-radius: 2px;
}
.intl-tel-input .tel > div button.inc {
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
}
.intl-tel-input .tel > div:first-child {
  margin-top: 0;
}
.intl-tel-input .inc-time {
  position: absolute;
  top: -23px;
  right: 0;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.intl-tel-input > button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 105px;
  height: 40px;
  background: #5539ff;
  color: #fff;
  border-radius: 2px;
  text-align: center;
}
.intl-tel-input > button:disabled {
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.4);
}
.intl-tel-input.disabled .flag-container .selected-flag {
  background-color: rgba(13, 18, 64, 0.05);
}
.intl-tel-input.disabled .flag-container .selected-flag .arrow {
  border-top-color: rgba(13, 18, 64, 0.4);
}

.iti-flag {
  width: 20px;
  height: 15px;
  background-image: url("../images/sel_flags.png");
  background-repeat: no-repeat;
  background-size: 5630px 15px;
  background-color: #dbdbdb;
  background-position: 20px 0;
  box-shadow: 0px 0px 1px 0px #888;
}
.iti-flag.ac {
  background-position: 0 0;
}
.iti-flag.ad {
  background-position: -22px 0;
}
.iti-flag.ae {
  background-position: -44px 0;
}
.iti-flag.af {
  background-position: -66px 0;
}
.iti-flag.ag {
  background-position: -88px 0;
}
.iti-flag.ai {
  background-position: -110px 0;
}
.iti-flag.al {
  background-position: -132px 0;
}
.iti-flag.am {
  background-position: -154px 0;
}
.iti-flag.ao {
  background-position: -176px 0;
}
.iti-flag.aq {
  background-position: -198px 0;
}
.iti-flag.ar {
  background-position: -220px 0;
}
.iti-flag.as {
  background-position: -242px 0;
}
.iti-flag.at {
  background-position: -264px 0;
}
.iti-flag.au {
  background-position: -286px 0;
}
.iti-flag.aw {
  background-position: -308px 0;
}
.iti-flag.ax {
  background-position: -330px 0;
}
.iti-flag.az {
  background-position: -352px 0;
}
.iti-flag.ba {
  background-position: -374px 0;
}
.iti-flag.bb {
  background-position: -396px 0;
}
.iti-flag.bd {
  background-position: -418px 0;
}
.iti-flag.be {
  background-position: -440px 0;
}
.iti-flag.bf {
  background-position: -460px 0;
}
.iti-flag.bg {
  background-position: -482px 0;
}
.iti-flag.bh {
  background-position: -504px 0;
}
.iti-flag.bi {
  background-position: -526px 0;
}
.iti-flag.bj {
  background-position: -548px 0;
}
.iti-flag.bl {
  background-position: -570px 0;
}
.iti-flag.bm {
  background-position: -592px 0;
}
.iti-flag.bn {
  background-position: -614px 0;
}
.iti-flag.bo {
  background-position: -636px 0;
}
.iti-flag.bq {
  background-position: -658px 0;
}
.iti-flag.br {
  background-position: -680px 0;
}
.iti-flag.bs {
  background-position: -702px 0;
}
.iti-flag.bt {
  background-position: -724px 0;
}
.iti-flag.bv {
  background-position: -746px 0;
}
.iti-flag.bw {
  background-position: -768px 0;
}
.iti-flag.by {
  background-position: -790px 0;
}
.iti-flag.bz {
  background-position: -812px 0;
}
.iti-flag.ca {
  background-position: -834px 0;
}
.iti-flag.cc {
  background-position: -856px 0;
}
.iti-flag.cd {
  background-position: -878px 0;
}
.iti-flag.cf {
  background-position: -900px 0;
}
.iti-flag.cg {
  background-position: -922px 0;
}
.iti-flag.ch {
  background-position: -944px 0;
}
.iti-flag.ci {
  background-position: -961px 0;
}
.iti-flag.ck {
  background-position: -983px 0;
}
.iti-flag.cl {
  background-position: -1005px 0;
}
.iti-flag.cm {
  background-position: -1027px 0;
}
.iti-flag.cn {
  background-position: -1049px 0;
}
.iti-flag.co {
  background-position: -1071px 0;
}
.iti-flag.cp {
  background-position: -1093px 0;
}
.iti-flag.cr {
  background-position: -1115px 0;
}
.iti-flag.cu {
  background-position: -1137px 0;
}
.iti-flag.cv {
  background-position: -1159px 0;
}
.iti-flag.cw {
  background-position: -1181px 0;
}
.iti-flag.cx {
  background-position: -1203px 0;
}
.iti-flag.cy {
  background-position: -1225px 0;
}
.iti-flag.cz {
  background-position: -1247px 0;
}
.iti-flag.de {
  background-position: -1269px 0;
}
.iti-flag.dg {
  background-position: -1291px 0;
}
.iti-flag.dj {
  background-position: -1313px 0;
}
.iti-flag.dk {
  background-position: -1335px 0;
}
.iti-flag.dm {
  background-position: -1357px 0;
}
.iti-flag.do {
  background-position: -1379px 0;
}
.iti-flag.dz {
  background-position: -1401px 0;
}
.iti-flag.ea {
  background-position: -1423px 0;
}
.iti-flag.ec {
  background-position: -1445px 0;
}
.iti-flag.ee {
  background-position: -1467px 0;
}
.iti-flag.eg {
  background-position: -1489px 0;
}
.iti-flag.eh {
  background-position: -1511px 0;
}
.iti-flag.er {
  background-position: -1533px 0;
}
.iti-flag.es {
  background-position: -1555px 0;
}
.iti-flag.et {
  background-position: -1577px 0;
}
.iti-flag.eu {
  background-position: -1599px 0;
}
.iti-flag.fi {
  background-position: -1621px 0;
}
.iti-flag.fj {
  background-position: -1643px 0;
}
.iti-flag.fk {
  background-position: -1665px 0;
}
.iti-flag.fm {
  background-position: -1687px 0;
}
.iti-flag.fo {
  background-position: -1709px 0;
}
.iti-flag.fr {
  background-position: -1731px 0;
}
.iti-flag.ga {
  background-position: -1753px 0;
}
.iti-flag.gb {
  background-position: -1775px 0;
}
.iti-flag.gd {
  background-position: -1797px 0;
}
.iti-flag.ge {
  background-position: -1819px 0;
}
.iti-flag.gf {
  background-position: -1841px 0;
}
.iti-flag.gg {
  background-position: -1863px 0;
}
.iti-flag.gh {
  background-position: -1885px 0;
}
.iti-flag.gi {
  background-position: -1907px 0;
}
.iti-flag.gl {
  background-position: -1929px 0;
}
.iti-flag.gm {
  background-position: -1951px 0;
}
.iti-flag.gn {
  background-position: -1973px 0;
}
.iti-flag.gp {
  background-position: -1995px 0;
}
.iti-flag.gq {
  background-position: -2017px 0;
}
.iti-flag.gr {
  background-position: -2039px 0;
}
.iti-flag.gs {
  background-position: -2061px 0;
}
.iti-flag.gt {
  background-position: -2083px 0;
}
.iti-flag.gu {
  background-position: -2105px 0;
}
.iti-flag.gw {
  background-position: -2127px 0;
}
.iti-flag.gy {
  background-position: -2149px 0;
}
.iti-flag.hk {
  background-position: -2171px 0;
}
.iti-flag.hm {
  background-position: -2193px 0;
}
.iti-flag.hn {
  background-position: -2215px 0;
}
.iti-flag.hr {
  background-position: -2237px 0;
}
.iti-flag.ht {
  background-position: -2259px 0;
}
.iti-flag.hu {
  background-position: -2281px 0;
}
.iti-flag.ic {
  background-position: -2303px 0;
}
.iti-flag.id {
  background-position: -2325px 0;
}
.iti-flag.ie {
  background-position: -2347px 0;
}
.iti-flag.il {
  background-position: -2369px 0;
}
.iti-flag.im {
  background-position: -2391px 0;
}
.iti-flag.in {
  background-position: -2413px 0;
}
.iti-flag.io {
  background-position: -2435px 0;
}
.iti-flag.iq {
  background-position: -2457px 0;
}
.iti-flag.ir {
  background-position: -2479px 0;
}
.iti-flag.is {
  background-position: -2501px 0;
}
.iti-flag.it {
  background-position: -2523px 0;
}
.iti-flag.je {
  background-position: -2545px 0;
}
.iti-flag.jm {
  background-position: -2567px 0;
}
.iti-flag.jo {
  background-position: -2589px 0;
}
.iti-flag.jp {
  background-position: -2611px 0;
}
.iti-flag.ke {
  background-position: -2633px 0;
}
.iti-flag.kg {
  background-position: -2655px 0;
}
.iti-flag.kh {
  background-position: -2677px 0;
}
.iti-flag.ki {
  background-position: -2699px 0;
}
.iti-flag.km {
  background-position: -2721px 0;
}
.iti-flag.kn {
  background-position: -2743px 0;
}
.iti-flag.kp {
  background-position: -2765px 0;
}
.iti-flag.kr {
  background-position: -2787px 0;
}
.iti-flag.kw {
  background-position: -2809px 0;
}
.iti-flag.ky {
  background-position: -2831px 0;
}
.iti-flag.kz {
  background-position: -2853px 0;
}
.iti-flag.la {
  background-position: -2875px 0;
}
.iti-flag.lb {
  background-position: -2897px 0;
}
.iti-flag.lc {
  background-position: -2919px 0;
}
.iti-flag.li {
  background-position: -2941px 0;
}
.iti-flag.lk {
  background-position: -2963px 0;
}
.iti-flag.lr {
  background-position: -2985px 0;
}
.iti-flag.ls {
  background-position: -3007px 0;
}
.iti-flag.lt {
  background-position: -3029px 0;
}
.iti-flag.lu {
  background-position: -3051px 0;
}
.iti-flag.lv {
  background-position: -3073px 0;
}
.iti-flag.ly {
  background-position: -3095px 0;
}
.iti-flag.ma {
  background-position: -3117px 0;
}
.iti-flag.mc {
  background-position: -3139px 0;
}
.iti-flag.md {
  background-position: -3160px 0;
}
.iti-flag.me {
  background-position: -3182px 0;
}
.iti-flag.mf {
  background-position: -3204px 0;
}
.iti-flag.mg {
  background-position: -3226px 0;
}
.iti-flag.mh {
  background-position: -3248px 0;
}
.iti-flag.mk {
  background-position: -3270px 0;
}
.iti-flag.ml {
  background-position: -3292px 0;
}
.iti-flag.mm {
  background-position: -3314px 0;
}
.iti-flag.mn {
  background-position: -3336px 0;
}
.iti-flag.mo {
  background-position: -3358px 0;
}
.iti-flag.mp {
  background-position: -3380px 0;
}
.iti-flag.mq {
  background-position: -3402px 0;
}
.iti-flag.mr {
  background-position: -3424px 0;
}
.iti-flag.ms {
  background-position: -3446px 0;
}
.iti-flag.mt {
  background-position: -3468px 0;
}
.iti-flag.mu {
  background-position: -3490px 0;
}
.iti-flag.mv {
  background-position: -3512px 0;
}
.iti-flag.mw {
  background-position: -3534px 0;
}
.iti-flag.mx {
  background-position: -3556px 0;
}
.iti-flag.my {
  background-position: -3578px 0;
}
.iti-flag.mz {
  background-position: -3600px 0;
}
.iti-flag.na {
  background-position: -3622px 0;
}
.iti-flag.nc {
  background-position: -3644px 0;
}
.iti-flag.ne {
  background-position: -3666px 0;
}
.iti-flag.nf {
  background-position: -3686px 0;
}
.iti-flag.ng {
  background-position: -3708px 0;
}
.iti-flag.ni {
  background-position: -3730px 0;
}
.iti-flag.nl {
  background-position: -3752px 0;
}
.iti-flag.no {
  background-position: -3774px 0;
}
.iti-flag.np {
  background-position: -3796px 0;
  background-color: transparent;
}
.iti-flag.nr {
  background-position: -3811px 0;
}
.iti-flag.nu {
  background-position: -3833px 0;
}
.iti-flag.nz {
  background-position: -3855px 0;
}
.iti-flag.om {
  background-position: -3877px 0;
}
.iti-flag.pa {
  background-position: -3899px 0;
}
.iti-flag.pe {
  background-position: -3921px 0;
}
.iti-flag.pf {
  background-position: -3943px 0;
}
.iti-flag.pg {
  background-position: -3965px 0;
}
.iti-flag.ph {
  background-position: -3987px 0;
}
.iti-flag.pk {
  background-position: -4009px 0;
}
.iti-flag.pl {
  background-position: -4031px 0;
}
.iti-flag.pm {
  background-position: -4053px 0;
}
.iti-flag.pn {
  background-position: -4075px 0;
}
.iti-flag.pr {
  background-position: -4097px 0;
}
.iti-flag.ps {
  background-position: -4119px 0;
}
.iti-flag.pt {
  background-position: -4141px 0;
}
.iti-flag.pw {
  background-position: -4163px 0;
}
.iti-flag.py {
  background-position: -4185px 0;
}
.iti-flag.qa {
  background-position: -4207px 0;
}
.iti-flag.re {
  background-position: -4229px 0;
}
.iti-flag.ro {
  background-position: -4251px 0;
}
.iti-flag.rs {
  background-position: -4273px 0;
}
.iti-flag.ru {
  background-position: -4295px 0;
}
.iti-flag.rw {
  background-position: -4317px 0;
}
.iti-flag.sa {
  background-position: -4339px 0;
}
.iti-flag.sb {
  background-position: -4361px 0;
}
.iti-flag.sc {
  background-position: -4383px 0;
}
.iti-flag.sd {
  background-position: -4405px 0;
}
.iti-flag.se {
  background-position: -4427px 0;
}
.iti-flag.sg {
  background-position: -4449px 0;
}
.iti-flag.sh {
  background-position: -4471px 0;
}
.iti-flag.si {
  background-position: -4493px 0;
}
.iti-flag.sj {
  background-position: -4515px 0;
}
.iti-flag.sk {
  background-position: -4537px 0;
}
.iti-flag.sl {
  background-position: -4559px 0;
}
.iti-flag.sm {
  background-position: -4581px 0;
}
.iti-flag.sn {
  background-position: -4603px 0;
}
.iti-flag.so {
  background-position: -4625px 0;
}
.iti-flag.sr {
  background-position: -4647px 0;
}
.iti-flag.ss {
  background-position: -4669px 0;
}
.iti-flag.st {
  background-position: -4691px 0;
}
.iti-flag.sv {
  background-position: -4713px 0;
}
.iti-flag.sx {
  background-position: -4735px 0;
}
.iti-flag.sy {
  background-position: -4757px 0;
}
.iti-flag.sz {
  background-position: -4779px 0;
}
.iti-flag.ta {
  background-position: -4801px 0;
}
.iti-flag.tc {
  background-position: -4823px 0;
}
.iti-flag.td {
  background-position: -4845px 0;
}
.iti-flag.tf {
  background-position: -4867px 0;
}
.iti-flag.tg {
  background-position: -4889px 0;
}
.iti-flag.th {
  background-position: -4911px 0;
}
.iti-flag.tj {
  background-position: -4933px 0;
}
.iti-flag.tk {
  background-position: -4955px 0;
}
.iti-flag.tl {
  background-position: -4977px 0;
}
.iti-flag.tm {
  background-position: -4999px 0;
}
.iti-flag.tn {
  background-position: -5021px 0;
}
.iti-flag.to {
  background-position: -5043px 0;
}
.iti-flag.tr {
  background-position: -5065px 0;
}
.iti-flag.tt {
  background-position: -5087px 0;
}
.iti-flag.tv {
  background-position: -5109px 0;
}
.iti-flag.tw {
  background-position: -5131px 0;
}
.iti-flag.tz {
  background-position: -5153px 0;
}
.iti-flag.ua {
  background-position: -5175px 0;
}
.iti-flag.ug {
  background-position: -5197px 0;
}
.iti-flag.um {
  background-position: -5219px 0;
}
.iti-flag.us {
  background-position: -5241px 0;
}
.iti-flag.uy {
  background-position: -5263px 0;
}
.iti-flag.uz {
  background-position: -5285px 0;
}
.iti-flag.va {
  background-position: -5307px 0;
}
.iti-flag.vc {
  background-position: -5324px 0;
}
.iti-flag.ve {
  background-position: -5346px 0;
}
.iti-flag.vg {
  background-position: -5368px 0;
}
.iti-flag.vi {
  background-position: -5390px 0;
}
.iti-flag.vn {
  background-position: -5412px 0;
}
.iti-flag.vu {
  background-position: -5434px 0;
}
.iti-flag.wf {
  background-position: -5456px 0;
}
.iti-flag.ws {
  background-position: -5478px 0;
}
.iti-flag.xk {
  background-position: -5500px 0;
}
.iti-flag.ye {
  background-position: -5522px 0;
}
.iti-flag.yt {
  background-position: -5544px 0;
}
.iti-flag.za {
  background-position: -5566px 0;
}
.iti-flag.zm {
  background-position: -5588px 0;
}
.iti-flag.zm {
  background-position: -5610px 0;
}

body > .iti-container {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
}
body > .iti-container .country-list {
  position: static;
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.partner-input {
  padding: 60px 24px 60px;
  background: #f9fdff;
}
.partner-input .txt-box {
  text-align: center;
}
.partner-input .txt-box:before {
  content: "";
  display: block;
  width: calc(100% + 48px);
  height: 473px;
  margin-left: -24px;
  background: url("../images/main_n01.png") no-repeat 50% 50%;
  background-size: auto 100%;
}
.partner-input .txt-box p {
  margin-top: 32px;
  font-size: 28px;
  line-height: px;
}
.partner-input .txt-box p em {
  font-weight: bold;
}
.partner-input .txt-box p span {
  position: relative;
  white-space: nowrap;
}
.partner-input .txt-box .in-btn {
  margin-top: 32px;
}
.partner-input .txt-box .in-btn a {
  display: block;
  max-width: 400px;
  height: 52px;
  margin: 0 auto;
  background: #5539ff;
  color: #fff;
  font-size: 16px;
  line-height: 52px;
  border-radius: 2px;
}
@media (min-width: 1000px) {
  .partner-input .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1040px;
    min-height: 540px;
    margin: 0 auto;
  }
  .partner-input .txt-box {
    width: 50%;
    max-width: 360px;
    box-sizing: border-box;
    text-align: left;
  }
  
  .partner-input .txt-box:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 540px;
    height: 540px;
  }
  .partner-input .txt-box em {
    line-height: 48px;
  }
  .partner-input .txt-box p {
    font-size: 32px;
    line-height: 32px;
  }
  .partner-input .txt-box p span.title-description {
    line-height: 0rem;
  }
  .partner-input .txt-box .in-btn {
    text-align: center;
  }
}

.for-company,
.for-talent {
  padding: 65px 24px 75px;
  background: #5539ff;
  color: #fff;
}
.for-company .inner,
.for-talent .inner {
  position: relative;
  max-width: 1040px;
  margin: 0 auto;
}
.for-company .inner .hgroup,
.for-talent .inner .hgroup {
  text-align: center;
}
.for-company .inner .hgroup h2,
.for-talent .inner .hgroup h2 {
  color: #fff;
  font-weight: normal;
  font-size: 22px;
}
.for-company .inner .hgroup p,
.for-talent .inner .hgroup p {
  margin-top: 17px;
  font-size: 28px;
  font-weight: bold;
  line-height: 42px;
}
.for-company .inner .hgroup:after,
.for-talent .inner .hgroup:after {
  content: "";
  display: block;
  width: 327px;
  height: 327px;
  margin: 16px auto 0;
  background: url("../images/main_n0201.png") no-repeat 50% 50%;
  background-size: cover;
}
.for-company .inner ul,
.for-talent .inner ul {
  margin-top: 113px;
}
.for-company .inner ul li,
.for-talent .inner ul li {
  margin-top: 16px;
  padding: 24px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  line-height: 30px;
}
.for-company .inner ul li i,
.for-talent .inner ul li i {
  color: #e0fe52;
  font-size: 16px;
}
.for-company .inner ul li p,
.for-talent .inner ul li p {
  margin-top: 16px;
  font-size: 16px;
  letter-spacing: 0.5px;
}
@media (min-width: 1000px) {
  .for-company .inner,
  .for-talent .inner {
    display: flex;
  }
  .for-company .inner .hgroup,
  .for-talent .inner .hgroup {
    text-align: left;
  }
  .for-company .inner .hgroup p,
  .for-talent .inner .hgroup p {
    margin-top: 24px;
    font-size: 32px;
    line-height: 48px;
  }
  .for-company .inner .hgroup:after,
  .for-talent .inner .hgroup:after {
    width: 400px;
    height: 400px;
    margin-top: 40px;
  }
  .for-company .inner ul,
  .for-talent .inner ul {
    width: 51%;
    max-width: 520px;
    margin-left: auto;
  }
  .for-company .inner ul li,
  .for-talent .inner ul li {
    padding-left: 40px;
    padding-right: 40px;
  }
  .for-company .inner ul li p {
    font-size: 20px;
  }
}

.for-talent {
  background: #0d1240;
}
.for-talent .inner .hgroup:after {
  background-image: url("../images/main_n0202.png");
}

.recruit-w {
  padding: 93px 24px 64px;
  background: #f9fdff;
  text-align: center;
}
.recruit-w h2 {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 180px;
  font-size: 22px;
  font-weight: bold;
  line-height: 34px;
}
.recruit-w h2:before {
  content: "";
  display: block;
  position: absolute;
  width: 190px;
  height: 90px;
  background-image: linear-gradient(to left, #e0fe52, rgba(224, 254, 82, 0));
  border-radius: 80px;
  transform: rotate(-45deg);
}
.recruit-w h2 span {
  z-index: 2;
}
.recruit-w h2 br {
  display: none;
}
.recruit-w table {
  margin-top: 16px;
  font-weight: bold;
}
.recruit-w table th {
  position: relative;
  width: 50%;
  height: 74px;
  border-bottom: 1px solid #5539ff;
  color: #5539ff;
  font-weight: bold;
}
.recruit-w table th:first-child {
  color: rgba(85, 57, 255, 0.4);
}
.recruit-w table td {
  padding: 24px 0;
  font-size: 14px;
  border-bottom: 1px solid rgba(85, 57, 255, 0.1);
}
.recruit-w table td:first-child {
  color: rgba(13, 18, 64, 0.4);
}
.recruit-w table th:first-child, .recruit-w table td:first-child {
  font-weight: normal;
}
.recruit-w .in-btn {
  max-width: 512px;
  margin: 0 auto;
  padding-top: 30px;
}
.recruit-w .in-btn a {
  display: block;
  margin-top: 16px;
  padding: 19px 0;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #5539ff;
}
.recruit-w .in-btn a em {
  font-weight: bold;
}
.recruit-w .in-btn a.b-comp {
  background: #5539ff;
  color: #fff;
}
@media (min-width: 1000px) {
  .recruit-w {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .recruit-w p {
    height: 250px;
    font-size: 32px;
    line-height: 48px;
  }
  .recruit-w p:before {
    width: 300px;
    height: 150px;
  }
  .recruit-w p br {
    display: inline;
  }
  .recruit-w table {
    max-width: 1040px;
    margin: 64px auto 0;
    font-size: 22px;
  }
  .recruit-w table th {
    height: 82px;
  }
  .recruit-w table th:last-child {
    background-size: 90px auto;
  }
  .recruit-w table td {
    font-size: 22px;
  }
  .recruit-w .in-btn {
    padding-top: 48px;
  }
  .recruit-w .in-btn a {
    font-size: 22px;
  }
}

.about-seeso {
  padding: 64px 24px;
  color: #fff;
  background: #5539ff;
  text-align: center;
  line-height: 26px;
}
.about-seeso .inner {
  position: relative;
  max-width: 1040px;
  margin: 0 auto;
  font-size: 16px;
}
.about-seeso .inner h2 {
  margin-top: 16px;
  color: #fff;
  font-size: 28px;
}
.about-seeso .inner h2:first-child {
  margin-top: 0;
}
.about-seeso .inner p {
  margin-top: 16px;
}
.about-seeso .inner ul {
  margin-top: 16px;
}
.about-seeso .inner ul:after {
  content: "";
  display: block;
  width: 327px;
  height: 327px;
  margin: 16px auto 0;
  background: url("../images/main_n0301.png") no-repeat 50% 50%;
  background-size: cover;
}
.about-seeso .inner .in-btn {
  margin-top: 16px;
}
.about-seeso .inner .in-btn a {
  display: block;
  padding: 13px 0;
  background: #fff;
  color: #5539ff;
  text-align: center;
}
@media (min-width: 1000px) {
  .about-seeso {
    padding-top: 120px;
    padding-bottom: 120px;
    text-align: left;
  }
  .about-seeso .inner {
    display: flex;
    box-sizing: border-box;
  }
  .about-seeso .inner .txt-box {
    width: 400px;
  }
  .about-seeso .inner h2 {
    margin-top: 40px;
    font-size: 32px;
    line-height: 48px;
  }
  .about-seeso .inner ul:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 440px;
    height: 440px;
    margin: 0;
  }
  .about-seeso .inner .in-btn {
    margin-top: 40px;
  }
}

.steam-def {
  padding: 104px 24px 32px;
  text-align: center;
  background: #f9fdff;
}
.steam-def strong {
  font-size: 28px;
  line-height: 42px;
}
.steam-def strong br {
  display: none;
}
.steam-def p {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
}
@media (min-width: 1000px) {
  .steam-def {
    padding-top: 136px;
    padding-bottom: 80px;
  }
  .steam-def strong {
    font-size: 32px;
    line-height: 48px;
  }
  .steam-def strong br {
    display: inline;
  }
}

.how-tab {
  padding: 0 24px;
  background: #f9fdff;
}
.how-tab ul {
  display: flex;
  justify-content: center;
}
.how-tab ul li {
  width: calc(50% - 3px);
  max-width: 200px;
  margin: 0 3px;
  text-align: center;
}
.how-tab ul li a {
  display: block;
  position: relative;
  padding: 19px 0;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
}
.how-tab ul li a:after {
  content: "";
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #0d1240;
}
.how-tab ul li.selected a {
  color: #0d1240;
  font-weight: bold;
}
.how-tab ul li.selected a:after {
  display: block;
}
@media (min-width: 1000px) {
  .how-tab ul li {
    margin: 0 40px;
  }
  .how-tab ul li a {
    font-size: 22px;
  }
}

.invt-code {
  padding: 64px 24px;
  text-align: center;
  background: #5539ff;
  color: #fff;
}
.invt-code .inner {
  position: relative;
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  box-sizing: border-box;
}
.invt-code .inner strong {
  font-size: 22px;
  line-height: 42px;
}
.invt-code .inner strong br {
  display: none;
}
.invt-code .inner p {
  margin-top: 16px;
  font-size: 16px;
  line-height: 26px;
}
.invt-code .inner p:after {
  content: "";
  display: block;
  width: 327px;
  height: 327px;
  margin: 0 auto;
  background: url("../images/how_rec01.png") no-repeat 50% 50%;
  background-size: cover;
}
.invt-code .inner .in-btn {
  max-width: 440px;
  margin: 0 auto;
}
.invt-code .inner .in-btn a {
  display: block;
  padding: 13px 0;
  background: #fff;
  color: #5539ff;
  border-radius: 2px;
  font-size: 16px;
  text-align: center;
  line-height: 26px;
}
.invt-code .inner .in-btn {
  margin-top: 1rem;
}
@media (min-width: 1000px) {
  .invt-code {
    min-height: 496px;
    padding-top: 120px;
    padding-bottom: 120px;
    box-sizing: border-box;
  }
  .invt-code .inner {
    text-align: left;
  }
  .invt-code .inner .txt-box {
    width: 440px;
  }
  .invt-code .inner strong {
    font-size: 32px;
    line-height: 48px;
    white-space: nowrap;
  }
  .invt-code .inner strong br {
    display: inline;
  }
  .invt-code .inner .in-btn {
    margin-top: 0px;
  }
  .invt-code .inner p:after {
    position: absolute;
    top: -90px;
    right: 0;
    width: 440px;
    height: 440px;
  }
  .invt-code .inner .in-btn {
    margin-top: 40px;
  }
}
.invt-code.comp {
  background: #0d1240;
}
.invt-code.comp .inner p:after {
  background-image: url("../images/how_comp01.png");
}
.invt-code.comp .inner .in-btn a {
  color: #0d1240;
}

.join-profile .inner {
  position: relative;
  padding: 64px 24px;
}
.join-profile .inner .txt-box {
  position: relative;
  max-width: 1040px;
  margin: 0 auto;
}
.join-profile .inner span {
  display: block;
  font-size: 22px;
  text-align: center;
}
.join-profile .inner p {
  margin-top: 16px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 42px;
  word-break: keep-all;
}
.join-profile .inner p:after {
  content: "";
  display: block;
  width: 327px;
  height: 327px;
  margin: 16px auto 0;
  background: url("../images/how_rec0201.png") no-repeat 50% 50%;
  background-size: cover;
}
.join-profile .inner p br {
  display: none;
}
.join-profile .inner ul li {
  margin-top: 16px;
  padding: 24px;
  border-radius: 4px;
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  line-height: 26px;
  box-sizing: border-box;
}
.join-profile .inner .in-btn {
  margin-top: 16px;
}
.join-profile .inner .in-btn a {
  display: block;
  padding: 18px 0;
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #5539ff;
  font-size: 16px;
  text-align: center;
}
.join-profile .inner:nth-child(2n+1) {
  background: #f9fdff;
}
.join-profile .inner:nth-child(2) p:after {
  background-image: url("../images/how_rec0202.png");
}
.join-profile .inner:nth-child(3) p:after {
  background-image: url("../images/how_rec0203.png");
}
.join-profile .inner:nth-child(4) p:after {
  background-image: url("../images/how_rec0204.png");
}
.join-profile .inner:nth-child(5) p:after {
  background-image: url("../images/how_rec0205.png");
}
@media (min-width: 1000px) {
  .join-profile .inner .txt-box {
    display: flex;
    flex-direction: column;
  }
  .join-profile .inner .txt-box * {
    width: 480px;
  }
  .join-profile .inner .txt-box span, .join-profile .inner .txt-box p {
    text-align: left;
  }
  .join-profile .inner .txt-box ul {
    padding-top: 16px;
  }
  .join-profile .inner .txt-box p {
    font-size: 32px;
    line-height: 48px;
  }
  .join-profile .inner .txt-box p:after {
    position: absolute;
    top: 40px;
    right: 0;
    width: 400px;
    height: 400px;
  }
  .join-profile .inner .txt-box p br {
    display: inline;
  }
  .join-profile .inner:nth-child(2n) .txt-box p:after {
    right: auto;
    left: 0;
  }
  .join-profile .inner:nth-child(2n) .txt-box * {
    margin-left: auto;
    text-align: left;
  }
}
.join-profile.comp .inner:nth-child(1) p:after {
  background-image: url("../images/how_comp0201.png");
}
.join-profile.comp .inner:nth-child(2) p:after {
  background-image: url("../images/how_comp0202.png");
}
.join-profile.comp .inner:nth-child(3) p:after {
  background-image: url("../images/how_comp0203.png");
}
.join-profile.comp .inner:nth-child(4) p:after {
  background-image: url("../images/how_comp0204.png");
}
.join-profile.comp .inner:nth-child(5) p:after {
  background-image: url("../images/how_comp0205.png");
}

.pricing {
  width: calc(100% - 48px);
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 24px;
  text-align: center;
}
.pricing h2 {
  font-size: 32px;
  line-height: 48px;
}
.pricing table {
  width: 100%;
  margin-top: 32px;
  border-spacing: 4px 2px;
  font-size: 14px;
}
.pricing table th, .pricing table td {
  width: 33.3%;
  padding: 13px 0;
  font-weight: bold;
  background-color: rgba(85, 57, 255, 0.03);
}
.pricing table th.disb, .pricing table td.disb {
  color: rgba(13, 18, 64, 0.4);
  background-color: rgba(13, 18, 64, 0.02);
}
.pricing table thead th {
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
}
.pricing table thead th:first-child {
  background: none;
}
.pricing table tbody th {
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
}
.pricing p.vat {
  margin-top: 4px;
  font-size: 12px;
  color: rgba(13, 18, 64, 0.4);
  text-align: right;
}
.pricing .in-btn {
  max-width: 400px;
  margin: 24px auto 0;
}
.pricing .in-btn a {
  display: block;
  padding: 11px 0;
  background: #5539ff;
  color: #fff;
  border-radius: 2px;
  font-size: 16px;
  line-height: 26px;
}
@media (min-width: 786px) {
  .pricing {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pricing table br {
    display: none;
  }
  .pricing table tbody th, .pricing table tbody td {
    padding: 24px 0;
  }
}
.pricing .membership-guide {
  overflow: hidden;
  margin-top: 24px;
  border-radius: 4px;
  background-color: rgba(224, 254, 82, 0.1);
  font-size: 14px;
}
.pricing .membership-guide .hgroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  background-color: rgba(224, 254, 82, 0.2);
}
.pricing .membership-guide .hgroup h3 {
  font-size: 14px;
}
.pricing .membership-guide .hgroup strong {
  margin: 8px auto 0;
  padding: 8px 12px;
  border-radius: 4px;
  background: #0d1240;
  color: #e0fe52;
}
.pricing .membership-guide .in-cnts {
  padding: 24px;
}
.pricing .membership-guide .in-cnts .sale {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #e0fe52;
  font-weight: bold;
}
.pricing .membership-guide .in-cnts > p {
  margin-top: 16px;
  font-size: 22px;
  font-weight: bold;
  line-height: 34px;
}
.pricing .membership-guide .in-cnts > p s {
  color: rgba(13, 18, 64, 0.4);
}
.pricing .membership-guide .in-cnts .benf-price {
  padding-top: 16px;
  text-align: left;
}
.pricing .membership-guide .in-cnts .benf-price > div {
  margin-top: 8px;
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(13, 18, 64, 0.05);
  line-height: 22px;
}
.pricing .membership-guide .in-cnts .benf-price > div .vat {
  margin-top: 0;
  font-size: 14px;
  text-align: left;
}
.pricing .membership-guide .in-cnts .guide {
  color: rgba(13, 18, 64, 0.6);
  text-align: left;
  line-height: 22px;
}
.pricing .membership-guide .in-cnts .guide h4 {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
}
.pricing .membership-guide .in-cnts .in-btn {
  max-width: 100%;
}
.pricing .membership-guide .in-cnts .in-btn a {
  background: #0d1240;
  text-align: center;
}
@media (min-width: 1000px) {
  .pricing .membership-guide .hgroup {
    position: relative;
  }
  .pricing .membership-guide .hgroup strong {
    position: absolute;
    top: 16px;
    right: 24px;
    margin-top: 0;
  }
  .pricing .membership-guide .in-cnts .benf-price {
    display: flex;
    justify-content: space-between;
  }
  .pricing .membership-guide .in-cnts .benf-price > div {
    width: calc(50% - 12px);
    box-sizing: border-box;
  }
}

.pjt-comp {
  padding: 64px 20px;
  background: #5539ff;
  text-align: center;
}
.pjt-comp .inner {
  max-width: 816px;
  margin: 0 auto;
}
.pjt-comp .inner p {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 180px;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  line-height: 34px;
}
.pjt-comp .inner p:before {
  content: "";
  display: block;
  position: absolute;
  width: 190px;
  height: 100px;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
  border-radius: 80px;
  transform: rotate(-45deg);
}
.pjt-comp .inner p span {
  z-index: 2;
}
.pjt-comp .inner ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.pjt-comp .inner ul a  {
  width: calc(50% - 8px);
}
.pjt-comp .inner ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  height: 154px;
  margin: 8px 4px 0;
  background: #fff;
  border-radius: 4px;
}
.pjt-comp .inner ul li:before {
  content: "";
  width: 80px;
  height: 80px;
  background: url("../images/icon_folder.png") no-repeat 0 0;
  background-size: cover;
}
.pjt-comp .inner ul li span.title {
  font-weight: bold;
  margin-top: 16px;
  font-size: 16px;
}
.pjt-comp .inner ul li span.tag {
  font-weight: bold;
  margin-top: 0;
  font-size: 16px;
  color: #9ca3af;
}
@media (min-width: 1000px) {
  .pjt-comp {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .pjt-comp .inner p {
    height: 250px;
    font-size: 32px;
    line-height: 48px;
  }
  .pjt-comp .inner p:before {
    width: 300px;
    height: 150px;
  }
  .pjt-comp .inner p br {
    display: inline;
  }
  .pjt-comp .inner ul {
    margin-top: 64px;
  }
  .pjt-comp .inner ul a{
    width: calc(33.3% - 16px);
    height: 170px;
    margin: 16px 8px 0;  
  }
  .pjt-comp .inner ul {

  }
  
}

.invt-app {
  padding: 64px 24px;
  background: #f9fdff;
  text-align: center;
}
.invt-app .inner {
  position: relative;
  max-width: 1040px;
  margin: 0 auto;
}
.invt-app .inner strong {
  display: block;
  font-size: 28px;
  line-height: 42px;
}
.invt-app .inner p {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  line-height: 26px;
}
.invt-app .inner p:after {
  content: "";
  display: block;
  width: 327px;
  height: 327px;
  margin: 24px auto 0;
  background: url("../images/how_rec03.png") no-repeat 50% 50%;
  background-size: cover;
}
.invt-app .inner .in-btn {
  margin-top: 24px;
}
.invt-app .inner .in-btn a {
  display: block;
  padding: 13px 0;
  background: #5539ff;
  color: #fff;
  text-align: center;
  line-height: 26px;
  border-radius: 2px;
}
@media (min-width: 1000px) {
  .invt-app {
    padding-top: 120px;
    padding-bottom: 120px;
    text-align: left;
  }
  .invt-app .inner * {
    width: 440px;
  }
  .invt-app .inner p:after {
    position: absolute;
    top: -90px;
    right: 0;
    width: 400px;
    height: 400px;
  }
  .invt-app .inner .in-btn {
    margin-top: 40px;
  }
}

.main-faq {
  padding: 64px 24px;
  background: #0d1240;
}
.main-faq h2 {
  color: #fff;
  font-size: 28px;
  text-align: center;
  line-height: 42px;
}
.main-faq .inner {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 20px;
}
.main-faq .inner ul li {
  margin-top: 16px;
  padding: 24px;
  background: #fff;
  border-radius: 4px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  line-height: 26px;
}
.main-faq .inner ul li .ans {
  display: none;
  margin-top: 16px;
}
.main-faq .inner ul li.selected em {
  color: #0d1240;
  font-weight: bold;
}
.main-faq .inner ul li.selected .ans {
  display: block;
}
.main-faq .inner .in-btn {
  margin-top: 40px;
}
.main-faq .inner .in-btn a {
  display: block;
  padding: 16px 0;
  background: #5539ff;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 26px;
  border-radius: 4px;
}
@media (min-width: 1000px) {
  .main-faq .inner ul li {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.steam-join {
  padding: 64px 24px;
  background: #5539ff;
  color: #fff;
  text-align: center;
}
.steam-join strong {
  display: block;
  font-size: 28px;
  line-height: 42px;
}
.steam-join strong br {
  display: none;
}
.steam-join p {
  margin-top: 24px;
  font-size: 16px;
}
.steam-join .in-btn:before {
  content: "";
  display: block;
  width: 327px;
  height: 327px;
  margin: 24px auto;
  background: url("../images/how_rec04.png") no-repeat 50% 50%;
  background-size: 100% auto;
}
.steam-join .in-btn a {
  display: block;
  padding: 16px 0;
  background: #fff;
  color: #5539ff;
  font-size: 16px;
  text-align: center;
  line-height: 26px;
  border-radius: 4px;
}
@media (min-width: 1000px) {
  .steam-join {
    padding-top: 120px;
    padding-bottom: 120px;
    text-align: left;
  }
  .steam-join .inner {
    position: relative;
    max-width: 1040px;
    margin: 0 auto;
  }
  .steam-join .inner * {
    width: 440px;
  }
  .steam-join .inner strong {
    font-size: 32px;
  }
  .steam-join .inner strong br {
    display: inline;
  }
  .steam-join .inner .in-btn {
    margin-top: 40px;
  }
  .steam-join .inner .in-btn:before {
    position: absolute;
    top: -60px;
    right: 0;
    width: 432px;
    height: 400px;
    margin: 0;
  }
}

.def-tab {
  margin-top: 40px;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
.def-tab ul {
  display: flex;
  font-size: 16px;
}
.def-tab ul li {
  margin-right: 24px;
}
.def-tab ul li a {
  display: block;
  padding-bottom: 8px;
  color: rgba(13, 18, 64, 0.4);
}
.def-tab ul li.selected {
  border-bottom: 1px solid #0d1240;
  font-weight: bold;
}
.def-tab ul li.selected a {
  color: #0d1240;
}
@media (min-width: 786px) {
  .def-tab ul li {
    margin-right: 40px;
  }
}

.profile-box {
  position: relative;
  border-radius: 4px;
  border: solid 1px rgba(85, 57, 255, 0.05);
  background-color: rgba(85, 57, 255, 0.03);
}
.profile-box .def-info {
  position: relative;
  padding: 24px 24px 16px;
  box-sizing: border-box;
}
.profile-box .def-info .profile {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-right: 35px;
}
.profile-box .def-info .profile .photo {
  min-width: 64px;
  height: 64px;
  margin-right: 16px;
  background-size: cover;
  border-radius: 50%;
}
.profile-box .def-info .profile .info .name strong, .profile-box .def-info .profile .info .name > span {
  display: block;
  font-size: 14px;
}
.profile-box .def-info .profile .info .name > span {
  margin-top: 4px;
  color: rgba(13, 18, 64, 0.6);
}
.profile-box .def-info > p, .profile-box .def-info .pjt-c {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  line-height: 22px;
}
.profile-box .def-info > p.line1, .profile-box .def-info .pjt-c.line1 {
  overflow: hidden;
  height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.profile-box .def-info .pjt-c h3 {
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.profile-box .def-info .pjt-c p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.profile-box .def-info .date {
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.profile-box .def-info a.dir-go {
  display: block;
  position: absolute;
  top: 40px;
  right: 35px;
  width: 32px;
  height: 32px;
  background: url("../images/icon_arrow02.svg") no-repeat 0 0;
}
.profile-box .def-info a.dir-go span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.profile-box .sub-info {
  padding: 8px 24px 16px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
}
.profile-box .sub-info .ing {
  display: -webkit-inline-flex;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: rgba(13, 18, 64, 0.02);
  font-size: 14px;
}
.profile-box .sub-info .ing:before {
  content: "";
  width: 19px;
  height: 19px;
  margin-right: 3px;
  background: url("../images/imo_people.png") no-repeat 0 0;
  background-size: auto 100%;
}
.profile-box .sub-info .ing b {
  padding-left: 4px;
}
.profile-box .sub-info .q-cnts {
  margin-top: 16px;
  font-size: 14px;
  line-height: 22px;
}
.profile-box .sub-info .link-p {
  width: calc(100% + 48px);
  margin-bottom: 14px;
  margin-left: -24px;
  padding-top: 10px;
  padding-left: 24px;
  box-sizing: border-box;
  font-size: 14px;
}
.profile-box .sub-info .link-p a {
  display: inline-flex;
  color: rgba(13, 18, 64, 0.4);
  font-weight: bold;
}
.profile-box .sub-info .link-p a:after {
  content: "";
  width: 7px;
  height: 7px;
  margin-top: 4px;
  margin-left: 2px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: rgba(13, 18, 64, 0.4);
  border-radius: 2px;
  transform: rotate(-45deg);
}
.profile-box .sub-info table {
  font-size: 14px;
}
.profile-box .sub-info table th, .profile-box .sub-info table td {
  padding: 8px 0;
}
.profile-box .sub-info table th {
  color: #0d1240;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
}
.profile-box .sub-info table td {
  text-align: right;
  color: rgba(13, 18, 64, 0.6);
}
.profile-box .sub-info .btn-view {
  position: absolute;
  top: 35px;
  right: 23px;
  width: 32px;
  height: 32px;
  background: url("../images/icon_arrow02.svg") no-repeat 0 0;
}
.profile-box .sub-info .btn-view span {
  display: block;
  font-size: 1px;
  text-indent: -9999px;
}
@media (min-width: 786px) {
  .profile-box {
    display: flex;
  }
  .profile-box > div {
    flex: 1;
    width: 50%;
  }
  .profile-box > div.def-info .profile {
    width: calc(100% + 48px);
    margin-left: -24px;
    padding-right: 0;
    padding: 0 24px 24px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(85, 57, 255, 0.05);
  }
  .profile-box > div.def-info > p.line1 {
    height: 50px;
  }
  .profile-box > div.sub-info {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 24px;
    border-top: none;
    border-left: 1px solid rgba(85, 57, 255, 0.05);
  }
  .profile-box > div.sub-info .link-p {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(85, 57, 255, 0.05);
  }
  .profile-box > div.sub-info .btn-view {
    position: static;
    width: auto;
    height: auto;
    margin-top: 16px;
    background: none;
  }
  .profile-box > div.sub-info .btn-view a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 2px;
    background-color: #5539ff;
    color: #fff;
  }
  .profile-box > div.sub-info .btn-view a span {
    font-size: 14px;
    text-indent: 0;
  }
  .profile-box > div.sub-info table br {
    display: none;
  }
}

.pjt-info {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 24px;
}
.pjt-info > h3 {
  margin-top: 40px;
  padding-bottom: 17px;
  font-size: 22px;
  line-height: 34px;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
.pjt-info > h4 {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 13px;
  border-bottom: 1px solid #5539ff;
  color: #5539ff;
  font-size: 16px;
  font-weight: normal;
}
.pjt-info > h4:before {
  content: "";
  width: 20px;
  height: 10px;
  margin-right: 8px;
  border-radius: 10px;
  background: #5539ff;
  transform: rotate(-45deg);
}
.pjt-info .info-road {
  margin-top: -35px;
  margin-bottom: 15px;
  text-align: right;
}
.pjt-info .info-road button {
  color: #5539ff;
  font-size: 12px;
  text-decoration: underline;
}
.pjt-info .pjt-cnts {
  margin-top: 16px;
  color: rgba(13, 18, 64, 0.6);
  line-height: 22px;
}
.pjt-info .pjt-cnts p {
  white-space: pre-wrap;
}
.pjt-info .pjt-cnts ul + p {
  margin-top: 30px;
}
@media (min-width: 786px) {
  .pjt-info .pjt-cnts > ul {
    padding-left: 8px;
  }
  .pjt-info .pjt-cnts > ul li {
    display: flex;
  }
  .pjt-info .pjt-cnts > ul li:before {
    content: "";
    width: 5px;
    height: 5px;
    margin: 8px 5px 0 0;
    background: rgba(13, 18, 64, 0.6);
    border-radius: 50%;
  }
}
.pjt-info .in-cnts {
  padding: 24px 0;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.6);
  line-height: 22px;
}
.pjt-info .in-cnts h5 {
  font-size: 16px;
}
.pjt-info .in-cnts > p {
  margin-top: 16px;
}
.pjt-info .in-cnts > p em {
  color: #5539ff;
  font-weight: bold;
}
.pjt-info .in-cnts input, .pjt-info .in-cnts .fm-box {
  margin-top: 8px;
}
.pjt-info .in-cnts select {
  width: 100%;
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 16px;
}
.pjt-info .in-cnts textarea {
  margin-top: 8px;
  height: 196px;
  color: #0d1240;
}
.pjt-info .in-cnts .int-form input {
  margin-top: 0;
  color: #5539ff;
}
.pjt-info .in-cnts:first-child {
  padding-top: 40px;
}
@media (min-width: 786px) {
  .pjt-info .in-cnts {
    line-height: 22px;
  }
  .pjt-info .in-cnts.no-line {
    padding: 32px 0 0;
    border: none;
  }
  .pjt-info .in-cnts.no-line > p {
    margin-top: 8px;
  }
  .pjt-info .in-cnts.no-line > p.desc {
    margin-top: 30px;
    font-size: 16px;
  }
}
.pjt-info .in-cnts2 {
  margin-top: 20px;
  color: rgba(13, 18, 64, 0.6);
}
.pjt-info .sug-type {
  margin-top: 30px;
}
.pjt-info .sug-type label {
  font-weight: bold;
  font-size: 16px;
}
.pjt-info .sug-type .sel-w {
  position: relative;
  margin-top: 6px;
}
.pjt-info .sug-type .sel-w button {
  display: block;
  position: relative;
  width: 100%;
  height: 60px;
  padding: 0 24px 0 8px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
  color: rgba(13, 18, 64, 0.4);
  text-align: left;
}
.pjt-info .sug-type .sel-w button:after {
  content: "";
  display: block;
  position: absolute;
  top: 27px;
  right: 11px;
  width: 10px;
  height: 6px;
  background: url("../images/icon_arrow.svg") no-repeat 0 0;
}
.pjt-info .sug-type .sel-w .opt-li {
  display: none;
  margin-top: 5px;
  padding: 16px 16px 6px;
  border: 1px solid #5539ff;
  border-radius: 2px;
  font-size: 14px;
}
.pjt-info .sug-type .sel-w .opt-li p {
  color: rgba(13, 18, 64, 0.4);
  font-size: 12px;
}
.pjt-info .sug-type .sel-w .opt-li ul {
  padding-top: 5px;
}
.pjt-info .sug-type .sel-w .opt-li ul li {
  padding: 10px 0;
}
.pjt-info .sug-type .sel-w .opt-li ul li.selected {
  color: #5539ff;
}
.pjt-info .sug-type .sel-w .opt-li ul li.selected a {
  color: #5539ff;
}
.pjt-info .sug-type .sel-w.selected button:after {
  transform: rotate(180deg);
}
.pjt-info .sug-type .sel-w.selected .opt-li {
  display: block;
}
@media (min-width: 786px) {
  .pjt-info .sug-type {
    display: flex;
  }
  .pjt-info .sug-type label {
    width: 138px;
  }
  .pjt-info .sug-type .sel-w {
    flex: 1;
    margin-top: 0;
  }
}

.member-wrap {
  padding-bottom: 80px;
}
.member-wrap .in-info {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 24px;
}
.member-wrap .in-info .ass-form > a {
  display: block;
  margin-top: 40px;
  padding: 24px;
  background: #E0FE52;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
}
.member-wrap .in-info .ass-form > a:before {
  content: "";
  display: block;
  position: relative;
  left: calc(50% - 70px);
  width: 200px;
  height: 160px;
  background: url("../images/ass_img.png") no-repeat 0 0;
  background-size: contain;
}
.member-wrap .in-info .ass-form > a .txt {
  margin-top: 16px;
}
@media (min-width: 786px) {
  .member-wrap .in-info .ass-form > a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    text-align: left;
  }
  .member-wrap .in-info .ass-form > a:before {
    position: static;
  }
  .member-wrap .in-info .ass-form > a .txt {
    max-width: 340px;
    margin-top: 0;
    margin-left: 68px;
  }
  .member-wrap .in-info .ass-form > a .txt br {
    display: none;
  }
}
.member-wrap .in-info .inbox {
  position: relative;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid rgba(13, 18, 64, 0.05);
}
.member-wrap .in-info .inbox:first-child {
  margin-top: 40px;
  padding-top: 0;
  border: none;
}
.member-wrap .in-info .inbox h2 {
  font-size: 16px;
}
.member-wrap .in-info .inbox .btn-more {
  position: absolute;
  top: 24px;
  right: 0;
  color: #5539ff;
}
.member-wrap .in-info .inbox .btn-more:after {
  content: ">";
  margin-left: 5px;
}
@media (min-width: 786px) {
  .member-wrap .in-info .inbox {
    margin-top: 32px;
    padding-top: 32px;
  }
  .member-wrap .in-info .inbox:first-child {
    margin-top: 64px;
  }
  .member-wrap .in-info .inbox .btn-more {
    top: 32px;
  }
}
.member-wrap .in-info .ass-form + .inbox {
  margin-top: 0;
  border-top: none;
}

.data-status {
  margin-top: 20px;
  padding: 20px 22px;
  border-radius: 4px;
  background-color: rgba(13, 18, 64, 0.02);
}
.data-status h2 {
  font-size: 16px;
  font-weight: normal;
}
.data-status h2 em {
  font-weight: bold;
}
.data-status ul li {
  position: relative;
  margin-top: 25px;
  padding-left: 64px;
}
.data-status ul li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 54px;
  height: 54px;
  background: #fff url("../images/icon_datast.svg") no-repeat 14px 50%;
  border-radius: 50%;
}
.data-status ul li p {
  padding-top: 5px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.data-status ul li > em {
  display: block;
  margin-top: 3px;
  font-size: 18px;
  font-weight: 500;
}
.data-status ul li > em span {
  padding-left: 5px;
  font-size: 16px;
  font-weight: normal;
}
.data-status ul li.t-like:before {
  background-position: -76px 50%;
}
.data-status ul li.t-long:before {
  background-position: -121px 50%;
}
.data-status ul li.t-many:before {
  background-position: -168px 50%;
}
.data-status ul li.t-recu:before {
  background-position: -32px 50%;
}
@media (min-width: 786px) {
  .data-status {
    padding: 30px 40px;
  }
  .data-status ul {
    display: flex;
    flex-wrap: wrap;
  }
  .data-status ul li {
    width: 50%;
    box-sizing: border-box;
  }
}

.graph-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  padding: 24px 0;
  border-radius: 4px;
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
}
.graph-wrap ul {
  padding-top: 15px;
  font-size: 16px;
}
.graph-wrap ul li {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.graph-wrap ul li em {
  font-weight: bold;
}
.graph-wrap ul li:before {
  content: "";
  min-width: 8px;
  height: 8px;
  margin-right: 8px;
  background: #5539ff;
  border-radius: 50%;
}
.graph-wrap ul li.gt2:before {
  background: #96ffc6;
}
.graph-wrap ul li.gt3:before {
  background: #ffb494;
}
@media (min-width: 786px) {
  .graph-wrap {
    flex-direction: row;
    justify-content: center;
    padding: 32px 0;
  }
  .graph-wrap .graph img {
    width: 200px;
    height: 200px;
  }
  .graph-wrap ul {
    margin-left: 80px;
    padding-top: 0;
  }
  .graph-wrap ul li {
    margin-top: 16px;
  }
  .graph-wrap ul li:first-child {
    margin-top: 0;
  }
}

.new-member .member-box {
  margin-top: 16px;
  border: solid 1px rgba(85, 57, 255, 0.05);
  background-color: rgba(85, 57, 255, 0.03);
  border-radius: 4px;
}
.new-member .member-box .profile {
  display: flex;
  align-items: center;
  padding: 24px;
}
.new-member .member-box .profile .photo {
  min-width: 64px;
  height: 64px;
  margin-right: 16px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.new-member .member-box .profile .info {
  flex: 1;
}
.new-member .member-box .profile .info .name h3 {
  font-size: 14px;
  box-sizing: border-box;
}
.new-member .member-box .profile .info .name span {
  display: block;
  margin-top: 4px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.new-member .member-box .profile .info p {
  margin-top: 4px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.new-member .member-box .use-pjt {
  padding: 16px 24px 24px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
}
.new-member .member-box .use-pjt h4, .new-member .member-box .use-pjt p {
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  line-height: 22px;
}
.new-member .member-box .use-pjt .mb-btn {
  display: flex;
  margin-top: 16px;
}
.new-member .member-box .use-pjt .mb-btn a, .new-member .member-box .use-pjt .mb-btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
  height: 52px;
  margin-left: 8px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
  text-align: center;
  white-space: nowrap;
}
.new-member .member-box .use-pjt .mb-btn a:first-child, .new-member .member-box .use-pjt .mb-btn button:first-child {
  margin-left: 0;
}
.new-member .member-box .use-pjt .mb-btn a.btn-like, .new-member .member-box .use-pjt .mb-btn button.btn-like {
  min-width: 52px;
  max-width: 52px;
  background-image: url("../images/icon_heart_no.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.new-member .member-box .use-pjt .mb-btn a.btn-like.act, .new-member .member-box .use-pjt .mb-btn button.btn-like.act {
  background-image: url("../images/icon_heart.svg");
}
.new-member .member-box .use-pjt .mb-btn a.btn-like span, .new-member .member-box .use-pjt .mb-btn button.btn-like span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.new-member .member-box .use-pjt .mb-btn a.actv, .new-member .member-box .use-pjt .mb-btn button.actv {
  background: #5539ff;
  color: #fff;
}
.new-member .member-box .use-pjt .mb-btn a.actv > span, .new-member .member-box .use-pjt .mb-btn button.actv > span {
  display: none;
}
@media (min-width: 786px) {
  .new-member {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .new-member .member-box {
    width: calc(50% - 12px);
    margin-top: 24px;
  }
  .new-member .member-box .use-pjt p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .new-member .member-box .use-pjt .mb-btn a.actv span, .new-member .member-box .use-pjt .mb-btn button.actv span {
    display: inline-block;
    margin-right: 5px;
  }
}

.skill-wrap {
  margin-top: 16px;
  padding: 24px;
  border-radius: 4px;
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
}
.skill-wrap .in-tab {
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
.skill-wrap .in-tab ul {
  display: flex;
}
.skill-wrap .in-tab ul li {
  margin-right: 40px;
}
.skill-wrap .in-tab ul li a {
  display: block;
  padding-bottom: 8px;
  color: rgba(13, 18, 64, 0.4);
  white-space: nowrap;
}
.skill-wrap .in-tab ul li.selected {
  margin-bottom: -1px;
  border-bottom: 1px solid #0d1240;
}
.skill-wrap .in-tab ul li.selected a {
  color: #0d1240;
  font-weight: bold;
}
.skill-wrap .skill-box {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
}
.skill-wrap .skill-box > span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  margin: 8px 8px 0 0;
  padding: 0 16px;
  border-radius: 2px;
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
  white-space: nowrap;
}
.skill-wrap .skill-box .in-more {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 42px;
  margin: 8px 8px 0 0;
  padding: 0 36px 0 16px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
}
.skill-wrap .skill-box .in-more:before, .skill-wrap .skill-box .in-more:after {
  content: "";
  display: block;
  position: absolute;
  background: #5539ff;
}
.skill-wrap .skill-box .in-more:before {
  top: calc(50% - 1px);
  right: 19px;
  width: 11px;
  height: 1px;
}
.skill-wrap .skill-box .in-more:after {
  top: calc(50% - 6px);
  right: 24px;
  width: 1px;
  height: 11px;
}
.skill-wrap .no-data p {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.4);
  font-weight: bold;
  line-height: 26px;
}
@media (min-width: 786px) {
  .skill-wrap .skill-box > span, .skill-wrap .skill-box .in-more {
    height: 38px;
    font-size: 14px;
  }
  .skill-wrap .skill-box > span {
    padding: 0 24px;
  }
  .skill-wrap .no-data p br {
    display: none;
  }
}

.no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  margin-top: 40px;
  text-align: center;
}
.no-result:before {
  content: "";
  width: 200px;
  height: 200px;
  background: url("../images/no_result.png") no-repeat 50% 50%;
  background-size: cover;
}
.no-result p {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.4);
  line-height: 22px;
}
.no-result .btn-reset {
  width: 100%;
  margin-top: 24px;
  color: #5539ff;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
}
@media (min-width: 786px) {
  .no-result p {
    font-size: 22px;
    line-height: 30px;
  }
}

.invite-w {
  padding: 40px 24px;
}
.invite-w .inner {
  max-width: 608px;
  margin: 0 auto;
  padding: 32px 24px 24px;
  border: 1px solid rgba(111, 114, 138, 0.05);
  box-sizing: border-box;
}
.invite-w .inner > p {
  padding-bottom: 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 22px;
  text-align: center;
  line-height: 34px;
}
.invite-w .inner .code-copy {
  margin-top: 16px;
  padding: 16px 24px 24px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.invite-w .inner .code-copy input {
  height: auto;
  background: none;
  color: rgba(13, 18, 64, 0.6);
  text-align: center;
}
.invite-w .inner .code-copy button {
  width: 100%;
  height: 40px;
  margin-top: 16px;
  background: #5539ff;
  border-radius: 2px;
  color: #fff;
}
.invite-w .inner .inv-end {
  margin-top: 16px;
  padding: 16px 24px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
  text-align: center;
}
.invite-w .inner .inv-end p {
  color: rgba(13, 18, 64, 0.6);
  line-height: 26px;
}
.invite-w .inner .inv-end p em {
  font-weight: bold;
}
.invite-w .inner .inv-end > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 16px;
  border-radius: 2px;
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.4);
}
@media (min-width: 786px) {
  .invite-w {
    padding-top: 64px;
  }
  .invite-w .inner > p br {
    display: none;
  }
  .invite-w .inner .code-copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
  .invite-w .inner .code-copy input {
    padding: 0;
    text-align: left;
  }
  .invite-w .inner .code-copy button {
    min-width: 80px;
    width: 80px;
    margin: 0;
  }
  .invite-w .inner .inv-end {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
  .invite-w .inner .inv-end > div {
    min-width: 80px;
    width: 80px;
    margin: 0;
  }
}

.pw-reset {
  max-width: 400px;
  margin: 0 auto;
}
.pw-reset > p {
  margin-top: 25px;
  font-size: 16px;
  line-height: 26px;
}
.pw-reset > p em {
  font-weight: bold;
}
.pw-reset .desc-link {
  margin-top: 32px;
  font-size: 14px;
}
.pw-reset .desc-link p {
  color: rgba(13, 18, 64, 0.6);
}
.pw-reset .desc-link p a {
  color: #5539ff;
}
.pw-reset .email-input {
  margin-top: 28px;
  text-align: left;
}
.pw-reset .email-input label {
  display: block;
  padding-bottom: 8px;
}
.pw-reset .email-input > div {
  margin-top: 24px;
}
.pw-reset .email-input > div > span {
  display: block;
  position: relative;
}
.pw-reset .email-input > div > span button {
  top: 20px;
}
.pw-reset .email-input > div:first-child {
  margin-top: 0;
}
.pw-reset .def-btn {
  display: block;
  width: 100%;
  height: 48px;
  margin-top: 32px;
  background: #5539ff;
  border-radius: 2px;
  color: #fff;
  line-height: 48px;
}

.mem-add {
  overflow: auto;
  height: 100vh;
  padding: 130px 24px;
  box-sizing: border-box;
}
.mem-add .viewport {
  max-width: 548px;
  margin: 0 auto;
}
.mem-add .viewport h2 {
  display: block;
  font-size: 28px;
  font-weight: normal;
  text-align: center;
  line-height: 42px;
}
.mem-add .viewport > p {
  margin-top: 20px;
  color: rgba(13, 18, 64, 0.6);
  line-height: 22px;
}
.mem-add .viewport > p > em {
  color: #5539ff;
}
.mem-add .viewport > p a {
  color: #5539ff;
  text-decoration: underline;
}
.mem-add .viewport > p.em {
  color: #0d1240;
}
.mem-add .viewport .pjt-chklist {
  padding-top: 30px;
}
.mem-add .viewport .pjt-chklist ul li {
  margin-top: 20px;
}
.mem-add .viewport .pjt-chklist ul li i {
  position: relative;
  padding-left: 15px;
  color: #5539ff;
  font-size: 14px;
}
.mem-add .viewport .pjt-chklist ul li i:before {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 7px;
  height: 4px;
  margin-right: 8px;
  border-radius: 10px;
  background: #5539ff;
  transform: rotate(-45deg);
}
.mem-add .viewport .pjt-chklist ul li label {
  display: block;
  position: relative;
  margin-top: 3px;
  padding: 0 30px 0 0;
  line-height: 26px;
}
.mem-add .viewport .pjt-chklist ul li label:before {
  left: auto;
  right: 0;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-color: #5539ff;
}
.mem-add .viewport .pjt-chklist ul li label:after {
  left: auto;
  top: 9px;
  right: 5px;
}
.mem-add .viewport .pjt-chklist ul li p {
  margin-top: 3px;
  line-height: 26px;
}
.mem-add .viewport .pjt-chklist ul li p.desc {
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  line-height: 22px;
}
.mem-add .viewport .pjt-chklist ul li > div {
  display: none;
  padding-top: 10px;
  padding-left: 20px;
}
.mem-add .viewport .pjt-chklist ul li > div > label {
  display: block;
  margin-top: 10px;
  padding-bottom: 6px;
  font-size: 14px;
}
.mem-add .viewport .pjt-chklist ul li input:checked ~ div {
  display: block;
}
.mem-add .viewport .pjt-chklist ul li.my-info {
  padding: 20px 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: rgba(13, 18, 64, 0.1);
}
.mem-add .viewport .pjt-chklist ul li.my-info > div {
  display: block;
}
.mem-add .viewport .btn {
  margin-top: 60px;
}
.mem-add .viewport .btn.mtype2 {
  margin-top: 30px;
}
.mem-add .viewport .num-sect {
  margin-top: 50px;
  padding: 20px;
  background: rgba(13, 18, 64, 0.02);
}
.mem-add .viewport .num-sect h3 {
  font-weight: normal;
}
.mem-add .viewport .num-sect ul li {
  position: relative;
  margin-top: 28px;
  padding-left: 64px;
}
.mem-add .viewport .num-sect ul li > span {
  display: block;
  margin-top: 4px;
  color: #5539ff;
  white-space: nowrap;
}
.mem-add .viewport .num-sect ul li > span em {
  margin-right: 3px;
  font-size: 18px;
  font-weight: 500;
}
.mem-add .viewport .num-sect ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
  width: 54px;
  height: 54px;
  background: url("../images/icon_smem.svg") no-repeat 0 0;
}
.mem-add .viewport .num-sect ul li.nv2:before {
  background-position: 0 -56px;
}
.mem-add .viewport .num-sect ul li.nv3:before {
  background-position: 0 -112px;
}
.mem-add .viewport .num-sect ul li.nv4:before {
  background-position: 0 -168px;
}
.mem-add .viewport .num-sect ul li.nv5:before {
  background-position: 0 100%;
}
@media (min-width: 786px) {
  .mem-add .viewport h2 {
    font-size: 22px;
  }
  .mem-add .viewport h2 br {
    display: none;
  }
  .mem-add .viewport .btn > a, .mem-add .viewport .btn > button {
    max-width: 400px;
    margin: 0 auto !important;
  }
  .mem-add .viewport .num-sect {
    padding: 38px;
  }
  .mem-add .viewport .num-sect ul li {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .mem-add .viewport .num-sect ul li:before {
    position: static;
    margin-right: 10px;
  }
  .mem-add .viewport .num-sect ul li > span {
    margin-left: auto;
  }
}

.landing-w,
.lading-before {
  font-family: "Pretendard", "SFUIDisplay", "NotoSansCJKkr", "Roboto", "Droid Sans", "Malgun Gothic", "Helvetica", "Apple-Gothic", "애플고딕", "Tahoma", dotum, "돋움", gulim, "굴림", sans-serif;
  -webkit-font-smoothing: antialiased;
}
.landing-w strong,
.lading-before strong {
  font-weight: 700;
}

.landing-h {
  position: sticky !important;
  height: 60px;
  margin-bottom: 0;
  padding-top: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.landing-h .nav-w {
  display: none;
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin-top: 55px;
  background: rgba(0, 0, 0, 0.15);
}
.landing-h .nav-w .inner-w {
  overflow: auto;
  background: #fff;
}
.landing-h .nav-w ul li a {
  display: block;
  padding: 10px;
  font-size: 14px;
}
.landing-h .nav-w nav {
  position: relative;
  padding: 10px 10px 20px;
}
.landing-h .nav-w nav:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 5px;
  left: 20px;
  width: calc(100% - 40px);
  height: 1px;
  background: #E6E6E6;
}
.landing-h .nav-w .m-inner {
  padding: 10px 10px 20px;
}
.landing-h .nav-w.selected {
  display: block;
}
.landing-h.lg-before .nav-w .inner-w nav {
  display: none;
}
.landing-h .mem-w {
  margin-top: 5px;
}
.landing-h .btn-menu {
  top: 10px;
}
.landing-h .inner {
  top: auto;
  margin-top: 55px;
  padding: 0;
  background: rgba(0, 0, 0, 0.15);
}
.landing-h .inner .inbox {
  padding: 0 20px 10px;
  background: #fff;
}
.landing-h .inner .inbox > nav, .landing-h .inner .inbox .utill {
  padding: 15px 0;
}
.landing-h .inner .inbox > nav ul li, .landing-h .inner .inbox .utill ul li {
  padding: 0;
}
.landing-h .inner .inbox > nav ul li a, .landing-h .inner .inbox .utill ul li a {
  display: block;
  padding: 10px 0;
  color: #26262A;
  font-size: 14px;
}
.landing-h .inner .inbox .utill {
  margin-top: 0;
  border-top: 1px solid #E6E6E6;
}
@media (min-width: 786px) {
  .landing-h {
    height: 78px;
    padding-top: 22px;
  }
  .landing-h .btn-menu {
    top: 18px;
  }
  .landing-h .nav-w {
    display: block;
    position: static;
    width: 100%;
    height: auto;
    margin: 0;
    background: none;
  }
  .landing-h .nav-w .inner-w {
    display: flex;
    justify-content: space-between;
    background: none;
  }
  .landing-h .nav-w nav {
    padding: 0;
  }
  .landing-h .nav-w nav ul {
    display: flex;
  }
  .landing-h .nav-w nav ul li {
    margin-left: 40px;
  }
  .landing-h .nav-w nav:after {
    display: none;
  }
  .landing-h .nav-w .m-inner {
    overflow: hidden;
    margin-left: auto;
    padding: 0;
    background: none;
  }
  .landing-h .nav-w .m-inner ul {
    display: flex;
  }
  .landing-h .nav-w .m-inner ul li {
    margin-left: 8px;
  }
  .landing-h .nav-w .m-inner ul li a {
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
  }
  .landing-h .nav-w .m-inner ul li a:hover {
    background: #F4F4F5;
  }
  .landing-h .nav-w .m-inner ul li a.actv {
    background: #5539ff;
    color: #fff;
    font-weight: bold;
  }
  .landing-h.lg-before {
    display: flex;
  }
  .landing-h.lg-before .btn-menu {
    display: none;
  }
  .landing-h .mem-w {
    top: 25px;
    margin-top: 0;
  }
  .landing-h .inner {
    margin: 3px 0 0;
    background: none;
  }
  .landing-h .inner .inbox {
    padding: 0;
  }
  .landing-h .inner .inbox > nav, .landing-h .inner .inbox .utill {
    margin: 0;
    padding: 0;
  }
  .landing-h .inner .inbox nav ul li {
    margin-left: 40px;
  }
  .landing-h .inner .inbox .utill {
    top: 71px;
    padding: 16px 0;
    border: 1px solid #5539ff;
  }
  .landing-h .inner .inbox .utill ul li a {
    display: inline-block;
    padding: 0;
  }
}

.landing-cnts {
  padding-bottom: 40px;
  color: #26262A;
}
.landing-cnts a {
  color: #26262A;
}
@media (min-width: 786px) {
  .landing-cnts {
    padding-bottom: 60px;
  }
}
.landing-cnts .top-v {
  padding: 35px 30px;
  background-color: #FCFBF8;
}
.landing-cnts .top-v .inner {
  /* &:after {
      content: '';
      display: block;
      width: 100%;
      height: 300px;
      margin-top: 40px;
      background: url('../images/landing_topv.png') no-repeat 0 0;
      background-size: contain;
      transition: height 0.5s;
  } */
}
.landing-cnts .top-v .inner strong {
  font-size: 34px;
  transition: font-size 0.5s;
}
.landing-cnts .top-v .inner p {
  margin-top: 16px;
  color: #8C8C8C;
  line-height: 24px;
  font-weight: bold;
}
.landing-cnts .top-v .inner a {
  display: inline-block;
  padding: 12px 16px;
  background: #5539ff;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 24px;
}
@media (min-width: 786px) {
  .landing-cnts .top-v .inner a {
    padding: 16px 20px;
    font-size: 20px;
  }
}
.landing-cnts .top-v .inner .img {
  display: block;
  margin-top: 40px;
}
.landing-cnts .top-v .inner .img img {
  width: 100%;
}
@media (min-width: 768px) {
  .landing-cnts .top-v {
    padding: 85px 30px 70px;
  }
  .landing-cnts .top-v .inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1060px;
    margin: 0 auto;
    /* &:after {
        width: 370px;
        height: 370px;
        margin-top: 0;
        margin-left: 30px;
    } */
  }
  .landing-cnts .top-v .inner strong {
    font-size: 56px;
  }
  .landing-cnts .top-v .inner p {
    font-size: 24px;
    line-height: 36px;
  }
  .landing-cnts .top-v .inner .img {
    width: 43%;
    max-width: 480px;
    margin-top: 0;
    margin-left: 30px;
  }
}
@media (min-width: 1000px) {
  .landing-cnts .top-v .inner:after {
    width: 480px;
    height: 480px;
  }
}
.landing-cnts .corptop-v {
  padding: 36px 32px 0;
}
.landing-cnts .corptop-v strong {
  font-size: 34px;
}
.landing-cnts .corptop-v p {
  margin-top: 20px;
  color: #8C8C8C;
  font-weight: 700;
  line-height: 24px;
}
.landing-cnts .corptop-v > a {
  display: inline-block;
  padding: 12px 16px;
  background: #5539ff;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 24px;
}
@media (min-width: 786px) {
  .landing-cnts .corptop-v > a {
    padding: 16px 20px;
    font-size: 20px;
  }
}
.landing-cnts .corptop-v:after {
  content: "";
  display: block;
  width: 300px;
  height: 300px;
  margin: 39px auto;
  background: url("../images/landingcorp01_m.png") no-repeat 0 0;
  background-size: contain;
}
@media (min-width: 786px) {
  .landing-cnts .corptop-v {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    padding-top: 100px;
    background: url("../images/landingcorp01_pc.png") no-repeat 50% 0;
    background-size: 1440px auto;
    box-sizing: border-box;
    text-align: center;
  }
  .landing-cnts .corptop-v strong {
    font-size: 56px;
    line-height: 72px;
  }
  .landing-cnts .corptop-v p {
    margin-top: 28px;
    font-size: 24px;
    line-height: 36px;
  }
  .landing-cnts .corptop-v a {
    margin-top: 40px;
  }
  .landing-cnts .corptop-v:after {
    display: none;
  }
}
.landing-cnts .pjt-data {
  padding: 48px 30px 0;
}
.landing-cnts .pjt-data > p {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}
.landing-cnts .pjt-data > p br {
  display: none;
}
@media (min-width: 786px) {
  .landing-cnts .pjt-data > p {
    font-size: 36px;
    text-align: center;
    line-height: 56px;
  }
  .landing-cnts .pjt-data > p br {
    display: inline;
  }
}
@media (min-width: 1000px) {
  .landing-cnts .pjt-data > p {
    font-size: 40px;
  }
}
.landing-cnts .pjt-data .n-job {
  display: flex;
  align-items: center;
  margin-top: 24px;
  color: #8C8C8C;
  font-size: 14px;
  font-weight: 700;
}
.landing-cnts .pjt-data .n-job span {
  margin-right: 8px;
  padding: 12px;
  background: #F2F4F6;
  border-radius: 24px;
}
.landing-cnts .pjt-data ul {
  display: flex;
  flex-wrap: wrap;
}
.landing-cnts .pjt-data ul li {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 44px;
}
.landing-cnts .pjt-data ul li span {
  color: #8C8C8C;
  font-size: 14px;
}
.landing-cnts .pjt-data ul li em {
  margin-top: 4px;
  font-size: 40px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  color: #0C87DF;
  white-space: nowrap;
}
.landing-cnts .pjt-data ul li.ctype2 em {
  color: #699704;
}
.landing-cnts .pjt-data ul li.ctype3 em {
  color: #F65243;
}
.landing-cnts .pjt-data ul li.ctype4 em {
  color: #474ABE;
}
.landing-cnts .pjt-data:after {
  content: "";
  display: block;
  width: 280px;
  height: 308px;
  margin: 50px auto 0;
  background: url("../images/landingmem0101_m.png") no-repeat 0 0;
  background-size: contain;
  transition: width 0.5s, max-width 0.5s, height 0.5s;
}
.landing-cnts .pjt-data.corp:after {
  display: none;
}
@media (min-width: 560px) {
  .landing-cnts .pjt-data:after {
    width: 100%;
    max-width: 1000px;
    height: 150px;
    margin: 50px auto 0;
    background-image: url("../images/landingmem0101_pc.png");
  }
  .landing-cnts .pjt-data ul li {
    width: 25%;
  }
}
@media (min-width: 786px) {
  .landing-cnts .pjt-data {
    padding-top: 80px;
  }
  .landing-cnts .pjt-data .n-job {
    justify-content: center;
    font-size: 20px;
  }
  .landing-cnts .pjt-data .n-job span {
    margin-right: 12px;
    padding: 12px 16px;
  }
  .landing-cnts .pjt-data ul {
    max-width: 1020px;
    margin: 0 auto;
  }
  .landing-cnts .pjt-data ul li {
    margin-top: 70px;
  }
  .landing-cnts .pjt-data ul li span {
    font-size: 18px;
  }
  .landing-cnts .pjt-data ul li em {
    margin-top: 8px;
    font-size: 64px;
  }
  .landing-cnts .pjt-data:after {
    height: 212px;
    margin-top: 100px;
  }
}
.landing-cnts .mem-rev {
  max-width: 1080px;
  margin: 0 auto;
  padding: 20px 20px 0;
}
.landing-cnts .mem-rev ul li {
  overflow: hidden;
  margin-top: 40px;
  border: 1px solid #000;
  border-radius: 16px;
  background: #FCFBF8;
}
.landing-cnts .mem-rev ul li > div {
  padding: 28px 28px 40px;
}
.landing-cnts .mem-rev ul li > div strong {
  font-size: 26px;
  line-height: 34px;
}
.landing-cnts .mem-rev ul li > div p {
  margin-top: 24px;
  color: #8C8C8C;
  font-weight: 700;
  line-height: 26px;
}
.landing-cnts .mem-rev ul li:after {
  content: "";
  display: block;
  height: 262px;
  background: #FFEBEB url("../images/landingmem0201.png") no-repeat 50% 50%;
  background-size: auto 172px;
}
.landing-cnts .mem-rev ul li.rev2:after {
  background-color: #E8E0FE;
  background-image: url("../images/landingmem0202.png");
}
.landing-cnts .mem-rev ul li.rev3:after {
  background-color: #FFF5C8;
  background-image: url("../images/landingmem0203.png");
}
.landing-cnts .mem-rev ul li.rev4:after {
  background-color: #DAEEDC;
  background-image: url("../images/landingmem0204.png");
}
@media (min-width: 786px) {
  .landing-cnts .mem-rev ul li {
    display: flex;
    margin-top: 80px;
  }
  .landing-cnts .mem-rev ul li > div {
    width: 50%;
    padding: 70px 50px 0 70px;
    box-sizing: border-box;
  }
  .landing-cnts .mem-rev ul li > div strong {
    font-size: 38px;
    line-height: 56px;
  }
  .landing-cnts .mem-rev ul li > div p {
    max-width: 360px;
    margin-top: 32px;
    font-size: 20px;
    line-height: 32px;
  }
  .landing-cnts .mem-rev ul li:after {
    width: 50%;
    height: 580px;
    background-size: auto 240px;
  }
  .landing-cnts .mem-rev ul li:nth-child(2n+1) {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1000px) {
  .landing-cnts .mem-rev ul li > div {
    padding: 70px 60px 0 70px;
  }
  .landing-cnts .mem-rev ul li > div strong {
    font-size: 48px;
    line-height: 64px;
  }
  .landing-cnts .mem-rev ul li:after {
    background-size: auto 280px;
  }
  .landing-cnts .mem-rev ul li.rev3 > div strong {
    font-size: 40px;
    line-height: 56px;
  }
}
.landing-cnts .recu-info {
  padding: 0 30px;
}
.landing-cnts .recu-info ul li {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}
.landing-cnts .recu-info ul li:before {
  content: "";
  width: 100%;
  height: 285px;
  background: url("../images/landingcorp0201.png") no-repeat 50% 50%;
  background-size: cover;
  border: 1px solid #000;
  border-radius: 16px;
  box-sizing: border-box;
}
.landing-cnts .recu-info ul li > div {
  padding: 32px 10px 0;
}
.landing-cnts .recu-info ul li > div strong {
  font-size: 26px;
}
.landing-cnts .recu-info ul li > div strong br {
  display: none;
}
.landing-cnts .recu-info ul li > div p {
  margin-top: 24px;
  color: #8C8C8C;
  font-weight: 700;
  line-height: 26px;
}
.landing-cnts .recu-info ul li > div p br {
  display: none;
}
.landing-cnts .recu-info ul li > div p.desc {
  margin-top: 30px;
  font-size: 14px;
}
.landing-cnts .recu-info ul li > div p.desc:before {
  content: "*";
}
.landing-cnts .recu-info ul li.rec2:before {
  background-image: url("../images/landingcorp0203.png");
}
.landing-cnts .recu-info ul li.rec3:before {
  background-image: url("../images/landingcorp0204.png");
}
.landing-cnts .recu-info ul li.rec4:before {
  background-image: url("../images/landingcorp0202.png");
}
@media (min-width: 786px) {
  .landing-cnts .recu-info {
    max-width: 1100px;
    margin: 0 auto;
  }
  .landing-cnts .recu-info ul li {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 180px;
  }
  .landing-cnts .recu-info ul li:before {
    width: 45%;
    max-width: 484px;
    height: 460px;
  }
  .landing-cnts .recu-info ul li > div {
    width: 55%;
    padding-left: 130px;
    box-sizing: border-box;
  }
  .landing-cnts .recu-info ul li > div strong {
    font-size: 48px;
    line-height: 64px;
  }
  .landing-cnts .recu-info ul li > div strong br {
    display: inline;
  }
  .landing-cnts .recu-info ul li > div p {
    width: auto;
    max-width: 380px;
    margin-top: 32px;
    font-size: 20px;
    line-height: 32px;
  }
  .landing-cnts .recu-info ul li > div p br {
    display: inline;
  }
  .landing-cnts .recu-info ul li > div p.desc {
    font-size: 16px;
  }
  .landing-cnts .recu-info ul li:nth-child(2n) {
    flex-direction: row-reverse;
  }
  .landing-cnts .recu-info ul li:nth-child(2n) > div {
    padding-left: 0;
    padding-right: 130px;
  }
}
.landing-cnts .process-txt {
  margin-top: 95px;
  padding: 0 20px;
}
.landing-cnts .process-txt .inbox {
  max-width: 1080px;
  margin: 0 auto;
  padding: 32px;
  background: #FCFBF8;
  border: 1px solid #000;
  border-radius: 16px;
  box-sizing: border-box;
}
.landing-cnts .process-txt h3 {
  font-size: 26px;
  line-height: 34px;
}
.landing-cnts .process-txt ol {
  padding-top: 8px;
}
.landing-cnts .process-txt ol li {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.landing-cnts .process-txt ol li span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: #323131;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}
.landing-cnts .process-txt ol li strong {
  margin-top: 16px;
  font-size: 20px;
}
.landing-cnts .process-txt ol li p {
  margin-top: 8px;
  color: #8C8C8C;
  font-weight: 600;
  font-size: 16px;
}
@media (min-width: 786px) {
  .landing-cnts .process-txt {
    margin-top: 120px;
  }
  .landing-cnts .process-txt .inbox {
    display: flex;
    padding: 88px 72px;
  }
  .landing-cnts .process-txt .inbox h3 {
    width: 50%;
    font-size: 48px;
    line-height: 60px;
  }
  .landing-cnts .process-txt .inbox ol {
    width: 50%;
  }
  .landing-cnts .process-txt .inbox ol li {
    margin-top: 48px;
  }
  .landing-cnts .process-txt .inbox ol li span {
    width: 48px;
    height: 48px;
    font-size: 24px;
  }
  .landing-cnts .process-txt .inbox ol li strong {
    font-size: 24px;
  }
  .landing-cnts .process-txt .inbox ol li p {
    font-size: 20px;
    line-height: 32px;
  }
  .landing-cnts .process-txt .inbox ol li:first-child {
    margin-top: 0;
  }
}
.landing-cnts .mem-rev + .process-txt {
  margin-top: 40px;
}
@media (min-width: 786px) {
  .landing-cnts .mem-rev + .process-txt {
    margin-top: 80px;
  }
}
.landing-cnts .outsourcing-w {
  padding: 100px 20px 0;
}
.landing-cnts .outsourcing-w > p {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}
.landing-cnts .outsourcing-w > p br {
  display: none;
}
@media (min-width: 786px) {
  .landing-cnts .outsourcing-w > p {
    font-size: 36px;
    text-align: center;
    line-height: 56px;
  }
  .landing-cnts .outsourcing-w > p br {
    display: inline;
  }
}
@media (min-width: 1000px) {
  .landing-cnts .outsourcing-w > p {
    font-size: 40px;
  }
}
.landing-cnts .outsourcing-w > a {
  display: inline-block;
  padding: 12px 16px;
  background: #5539ff;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 24px;
}
@media (min-width: 786px) {
  .landing-cnts .outsourcing-w > a {
    padding: 16px 20px;
    font-size: 20px;
  }
}
.landing-cnts .outsourcing-w ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
}
.landing-cnts .outsourcing-w ul li {
  width: calc(50% - 5px);
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  min-height: 100%;
  padding: 20px;
  border: 1px solid #E2E3E6;
  border-radius: 16px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
}
.landing-cnts .outsourcing-w ul li a {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.landing-cnts .outsourcing-w ul li span {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 16px;
  margin-bottom: 40px;
}
@media (min-width: 786px) {
  .landing-cnts .outsourcing-w {
    padding-top: 210px;
    text-align: center;
  }
  .landing-cnts .outsourcing-w > a {
    margin-top: 64px;
  }
  .landing-cnts .outsourcing-w ul {
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
  }
  .landing-cnts .outsourcing-w ul li {
    width: calc(33.3% - 6px);
    margin-top: 10px;
    font-size: 20px;
  }
  .landing-cnts .outsourcing-w ul li span {
    margin-bottom: 80px;
  }
}
.landing-cnts .partner-w {
  padding: 80px 35px 0;
}
.landing-cnts .partner-w > p, .landing-cnts .partner-w > h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}
.landing-cnts .partner-w > p br, .landing-cnts .partner-w > h3 br {
  display: none;
}
@media (min-width: 786px) {
  .landing-cnts .partner-w > p, .landing-cnts .partner-w > h3 {
    font-size: 36px;
    text-align: center;
    line-height: 56px;
  }
  .landing-cnts .partner-w > p br, .landing-cnts .partner-w > h3 br {
    display: inline;
  }
}
@media (min-width: 1000px) {
  .landing-cnts .partner-w > p, .landing-cnts .partner-w > h3 {
    font-size: 40px;
  }
}
.landing-cnts .partner-w > h3 {
  font-size: 26px;
}
.landing-cnts .partner-w ul.bnr-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 28px;
}
.landing-cnts .partner-w ul.bnr-list li img {
  width: 134px;
}
@media (min-width: 786px) {
  .landing-cnts .partner-w {
    padding-top: 160px;
  }
  .landing-cnts .partner-w > h3 {
    font-size: 48px;
  }
  .landing-cnts .partner-w ul.bnr-list {
    justify-content: center;
    max-width: 1232px;
    margin: 56px auto 0;
  }
  .landing-cnts .partner-w ul.bnr-list li {
    padding: 0 10px;
  }
  .landing-cnts .partner-w ul.bnr-list li img {
    width: 180px;
  }
}
.landing-cnts ul.pt-rev {
  padding: 10px 20px 0;
}
.landing-cnts ul.pt-rev li {
  margin-top: 20px;
  padding: 24px;
  border: 1px solid #E2E3E6;
  border-radius: 20px;
}
.landing-cnts ul.pt-rev li em {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}
.landing-cnts ul.pt-rev li em span {
  overflow: hidden;
  min-width: 48px;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 50%;
}
.landing-cnts ul.pt-rev li em span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.landing-cnts ul.pt-rev li p {
  margin-top: 28px;
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
}
.landing-cnts ul.pt-rev li .prof-w {
  display: flex;
  align-items: center;
}
.landing-cnts ul.pt-rev li .prof-w > span {
  overflow: hidden;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 50%;
}
.landing-cnts ul.pt-rev li .prof-w > span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.landing-cnts ul.pt-rev li .prof-w div em {
  font-size: 14px;
}
.landing-cnts ul.pt-rev li .prof-w div span {
  display: block;
  margin-top: 8px;
  font-size: 12px;
}
.landing-cnts ul.pt-rev li .bglogo {
  display: none;
}
.landing-cnts ul.pt-rev.corp p {
  line-height: 24px;
}
@media (min-width: 786px) {
  .landing-cnts ul.pt-rev {
    display: flex;
    justify-content: space-between;
    max-width: 1232px;
    margin: 0 auto;
  }
  .landing-cnts ul.pt-rev li {
    display: flex;
    flex-direction: column;
    width: calc(33.3% - 10px);
    padding: 40px;
    box-sizing: border-box;
  }
  .landing-cnts ul.pt-rev li em {
    font-size: 18px;
  }
  .landing-cnts ul.pt-rev li p {
    font-size: 18px;
    line-height: 32px;
  }
  .landing-cnts ul.pt-rev li .prof-w > span {
    width: 60px;
    height: 60px;
  }
  .landing-cnts ul.pt-rev li .prof-w div em {
    font-size: 18px;
  }
  .landing-cnts ul.pt-rev li .prof-w div span {
    font-size: 16px;
  }
  .landing-cnts ul.pt-rev li .bglogo {
    display: block;
    margin-top: auto;
  }
  .landing-cnts ul.pt-rev li .bglogo img {
    width: 152px;
  }
  .landing-cnts ul.pt-rev.corp li {
    min-height: 520px;
    padding: 34px 34px 24px;
  }
  .landing-cnts ul.pt-rev.corp li p {
    line-height: 32px;
  }
}
.landing-cnts .m-price {
  padding: 75px 20px 0;
}
.landing-cnts .m-price .info {
  padding: 0 12px;
}
.landing-cnts .m-price .info h3 {
  font-size: 26px;
}
.landing-cnts .m-price .info p {
  margin-top: 14px;
  color: #8C8C8C;
  font-weight: 700;
  line-height: 26px;
}
.landing-cnts .m-price .info p br {
  display: none;
}
.landing-cnts .m-price .info > a, .landing-cnts .m-price .info > em {
  display: inline-block;
  padding: 12px 16px;
  background: #5539ff;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 24px;
  background-color: #323131;
}
@media (min-width: 786px) {
  .landing-cnts .m-price .info > a, .landing-cnts .m-price .info > em {
    padding: 16px 20px;
    font-size: 20px;
  }
}
.landing-cnts .m-price .p-box {
  margin-top: 47px;
}
.landing-cnts .m-price .p-box .box-w {
  padding: 16px;
  border: 1px solid #000;
  background: #FCFBF8;
  border-radius: 16px;
}
.landing-cnts .m-price .p-box .box-w h4 {
  font-size: 16px;
}
.landing-cnts .m-price .p-box .box-w > p {
  margin-top: 10px;
  color: #8C8C8C;
  font-size: 12px;
}
.landing-cnts .m-price .p-box .box-w strong {
  display: block;
  margin-top: 18px;
  font-size: 40px;
  line-height: 64px;
}
.landing-cnts .m-price .p-box .box-w .noti {
  display: block;
  margin-top: 10px;
  padding: 16px;
  background: #FFEBEB;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.landing-cnts .m-price .p-box .tit {
  padding: 18px 20px 0;
  font-size: 14px;
  font-weight: 700;
}
.landing-cnts .m-price .p-box ul {
  padding: 0 20px;
  font-size: 12px;
}
.landing-cnts .m-price .p-box ul li {
  position: relative;
  margin-top: 10px;
  padding-left: 20px;
}
.landing-cnts .m-price .p-box ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  width: 3px;
  height: 3px;
  background: #0d1240;
  border-radius: 50%;
}
.landing-cnts .m-price .p-data {
  overflow: auto;
  width: 100vw;
  margin-left: -20px;
  margin-top: 40px;
  padding: 0 20px;
  font-size: 12px;
  box-sizing: border-box;
}
.landing-cnts .m-price .p-data table {
  min-width: 360px;
}
.landing-cnts .m-price .p-data table tr {
  margin-top: 4px;
  text-align: center;
}
.landing-cnts .m-price .p-data table tr th, .landing-cnts .m-price .p-data table tr td {
  vertical-align: middle;
  line-height: 20px;
  border: 2px solid #fff;
  border-radius: 8px;
}
.landing-cnts .m-price .p-data table tr th > span, .landing-cnts .m-price .p-data table tr td > span {
  display: block;
  font-size: 10px;
  font-weight: normal;
}
.landing-cnts .m-price .p-data table tr th {
  padding: 16px 0;
  background: #eee;
  font-weight: 700;
}
.landing-cnts .m-price .p-data table tr td {
  width: calc(50% - 2px);
  padding: 16px 0;
  background: #F8F8F8;
  border-radius: 4px;
  font-weight: 700;
}
.landing-cnts .m-price .p-data table tr td .tok {
  display: block;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: url("../images/icon_chk_p.svg") no-repeat 0 0;
  background-size: cover;
  font-size: 1px;
  text-align: left;
  text-indent: -9999px;
}
.landing-cnts .m-price .p-data table thead tr th:first-child {
  background: none;
}
@media (min-width: 786px) {
  .landing-cnts .m-price {
    display: flex;
    justify-content: space-between;
    max-width: 1060px;
    margin: 0 auto;
    padding-top: 190px;
  }
  .landing-cnts .m-price .info {
    padding: 39px 20px 0 0;
  }
  .landing-cnts .m-price .info h3 {
    font-size: 48px;
  }
  .landing-cnts .m-price .info p {
    margin-top: 26px;
    font-size: 20px;
    line-height: 32px;
  }
  .landing-cnts .m-price .info p br {
    display: inline;
  }
  .landing-cnts .m-price .info a {
    margin-top: 40px;
  }
  .landing-cnts .m-price .p-box {
    width: 50%;
    max-width: 400px;
    margin-top: 0;
    margin-left: 30px;
  }
  .landing-cnts .m-price .p-box .box-w {
    padding-top: 25px;
  }
  .landing-cnts .m-price .p-box .box-w h4 {
    padding-left: 24px;
    font-size: 18px;
  }
  .landing-cnts .m-price .p-box .box-w > p {
    margin: 10px 0 0 24px;
    font-size: 16px;
  }
  .landing-cnts .m-price .p-box .box-w strong {
    margin: 54px 0 0 24px;
    font-size: 64px;
  }
  .landing-cnts .m-price .p-box .box-w .noti {
    margin-top: 29px;
    font-size: 16px;
  }
  .landing-cnts .m-price .p-box .tit {
    padding: 24px 40px 0;
    font-size: 18px;
  }
  .landing-cnts .m-price .p-box ul {
    padding: 10px 40px 0;
    font-size: 16px;
  }
  .landing-cnts .m-price .p-data {
    width: 55%;
    max-width: 580px;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
  }
  .landing-cnts .m-price .p-data table tr th {
    width: calc(45% - 2px);
    padding: 21px 0 20px;
  }
  .landing-cnts .m-price .p-data table tr td {
    width: calc(55% - 2px);
    padding: 21px 0 20px;
  }
  .landing-cnts .m-price .p-data table tr th span, .landing-cnts .m-price .p-data table tr td span {
    font-size: 12px;
  }
  .landing-cnts .m-price.corp .info {
    padding: 0;
  }
}
.landing-cnts .join-info {
  padding: 60px 20px 0;
}
.landing-cnts .join-info .inner {
  padding: 28px 28px 40px;
  background: #FFF28A;
  border-radius: 16px;
}
.landing-cnts .join-info .inner .txt strong {
  font-size: 24px;
}
.landing-cnts .join-info .inner .txt p {
  margin-top: 24px;
  font-weight: 700;
  line-height: 26px;
}
.landing-cnts .join-info .inner .txt > a {
  display: inline-block;
  padding: 12px 16px;
  background: #5539ff;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 24px;
}
@media (min-width: 786px) {
  .landing-cnts .join-info .inner .txt > a {
    padding: 16px 20px;
    font-size: 20px;
  }
}
.landing-cnts .join-info .inner:after {
  content: "";
  display: block;
  width: 184px;
  height: 220px;
  margin: 39px auto 0;
  background: url("../images/landingmem03.png") no-repeat 0 0;
  background-size: contain;
}
@media (min-width: 786px) {
  .landing-cnts .join-info .inner {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 90px 100px;
    box-sizing: border-box;
  }
  .landing-cnts .join-info .inner .txt strong {
    font-size: 40px;
  }
  .landing-cnts .join-info .inner .txt p {
    margin-top: 36px;
    font-size: 20px;
  }
  .landing-cnts .join-info .inner .txt > a {
    margin-top: 40px;
  }
  .landing-cnts .join-info .inner:after {
    width: 258px;
    height: 306px;
    margin: 0 30px 0 0;
  }
}

.cust-cnts {
  padding: 80px 20px 0;
}
.cust-cnts .txt-box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 32px 28px 40px;
  background: #263238;
  border-radius: 16px;
}
.cust-cnts .txt-box h3 {
  margin-top: 14px;
  color: #fff;
  font-size: 24px;
}
.cust-cnts .txt-box p {
  margin-top: 20px;
  margin-bottom: 50px;
  color: #A9A9B7;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.cust-cnts .txt-box > a {
  display: inline-block;
  padding: 12px 16px;
  background: #5539ff;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  margin-top: auto;
  margin-right: auto;
}
@media (min-width: 786px) {
  .cust-cnts .txt-box > a {
    padding: 16px 20px;
    font-size: 20px;
  }
}
.cust-cnts .txt-box:before {
  content: "";
  display: block;
  width: 28px;
  height: 44px;
  background: url("../images/custimg01.png") no-repeat 0 0;
  background-size: cover;
}
.cust-cnts .txt-box.faq:before {
  width: 48px;
  height: 40px;
  background-image: url("../images/custimg02.png");
}
.cust-cnts .txt-box.faq > a {
  background: #F8C858;
  color: #0d1240;
}
@media (min-width: 786px) {
  .cust-cnts {
    display: flex;
    justify-content: space-between;
    max-width: 1080px;
    margin: 230px auto 0;
    padding: 0;
    background: #263238;
    border-radius: 16px;
  }
  .cust-cnts .txt-box {
    width: 50%;
    margin-top: 0;
    padding: 62px 80px 90px;
    background: none;
    border-radius: 0;
  }
  .cust-cnts .txt-box:before {
    width: 40px;
    height: 63px;
  }
  .cust-cnts .txt-box h3 {
    margin-top: 20px;
    font-size: 40px;
  }
  .cust-cnts .txt-box p {
    margin-top: 28px;
    font-size: 20px;
    line-height: 32px;
  }
  .cust-cnts .txt-box.faq:before {
    width: 62px;
    height: 53px;
  }
}

.lading-before .top-v {
  padding: 120px 20px 50px;
  background: #5539ff;
  color: #fff;
}
.lading-before .top-v .inner strong {
  font-size: 28px;
}
.lading-before .top-v .inner p {
  max-width: 340px;
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
}
.lading-before .top-v .inner p br {
  display: none;
}
.lading-before .top-v .inner a {
  display: block;
  padding: 13px 0;
  background: #fff;
  color: #5539ff;
  text-align: center;
  margin-top: 40px;
}
@media (min-width: 786px) {
  .lading-before .top-v .inner a {
    padding: 18px 0;
    font-size: 16px;
  }
}
@media (min-width: 786px) {
  .lading-before .top-v {
    padding-top: 185px;
    padding-bottom: 285px;
  }
  .lading-before .top-v .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1035px;
    margin: 0 auto;
  }
  .lading-before .top-v .inner strong {
    font-size: 32px;
  }
  .lading-before .top-v .inner p {
    font-size: 18px;
    line-height: 32px;
  }
  .lading-before .top-v .inner a {
    width: 360px;
  }
  .lading-before .top-v .inner:after {
    content: "";
    width: 534px;
    height: 523px;
    background: url("../images/landingimg01.png") no-repeat 50% 50%;
    background-size: contain;
  }
}
.lading-before .sect-cnts {
  position: relative;
  padding: 0 20px;
}
.lading-before .sect-cnts ul li {
  margin-top: 30px;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 1px 2px 34px -2px rgba(131, 131, 131, 0.15);
  border-radius: 20px;
}
.lading-before .sect-cnts ul li > div strong {
  font-size: 26px;
}
.lading-before .sect-cnts ul li > div strong span {
  display: block;
  margin-bottom: 10px;
  color: rgba(13, 18, 64, 0.4);
  font-weight: normal;
  font-size: 16px;
}
.lading-before .sect-cnts ul li > div p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
}
@media (min-width: 786px) {
  .lading-before .sect-cnts {
    margin-top: -180px;
    background: url("../images/landingbg.png") no-repeat 50% 50%;
  }
  .lading-before .sect-cnts ul {
    max-width: 1040px;
    margin: 0 auto;
  }
  .lading-before .sect-cnts ul li {
    display: flex;
    align-items: center;
    margin-top: 60px;
    padding: 60px 65px;
  }
  .lading-before .sect-cnts ul li:before {
    content: "";
    width: 60%;
    max-width: 460px;
    height: 438px;
    background: url("../images/landingimg0201.png") no-repeat 50% 0;
    background-size: contain;
  }
  .lading-before .sect-cnts ul li > div {
    width: 40%;
    padding-left: 48px;
  }
  .lading-before .sect-cnts ul li > div strong {
    font-size: 32px;
    line-height: 48px;
  }
  .lading-before .sect-cnts ul li > div strong span {
    margin-bottom: 0;
  }
  .lading-before .sect-cnts ul li > div p {
    line-height: 32px;
  }
  .lading-before .sect-cnts ul li:first-child {
    margin-top: 0;
  }
  .lading-before .sect-cnts ul li:first-child:before {
    height: 395px;
  }
  .lading-before .sect-cnts ul li:nth-child(2):before {
    background-image: url("../images/landingimg0202.png");
  }
  .lading-before .sect-cnts ul li:nth-child(3):before {
    background-image: url("../images/landingimg0203.png");
  }
}
.lading-before .bgbox {
  margin-top: 80px;
  padding: 0 20px;
  background: #0d1240;
  box-shadow: 0px 2px 12px 3px rgba(0, 0, 0, 0.15);
}
.lading-before .bgbox h3 {
  color: #fff;
  font-size: 26px;
  font-weight: normal;
}
.lading-before .bgbox h3 em {
  display: block;
  font-weight: bold;
}
.lading-before .bgbox > div {
  padding-top: 60px;
}
.lading-before .bgbox > div.solution-w h3 {
  text-align: center;
}
.lading-before .bgbox > div.solution-w ul li {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 30px 20px;
  background: #fff;
  border-radius: 15px;
  box-sizing: border-box;
}
.lading-before .bgbox > div.solution-w ul li:before {
  content: "";
  min-width: 100px;
  height: 100px;
  margin-right: 20px;
  background: url("../images/landingimg0301.png") no-repeat 50% 50%;
  background-size: contain;
}
.lading-before .bgbox > div.solution-w ul li > div {
  font-size: 16px;
}
.lading-before .bgbox > div.solution-w ul li > div p {
  margin-top: 5px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  line-height: 24px;
}
.lading-before .bgbox > div.solution-w ul li.sol2:before {
  background-image: url("../images/landingimg0302.png");
}
.lading-before .bgbox > div.solution-w ul li.sol3:before {
  background-image: url("../images/landingimg0303.png");
}
.lading-before .bgbox > div.solution-w ul li.sol4:before {
  background-image: url("../images/landingimg0304.png");
}
.lading-before .bgbox > div.jobtype-data {
  display: flex;
  padding-bottom: 60px;
}
.lading-before .bgbox > div.jobtype-data .graph {
  min-width: 100px;
  margin-right: 30px;
}
.lading-before .bgbox > div.jobtype-data .graph img {
  width: 100%;
}
.lading-before .bgbox > div.jobtype-data .cnts ul {
  padding-top: 10px;
  color: #fff;
  font-size: 16px;
}
.lading-before .bgbox > div.jobtype-data .cnts ul li {
  display: flex;
  margin-top: 10px;
}
.lading-before .bgbox > div.jobtype-data .cnts ul li em {
  margin: 0 5px;
  font-weight: bold;
}
.lading-before .bgbox > div.jobtype-data .cnts ul li:before {
  content: "";
  width: 8px;
  height: 8px;
  margin: 5px 8px 0 0;
  background: #5539ff;
  border-radius: 50%;
}
.lading-before .bgbox > div.jobtype-data .cnts ul li.designer:before {
  background: #96FFC6;
}
.lading-before .bgbox > div.jobtype-data .cnts ul li.planner:before {
  background: #E0FE52;
}
.lading-before .bgbox > div.jobtype-data .cnts ul li.etc:before {
  background: #FFB494;
}
@media (min-width: 786px) {
  .lading-before .bgbox {
    margin-top: -260px;
    padding-top: 320px;
  }
  .lading-before .bgbox h3 {
    font-size: 32px;
  }
  .lading-before .bgbox h3 em {
    display: inline;
  }
  .lading-before .bgbox > div.solution-w {
    max-width: 1242px;
    margin: 0 auto;
  }
  .lading-before .bgbox > div.solution-w ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .lading-before .bgbox > div.solution-w ul li {
    width: calc(50% - 13px);
  }
  .lading-before .bgbox > div.jobtype-data {
    max-width: 1017px;
    padding-top: 148px;
    padding-bottom: 125px;
    margin: 0 auto;
    background: url("../images/graphimg.png") no-repeat 100% 100%;
  }
  .lading-before .bgbox > div.jobtype-data .graph {
    width: 290px;
    height: 290px;
    margin-right: 60px;
  }
  .lading-before .bgbox > div.jobtype-data .cnts {
    margin-top: 10px;
  }
  .lading-before .bgbox > div.jobtype-data .cnts ul {
    font-size: 20px;
  }
  .lading-before .bgbox > div.jobtype-data .cnts ul li {
    margin-top: 16px;
  }
  .lading-before .bgbox > div.jobtype-data .cnts ul li:before {
    margin-top: 8px;
  }
}
@media (min-width: 786px) and (min-width: 1000px) {
  .lading-before .bgbox > div.solution-w ul li {
    flex-direction: column;
    width: calc(25% - 22px);
    padding: 30px;
  }
  .lading-before .bgbox > div.solution-w ul li:before {
    width: 172px;
    height: 174px;
  }
  .lading-before .bgbox > div.solution-w ul li > div {
    margin-top: 19px;
  }
}
.lading-before .pricing-w {
  margin-top: 20px;
  padding: 45px 0;
  box-shadow: 0px 2px 12px 3px rgba(0, 0, 0, 0.15);
}
.lading-before .pricing-w h3 {
  font-size: 28px;
  text-align: center;
}
.lading-before .pricing-w .viewport {
  overflow: hidden;
  overflow-x: auto;
  padding: 20px 20px 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.lading-before .pricing-w .viewport::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.lading-before .pricing-w .viewport table {
  width: 796px;
  margin: 0 auto;
  border-collapse: collapse;
}
.lading-before .pricing-w .viewport table th, .lading-before .pricing-w .viewport table td {
  border: 4px solid #fff;
}
.lading-before .pricing-w .viewport table thead th {
  padding: 20px 0;
  border-radius: 20px 20px 0 0;
  border-top: none;
  border-bottom: none;
}
.lading-before .pricing-w .viewport table thead th.basic {
  background: #5539ff;
  color: #fff;
}
.lading-before .pricing-w .viewport table thead th.memship {
  background: #E0FE52;
  color: #0d1240;
  font-size: 18px;
}
.lading-before .pricing-w .viewport table tbody {
  font-size: 14px;
}
.lading-before .pricing-w .viewport table tbody th, .lading-before .pricing-w .viewport table tbody td {
  height: 61px;
  border-radius: 6px;
}
.lading-before .pricing-w .viewport table tbody th {
  padding-left: 20px;
  background: rgba(13, 18, 64, 0.02);
  text-align: left;
}
.lading-before .pricing-w .viewport table tbody td {
  width: 283px;
  background: rgba(85, 57, 255, 0.03);
  color: #5539ff;
  text-align: center;
}
.lading-before .pricing-w .viewport table tbody td .tok {
  display: block;
  width: 40px;
  height: 31px;
  margin: 0 auto;
  background: url("../images/icon_chk.png") no-repeat 0 50%;
  background-size: contain;
  font-size: 1px;
  text-indent: -9999px;
  text-align: left;
}
.lading-before .pricing-w .viewport table tbody td.memship {
  background: rgba(224, 254, 82, 0.05);
  color: rgba(13, 18, 64, 0.6);
}
.lading-before .pricing-w > p {
  max-width: 796px;
  margin: 20px auto 0;
  padding-right: 20px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 12px;
  text-align: right;
  box-sizing: border-box;
}
.lading-before .join-info {
  padding: 50px 20px;
  background: #5539ff;
  color: #fff;
}
.lading-before .join-info .inner {
  max-width: 1040px;
  margin: 0 auto;
}
.lading-before .join-info .inner .cnts {
  max-width: 440px;
}
.lading-before .join-info .inner .cnts p {
  margin-top: 10px;
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
}
.lading-before .join-info .inner .cnts p br {
  display: none;
}
.lading-before .join-info .inner .cnts a {
  display: block;
  padding: 13px 0;
  background: #fff;
  color: #5539ff;
  text-align: center;
  margin-top: 40px;
}
@media (min-width: 786px) {
  .lading-before .join-info .inner .cnts a {
    padding: 18px 0;
    font-size: 16px;
  }
}
@media (min-width: 786px) {
  .lading-before .join-info .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .lading-before .join-info .inner .cnts {
    padding-right: 30px;
  }
  .lading-before .join-info .inner:after {
    content: "";
    width: 432px;
    height: 425px;
    background: url("../images/how_rec04.png") no-repeat 50% 50%;
    background-size: contain;
  }
}

.landing-cnts-v3 {
  padding-bottom: 40px;
}
.landing-cnts-v3 .top-v {
  max-width: 1073px;
  margin: 0 auto;
  padding: 35px 30px;
}
.landing-cnts-v3 .top-v .inner .cnts {
  display: flex;
  flex-direction: column;
  color: #0d1240;
}
.landing-cnts-v3 .top-v .inner .cnts > span {
  font-size: 18px;
  font-weight: 600;
}
.landing-cnts-v3 .top-v .inner .cnts a {
  display: inline-block;
  padding: 12px 16px;
  background: #5539ff;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 24px;
  margin-right: auto;
  padding-left: 20px;
}
@media (min-width: 786px) {
  .landing-cnts-v3 .top-v .inner .cnts a {
    padding: 16px 20px;
    font-size: 20px;
  }
}
.landing-cnts-v3 .top-v .inner strong {
  margin-top: 10px;
  font-size: 34px;
  transition: font-size 0.5s;
}
.landing-cnts-v3 .top-v .inner p {
  margin-top: 16px;
  color: #8C8C8C;
  line-height: 24px;
  font-weight: bold;
}
.landing-cnts-v3 .top-v .inner .img {
  display: block;
  margin-top: 40px;
}
.landing-cnts-v3 .top-v .inner .img img {
  width: 80%;
}
@media (min-width: 768px) {
  .landing-cnts-v3 .top-v {
    padding: 85px 30px 70px;
  }
  .landing-cnts-v3 .top-v .inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1060px;
    margin: 0 auto;
  }
  .landing-cnts-v3 .top-v .inner .cnts > span {
    font-size: 24px;
  }
  .landing-cnts-v3 .top-v .inner strong {
    margin-top: 20px;
    font-size: 60px;
  }
  .landing-cnts-v3 .top-v .inner p {
    font-size: 24px;
    line-height: 36px;
  }
  .landing-cnts-v3 .top-v .inner .img {
    width: 43%;
    max-width: 500px;
    margin-top: 0;
    margin-left: 30px;
  }
}
.landing-cnts-v3 .partners {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: auto;
  margin-top: 80px;
}
.landing-cnts-v3 .partners::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.landing-cnts-v3 .partners > p {
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 16px;
  color: #8E9496;
  font-size: 20px;
  font-weight: 700;
}
.landing-cnts-v3 .partners > p b {
  color: #0d1240;
}
.landing-cnts-v3 .partners ul {
  display: flex;
}
.landing-cnts-v3 .partners ul li {
  min-width: 120px;
  max-width: 120px;
  margin-top: 30px;
  padding: 0 18px;
}
.landing-cnts-v3 .partners ul li img {
  width: 100%;
}
.landing-cnts-v3 .partners.corp ul {
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .landing-cnts-v3 .partners {
    margin-top: 160px;
  }
  .landing-cnts-v3 .partners > p {
    margin-bottom: 30px;
    font-size: 40px;
  }
  .landing-cnts-v3 .partners ul {
    justify-content: center;
  }
  .landing-cnts-v3 .partners ul li {
    padding: 0 32px;
  }
}
.landing-cnts-v3 .res-update {
  margin-top: 60px;
  padding: 0 16px 60px;
  text-align: center;
}
.landing-cnts-v3 .res-update h3 {
  color: #5539ff;
  font-size: 24px;
  font-weight: 700;
}
.landing-cnts-v3 .res-update > p {
  margin-top: 18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}
.landing-cnts-v3 .res-update > p em {
  color: #5539ff;
}
.landing-cnts-v3 .res-update .up-list {
  max-width: 920px;
  padding-top: 20px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}
.landing-cnts-v3 .res-update .up-list ul li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 0;
  padding: 20px;
  background: #fff;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  box-shadow: 0 0 0 4px #EFF8FF;
}
.landing-cnts-v3 .res-update .up-list ul li .logo {
  overflow: hidden;
  min-width: 36px;
  width: 48px;
  height: 48px;
  border-radius: 3px;
}
.landing-cnts-v3 .res-update .up-list ul li .logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.landing-cnts-v3 .res-update .up-list ul li .info {
  flex: 1;
  padding: 0 16px;
}
.landing-cnts-v3 .res-update .up-list ul li .info > span {
  color: #475467;
}
.landing-cnts-v3 .res-update .up-list ul li .result {
  display: flex;
  align-items: center;
  min-width: calc(100% - 20px);
  margin-top: 10px;
  margin-left: auto;
  padding: 10px;
  border-radius: 16px;
  background: #EFF8FF;
  color: #175CD3;
  font-size: 14px;
}
.landing-cnts-v3 .res-update .up-list ul li .result:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  margin-right: 6px;
  border-radius: 50%;
  background: #175CD3;
}
.landing-cnts-v3 .res-update .up-list ul li.propose {
  box-shadow: 0 0 0 4px #F2EDFF;
}
.landing-cnts-v3 .res-update .up-list ul li.propose .result {
  background: #F2EDFF;
  color: #7C45F7;
}
.landing-cnts-v3 .res-update .up-list ul li.propose .result:before {
  background: #7C45F7;
}
.landing-cnts-v3 .res-update .up-list ul li.employ {
  box-shadow: 0 0 0 4px #ECFDF3;
}
.landing-cnts-v3 .res-update .up-list ul li.employ .result {
  background: #ECFDF3;
  color: #027948;
}
.landing-cnts-v3 .res-update .up-list ul li.employ .result:before {
  background: #027948;
}
@media (min-width: 768px) {
  .landing-cnts-v3 .res-update {
    margin-top: 120px;
    padding-bottom: 120px;
    background: url("../images/update_bg.png") no-repeat 50% 0;
  }
  .landing-cnts-v3 .res-update > p {
    margin-top: 24px;
    font-size: 36px;
    line-height: 52px;
  }
  .landing-cnts-v3 .res-update .up-list {
    padding-top: 72px;
  }
  .landing-cnts-v3 .res-update .up-list ul li {
    padding: 20px 32px;
  }
  .landing-cnts-v3 .res-update .up-list ul li .logo {
    min-width: 72px;
    width: 72px;
    height: 72px;
  }
  .landing-cnts-v3 .res-update .up-list ul li .info {
    padding: 0 32px;
  }
  .landing-cnts-v3 .res-update .up-list ul li .result {
    min-width: auto;
    padding: 20px;
    font-size: 16px;
  }
}
.landing-cnts-v3 .fav-pjt {
  max-width: 1012px;
  margin: 0 auto;
  padding: 0 20px 100px;
  text-align: center;
}
.landing-cnts-v3 .fav-pjt h3 {
  color: #5539ff;
  font-size: 24px;
  font-weight: 700;
}
.landing-cnts-v3 .fav-pjt > p {
  margin-top: 18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}
.landing-cnts-v3 .fav-pjt > p em {
  color: #5539ff;
}
.landing-cnts-v3 .fav-pjt > p.desc {
  margin-top: 0;
  color: #8E9496;
  font-size: 16px;
}
.landing-cnts-v3 .fav-pjt .ms-list {
  column-width: 324px;
  gap: 20px;
  padding-top: 60px;
  text-align: left;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox {
  margin-bottom: 20px;
  padding: 32px;
  border: 1px solid #D6D6DA;
  border-radius: 20px;
  break-inside: avoid;
  box-sizing: border-box;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox > a {
  display: block;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .recomd {
  display: block;
  position: relative;
  margin-bottom: 20px;
  padding: 16px 13px 16px 26px;
  background: #EFF8FF;
  border-radius: 12px;
  color: #175CD3;
  font-size: 14px;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .recomd span {
  text-decoration: underline;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .recomd:before {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  left: 13px;
  width: 6px;
  height: 6px;
  margin-top: 5px;
  margin-right: 7px;
  background: #175CD3;
  border-radius: 50%;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .cnts {
  margin-top: 24px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .cnts ul {
  margin-top: 30px;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .cnts ul li {
  padding-left: 12px;
  text-indent: -12px;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .cnts ul li:before {
  content: "•";
  margin-right: 5px;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .cnts p {
  margin-top: 30px;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .cnts p:first-child {
  margin-top: 0;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox h4 {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .pjt {
  display: flex;
  flex-direction: column;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .pjt em {
  color: #B4B4B9;
  font-size: 11px;
  font-weight: bold;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .pjt strong {
  margin-top: 8px;
  font-size: 15px;
  line-height: 20px;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .hash-pj {
  display: flex;
  flex-wrap: wrap;
  padding-top: 28px;
  height: 74px;
  overflow: hidden;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .hash-pj span {
  margin-top: 6px;
  margin-right: 6px;
  padding: 8px 12px;
  background: #FFEFEB;
  border-radius: 6px;
  color: #CC0905;
  font-weight: 600;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .hash-pj span.ml {
  background: #EBF7FF;
  color: #0452C8;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .hash-pj span.node {
  background: #EBFFF1;
  color: #119C2B;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .hash-pj span.human {
  background: #E4FCFC;
  color: #0D9676;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .hash-pj span.ux {
  background: #FFFCC2;
  color: #835101;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox .hash-pj span.aie {
  background: #FCF0FF;
  color: #7E10E5;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox.c1 .cnts {
  color: #F74;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox.c2 .cnts {
  color: #09f;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox.c3 .cnts {
  color: #504BFF;
}
.landing-cnts-v3 .fav-pjt .ms-list .inbox.c4 .cnts {
  color: #2A9;
}
.landing-cnts-v3 .fav-pjt .btn-more {
  margin-top: 30px;
}
.landing-cnts-v3 .fav-pjt .btn-more button {
  padding: 15px 20px 14px;
  background: #FFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #344054;
  font-size: 16px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .landing-cnts-v3 .fav-pjt > p {
    margin-top: 24px;
    font-size: 36px;
    line-height: 52px;
  }
  .landing-cnts-v3 .fav-pjt > p.desc {
    margin-top: 12px;
    font-size: 24px;
  }
  .landing-cnts-v3 .fav-pjt .btn-more {
    margin-top: 60px;
  }
}
.landing-cnts-v3 .pjt-input {
  padding: 50px 16px;
  background: #FCFCFD;
}
.landing-cnts-v3 .pjt-input h3 {
  font-size: 22px;
  text-align: center;
}
.landing-cnts-v3 .pjt-input .btn {
  display: flex;
  justify-content: center;
}
.landing-cnts-v3 .pjt-input .btn a, .landing-cnts-v3 .pjt-input .btn button {
  width: auto;
  height: 55px;
  margin: 0 -2px;
  padding: 0 12px;
  background: #fff;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
}
.landing-cnts-v3 .pjt-input .mem-rev {
  max-width: 1112px;
  margin: 0 auto;
}
.landing-cnts-v3 .pjt-input .mem-rev ul li {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 20px;
}
.landing-cnts-v3 .pjt-input .mem-rev ul li div {
  flex: 1;
  padding: 32px 32px 40px;
  background: linear-gradient(180deg, #00B900 0%, rgba(0, 185, 0, 0.8) 50%, rgba(0, 185, 0, 0.5) 100%);
  color: #fff;
  font-weight: 700;
}
.landing-cnts-v3 .pjt-input .mem-rev ul li div em {
  font-size: 20px;
}
.landing-cnts-v3 .pjt-input .mem-rev ul li div em:before {
  content: "";
  display: block;
  width: 20px;
  height: 15px;
  margin-bottom: 20px;
  background: url("../images/icon_comma.svg") no-repeat 0 0;
}
.landing-cnts-v3 .pjt-input .mem-rev ul li div p {
  margin-top: 20px;
  font-size: 24px;
}
.landing-cnts-v3 .pjt-input .mem-rev ul li .img {
  display: block;
  width: 100%;
  height: 160px;
  margin-top: auto;
}
.landing-cnts-v3 .pjt-input .mem-rev ul li .img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.landing-cnts-v3 .pjt-input .mem-rev ul li.c2 div {
  background: linear-gradient(180deg, #FFD200 0%, rgba(255, 210, 0, 0.9) 48.96%, rgba(255, 210, 0, 0.5) 100%, rgba(255, 210, 0, 0.5) 100%, rgba(255, 210, 0, 0.8) 100%);
}
.landing-cnts-v3 .pjt-input .mem-rev ul li.c3 div {
  background: linear-gradient(180deg, #2AC1BC 0%, rgba(42, 193, 188, 0.8) 49.48%, rgba(42, 193, 188, 0.5) 100%);
}
.landing-cnts-v3 .pjt-input.corp {
  max-width: 1112px;
  margin: 0 auto;
  background: #fff;
}
.landing-cnts-v3 .pjt-input.corp > p {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: bold;
}
.landing-cnts-v3 .pjt-input.corp > p:before {
  content: "💬";
  display: block;
}
@media (min-width: 768px) {
  .landing-cnts-v3 .pjt-input {
    padding: 100px 16px;
  }
  .landing-cnts-v3 .pjt-input h3 {
    font-size: 22px;
  }
  .landing-cnts-v3 .pjt-input .btn a, .landing-cnts-v3 .pjt-input .btn button {
    padding: 0 24px;
    font-size: 20px;
  }
  .landing-cnts-v3 .pjt-input .mem-rev {
    margin-top: 65px;
  }
  .landing-cnts-v3 .pjt-input .mem-rev ul {
    display: flex;
  }
  .landing-cnts-v3 .pjt-input .mem-rev ul li {
    width: 33.3%;
    margin: 0 8px;
  }
  .landing-cnts-v3 .pjt-input.corp {
    padding-top: 160px;
  }
  .landing-cnts-v3 .pjt-input.corp > p {
    margin-bottom: 60px;
    padding: 0 40px;
    font-size: 44px;
  }
}
.landing-cnts-v3 .service {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1080px;
  margin: 30px 16px 0;
  padding: 30px;
  background: linear-gradient(0deg, #262838, #262838), linear-gradient(0deg, #263238, #263238), linear-gradient(0deg, #FFF28A, #FFF28A), #F6F6F8;
  border-radius: 16px;
  box-sizing: border-box;
  font-weight: 700;
}
.landing-cnts-v3 .service > span {
  color: #EAECF0;
  font-size: 20px;
}
.landing-cnts-v3 .service > strong {
  margin-top: 10px;
  color: #EAECF0;
  font-size: 32px;
  line-height: 48px;
}
.landing-cnts-v3 .service p {
  margin-top: 24px;
  color: #A9A9B7;
  font-size: 18px;
  line-height: 32px;
}
.landing-cnts-v3 .service > a, .landing-cnts-v3 .service > button {
  margin-top: 32px;
  margin-right: auto;
  padding: 16px 20px;
  background: #5539FF;
  color: #fff;
  font-size: 20px;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .landing-cnts-v3 .service {
    min-height: 520px;
    margin: 60px auto;
    padding: 70px 20px 70px 460px;
  }
  .landing-cnts-v3 .service:before {
    content: "";
    display: block;
    position: absolute;
    top: 107px;
    left: 102px;
    width: 258px;
    height: 306px;
    background: url("../images/landingmem03.png") no-repeat 0 0;
    background-size: cover;
  }
  .landing-cnts-v3 .service > strong {
    font-size: 36px;
  }
  .landing-cnts-v3 .service p {
    font-size: 20px;
  }
}
.landing-cnts-v3 .corptop-v {
  display: flex;
  flex-direction: column;
  padding: 36px 32px 0;
}
.landing-cnts-v3 .corptop-v > span {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}
.landing-cnts-v3 .corptop-v strong {
  font-size: 34px;
}
.landing-cnts-v3 .corptop-v p {
  margin-top: 20px;
  color: #8C8C8C;
  font-weight: 700;
  line-height: 24px;
}
.landing-cnts-v3 .corptop-v > a {
  display: inline-block;
  padding: 12px 16px;
  background: #5539ff;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 24px;
  margin-right: auto;
}
@media (min-width: 786px) {
  .landing-cnts-v3 .corptop-v > a {
    padding: 16px 20px;
    font-size: 20px;
  }
}
@media (min-width: 786px) {
  .landing-cnts-v3 .corptop-v {
    align-items: center;
    margin-top: 80px;
    background: url("../images/landingcorp03_pc.png") no-repeat 50% 0;
    background-size: 1440px auto;
    box-sizing: border-box;
    text-align: center;
  }
  .landing-cnts-v3 .corptop-v > span {
    margin-bottom: 20px;
    font-size: 24px;
  }
  .landing-cnts-v3 .corptop-v strong {
    font-size: 56px;
    line-height: 72px;
  }
  .landing-cnts-v3 .corptop-v p {
    margin-top: 28px;
    font-size: 24px;
    line-height: 36px;
  }
  .landing-cnts-v3 .corptop-v a {
    margin: 40px auto 0;
  }
  .landing-cnts-v3 .corptop-v:after {
    display: none;
  }
}
.landing-cnts-v3 .prob-solv {
  max-width: 1060px;
  margin: 0 auto;
  padding: 0 16px;
}
.landing-cnts-v3 .prob-solv h3 {
  font-size: 22px;
}
.landing-cnts-v3 .prob-solv h3:before {
  content: "🤔";
  display: block;
}
.landing-cnts-v3 .prob-solv ul li {
  margin-top: 15px;
  color: #8E9496;
  font-weight: 700;
}
.landing-cnts-v3 .prob-solv ul li:before {
  content: "👉";
  margin-right: 7px;
}
@media (min-width: 768px) {
  .landing-cnts-v3 .prob-solv {
    display: flex;
    margin-top: 80px;
  }
  .landing-cnts-v3 .prob-solv h3 {
    font-size: 44px;
  }
  .landing-cnts-v3 .prob-solv ul {
    margin-top: 50px;
    margin-left: auto;
    padding-left: 40px;
    font-size: 24px;
  }
}
.landing-cnts-v3 .service-tw {
  margin-top: 80px;
  padding: 40px 16px 90px;
  background: linear-gradient(0deg, #FAFBFC, #FAFBFC), linear-gradient(0deg, #FAFAFA, #FAFAFA), #FFF;
}
.landing-cnts-v3 .service-tw > h3 {
  color: #5539ff;
  text-align: center;
}
.landing-cnts-v3 .service-tw > div {
  max-width: 1080px;
  margin: 0 auto;
}
.landing-cnts-v3 .service-tw > div > p {
  color: #0d1240;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.landing-cnts-v3 .service-tw > div .service-w {
  margin-top: 40px;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox {
  overflow: hidden;
  margin: 24px 0 0;
  padding: 24px;
  background: url("../images/boxbg01.png") no-repeat 100% 100%;
  background-size: cover;
  box-shadow: 0px 20px 16px rgba(0, 0, 0, 0.04), 0px 8px 8px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  box-sizing: border-box;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox strong {
  font-size: 20px;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox p {
  margin-top: 24px;
  color: #8E9496;
  font-weight: 700;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox ul {
  margin-top: 24px;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox ul li {
  position: relative;
  padding-left: 35px;
  color: #8E9496;
  font-weight: 700;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  width: 22px;
  height: 24px;
  background: url("../images/icon_check_r.svg") no-repeat 0 0;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.d2 {
  background-image: url("../images/boxbg02.png");
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.d2 strong {
  color: #fff;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.d3 {
  background-color: #5539FF;
  background-image: none;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.d3 strong {
  color: #fff;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.d3 p {
  color: #fff;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.ds1 {
  background-image: url("../images/box2bg01.png");
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.ds1 strong, .landing-cnts-v3 .service-tw > div .service-w .inbox.ds1 p {
  color: #fff;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.ds2 {
  background-image: url("../images/box2bg02.png");
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.ds3 {
  background-image: url("../images/box2bg03.png");
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.ds4 {
  background-image: url("../images/box2bg04.png");
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.ds4 strong, .landing-cnts-v3 .service-tw > div .service-w .inbox.ds4 ul > li {
  color: #fff;
}
.landing-cnts-v3 .service-tw > div .service-w .inbox.ds4 ul li:before {
  background-image: url("../images/icon_check_rw.svg");
}
.landing-cnts-v3 .service-tw > div.service3 {
  margin-top: 70px;
}
@media (min-width: 768px) {
  .landing-cnts-v3 .service-tw {
    margin-top: 160px;
    padding-top: 80px;
  }
  .landing-cnts-v3 .service-tw > h3 {
    font-size: 24px;
  }
  .landing-cnts-v3 .service-tw > div > p {
    margin-top: 24px;
    font-size: 48px;
    line-height: 64px;
  }
  .landing-cnts-v3 .service-tw > div .service-w {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 80px;
  }
  .landing-cnts-v3 .service-tw > div .service-w .inbox {
    width: calc(50% - 12px);
    min-height: 400px;
    padding: 48px;
  }
  .landing-cnts-v3 .service-tw > div .service-w .inbox strong {
    font-size: 40px;
  }
  .landing-cnts-v3 .service-tw > div .service-w .inbox p {
    max-width: 400px;
    font-size: 24px;
    line-height: 36px;
  }
  .landing-cnts-v3 .service-tw > div .service-w .inbox ul li {
    font-size: 24px;
    line-height: 36px;
  }
  .landing-cnts-v3 .service-tw > div .service-w .inbox.d3 {
    min-width: 100%;
    background-image: url("../images/boxbg03.png");
  }
  .landing-cnts-v3 .service-tw > div.service3 {
    margin-top: 140px;
  }
}
.landing-cnts-v3 .price-w2 {
  max-width: 1080px;
  margin: 0 auto;
  padding: 70px 16px 0;
}
.landing-cnts-v3 .price-w2 > h3 {
  font-size: 24px;
}
.landing-cnts-v3 .price-w2 > p {
  color: #8C8C8C;
  margin-top: 12px;
  font-weight: 700;
}
.landing-cnts-v3 .price-w2 .price-box {
  margin-top: 30px;
}
.landing-cnts-v3 .price-w2 .price-box .inbox {
  background: #fff;
  margin-top: 10px;
  border: 1px solid #EAECF0;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .hgroup {
  min-height: 132px;
  position: relative;
  padding: 32px 32px 24px;
  box-sizing: border-box;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .hgroup h4 {
  font-size: 26px;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .hgroup p {
  color: #475467;
  font-weight: bold;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .hgroup.lev2 p {
  position: absolute;
  top: 32px;
  right: 32px;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .hgroup.lev2 p em {
  color: #0d1240;
  font-size: 60px;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .ser-li {
  padding: 32px 32px 40px;
  border-top: 1px solid #EAECF0;
  word-break: break-all;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .ser-li h5 {
  font-size: 18px;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .ser-li ul li {
  position: relative;
  margin-top: 20px;
  padding-left: 35px;
  color: #475467;
  font-weight: 700;
  font-size: 14px;
  word-break: keep-all;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .ser-li ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background: url("../images/icon_scheck_y.svg") no-repeat 0 0;
}
.landing-cnts-v3 .price-w2 .price-box .inbox .ser-li ul li.no:before {
  background-image: url("../images/icon_scheck_n.svg");
}
@media (min-width: 768px) {
  .landing-cnts-v3 .price-w2 {
    padding-top: 0px;
  }
  .landing-cnts-v3 .price-w2 > h3 {
    font-size: 48px;
  }
  .landing-cnts-v3 .price-w2 > p {
    margin-top: 24px;
    font-size: 24px;
  }
  .landing-cnts-v3 .price-w2 .price-box {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }
  .landing-cnts-v3 .price-w2 .price-box .inbox {
    width: calc(50% - 12px);
    margin-top: 0;
  }
  .landing-cnts-v3 .price-w2 .price-box .inbox .hgroup p {
    font-size: 20px;
  }
  .landing-cnts-v3 .price-w2 .price-box .inbox .ser-li ul {
    font-size: 20px;
  }
  .landing-cnts-v3 .price-w2 .price-box .inbox .hgroup h4 {
    font-size: 32px;
  }
  .landing-cnts-v3 .price-w2 .price-box .inbox .ser-li ul li {
    font-size: 16px;
  }
}

.btn {
  display: flex;
  margin-top: 40px;
  box-sizing: border-box;
  /* &.fx-type {
      display: flex;
      a,  button {
          width: auto;
          flex: auto;
          &:last-child {
              //flex: 1;
              //margin-left: 8px;
          }
      }
  } */
}
.btn .inner {
  display: flex;
  width: 100%;
}
.btn a, .btn button {
  display: block;
  width: 100%;
  height: 52px;
  margin-left: 8px;
  padding: 0 25px;
  color: #5539ff;
  font-size: 16px;
  text-align: center;
  line-height: 52px;
  box-sizing: border-box;
  white-space: nowrap;
  border-radius: 2px;
}
.btn a.actv, .btn button.actv {
  background: #5539ff !important;
  color: #fff !important;
}
.btn a.sub, .btn button.sub {
  margin-right: 8px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
}
.btn a.prev, .btn button.prev {
  max-width: 88px;
  margin-right: 8px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
}
.btn a.cel, .btn button.cel {
  max-width: 80px;
}
.btn a.disb, .btn a:disabled, .btn button.disb, .btn button:disabled {
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.4);
}
.btn a.wtype, .btn button.wtype {
  background: none;
  color: #5539ff;
}
.btn a:first-child, .btn button:first-child {
  margin-left: 0;
}
.btn a:last-child, .btn button:last-child {
  margin-right: 0;
}
@media (min-width: 786px) {
  .btn a, .btn button {
    height: 48px;
    font-size: 14px;
  }
  .btn a.cel, .btn button.cel {
    max-width: 190px;
  }
  .btn a.actv.save, .btn button.actv.save {
    max-width: 436px;
  }
}
.btn.type2 {
  display: flex;
}
.btn.type2 > a, .btn.type2 > button {
  height: 48px;
  border-radius: 2px;
  line-height: 48px;
}
.btn.type2 > a.cancel, .btn.type2 > button.cancel {
  width: 87px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
}
.btn.flex-col {
  flex-direction: column;
  margin-top: 32px;
}
.btn.flex-col a, .btn.flex-col button {
  display: block;
  margin-top: 8px;
}

.price-w {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-right: 24px;
  background-color: rgba(85, 57, 255, 0.03);
}
.price-w input {
  margin-top: 0 !important;
  background: none !important;
  text-align: right;
}
.price-w input::placeholder {
  text-align: left;
}
.price-w span {
  color: #0d1240;
  white-space: nowrap;
}

.dimd-layer {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 16px;
  background-color: rgba(13, 18, 64, 0.6);
  box-sizing: border-box;
}
.dimd-layer .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 52px;
  height: 52px;
}
.dimd-layer .btn-close:before, .dimd-layer .btn-close:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 13px);
  left: 50%;
  width: 2px;
  height: 25px;
  background-color: rgba(13, 18, 64, 0.4);
}
.dimd-layer .btn-close:before {
  transform: rotate(45deg);
}
.dimd-layer .btn-close:after {
  transform: rotate(-45deg);
}
.dimd-layer .btn-close span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.dimd-layer .ly-box {
  position: relative;
  width: 100%;
  margin: auto;
  background: #f9fdff;
}
.dimd-layer .ly-box .inner {
  max-width: 320px;
  margin: 0 auto;
}
.dimd-layer .ly-box .inner > h3 {
  font-size: 22px;
  font-weight: normal;
  line-height: 34px;
}
.dimd-layer .ly-box .inner > strong {
  display: block;
  margin-bottom: 20px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  text-align: left;
}
.dimd-layer .ly-box .inner > p {
  font-size: 16px;
  color: rgba(13, 18, 64, 0.6);
  text-align: left;
  line-height: 26px;
}
.dimd-layer .ly-box .inner > p.altype2 {
  text-align: center;
}
.dimd-layer .ly-box .inner > h3 + p,
.dimd-layer .ly-box .inner p ~ p {
  margin-top: 24px;
}
.dimd-layer .ly-box .inner .s-desc {
  margin-top: 32px;
  padding: 16px;
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
  line-height: 22px;
}
.dimd-layer .ly-box .inner .sp-end + .s-desc {
  margin-top: 16px;
}
.dimd-layer .ly-box .inner .ft-desc {
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
  line-height: 1.5;
}
.dimd-layer .ly-box .inner .ft-desc:before {
  content: "*";
}
.dimd-layer .ly-box > section {
  max-width: 320px;
  margin: 0 auto;
}
.dimd-layer .ly-box > section .hgroup {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
.dimd-layer .ly-box > section .hgroup h1 {
  font-size: 22px;
  font-weight: normal;
  text-align: center;
}
.dimd-layer .ly-box > section .hgroup p {
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  line-height: 26px;
}
@media (min-width: 786px) {
  .dimd-layer .ly-box > section {
    padding: 30px 0 40px;
  }
}
.dimd-layer .ly-box.alert-box {
  max-width: 640px;
  padding: 40px 24px 24px !important;
  font-size: 22px;
  text-align: center;
  line-height: 34px;
  box-sizing: border-box;
}
.dimd-layer .ly-box.alert-box > p {
  color: rgba(13, 18, 64, 0.6);
  text-align: left;
}
.dimd-layer .ly-box.alert-box .btn {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}
.dimd-layer .ly-box.alert-box p.link {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
.dimd-layer .ly-box.alert-box p.link a {
  text-decoration: underline;
}
@media (min-width: 786px) {
  .dimd-layer .ly-box.alert-box {
    padding: 64px 80px !important;
  }
}
.dimd-layer .ly-box.smtype {
  max-width: 480px;
  padding: 40px 24px 24px;
  box-sizing: border-box;
}
.dimd-layer .ly-box.smtype > p {
  font-size: 16px;
  line-height: 26px;
}
.dimd-layer .ly-box .h-pay {
  text-align: left;
}
.dimd-layer .ly-box .h-pay > label {
  font-size: 16px;
  font-weight: bold;
}
.dimd-layer .ly-box .h-pay > div {
  display: flex;
  align-items: center;
  background-color: rgba(85, 57, 255, 0.03);
}
.dimd-layer .ly-box .h-pay > div input {
  background: none;
}
.dimd-layer .ly-box .h-pay > div span {
  padding-right: 20px;
  font-size: 16px;
}
.dimd-layer .ly-box .alrtype2 {
  max-width: 320px;
  margin: 0 auto;
}
.dimd-layer .ly-box .alrtype2 > h3 {
  font-size: 22px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
  font-weight: normal;
}
.dimd-layer .ly-box .alrtype2 > p {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  text-align: left;
  line-height: 26px;
}
@media (min-width: 786px) {
  .dimd-layer .ly-box .alrtype2 {
    margin-top: -30px;
  }
}
.dimd-layer .pjt-road h1 {
  font-size: 22px;
  font-weight: normal;
  text-align: center;
}
.dimd-layer .pjt-road > p {
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  text-align: center;
  line-height: 26px;
}
.dimd-layer .pjt-road .cnts {
  margin-top: 24px;
  border-top: 1px solid rgba(13, 18, 64, 0.05);
}
.dimd-layer .pjt-road .cnts .pjt-list {
  padding-top: 16px;
}
.dimd-layer .pjt-road .cnts .pjt-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
  font-size: 16px;
  line-height: 22px;
}
.dimd-layer .pjt-road .cnts .pjt-list li em {
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dimd-layer .pjt-road .cnts .pjt-list li span {
  color: rgba(13, 18, 64, 0.6);
  text-align: right;
}
.dimd-layer .pjt-road .cnts .no-data {
  margin-top: 24px;
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  line-height: 26px;
}
.dimd-layer .pjt-road .cnts .no-data:before {
  display: none;
}
@media (min-width: 786px) {
  .dimd-layer .pjt-road {
    overflow: auto;
    width: calc(100% + 48px);
    max-height: 420px;
    margin-top: 24px;
    margin-left: -24px;
    padding: 0 80px;
    box-sizing: border-box;
  }
  .dimd-layer .pjt-road .cnts .pjt-list li em {
    font-weight: 500;
    font-size: 14px;
  }
  .dimd-layer .pjt-road .cnts .pjt-list li span {
    font-size: 14px;
  }
  .dimd-layer .pjt-road .cnts .pjt-list li span br {
    display: none;
  }
}
.dimd-layer .recu-chk-wrap {
  max-width: 320px;
  margin: 0 auto;
}
.dimd-layer .recu-chk-wrap h1 {
  font-size: 22px;
  font-weight: normal;
  text-align: center;
}
.dimd-layer .recu-chk-wrap > p {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  line-height: 26px;
}
.dimd-layer .recu-chk-wrap .recu-chk > ul > li {
  margin-top: 24px;
}
.dimd-layer .recu-chk-wrap .recu-chk > ul > li label {
  display: block;
  font-size: 16px;
  font-weight: bold;
}
.dimd-layer .recu-chk-wrap .recu-chk > ul > li select,
.dimd-layer .recu-chk-wrap .recu-chk > ul > li > input[type=text],
.dimd-layer .recu-chk-wrap .recu-chk > ul > li textarea {
  width: 100%;
  margin-top: 8px;
  font-size: 16px;
}
.dimd-layer .recu-chk-wrap .recu-chk > ul > li .inpt-w {
  display: flex;
}
.dimd-layer .recu-chk-wrap .recu-chk > ul > li .inpt-w select {
  width: 124px;
  margin-right: 10px;
}
.dimd-layer .recu-chk-wrap .recu-chk > ul > li .add-link {
  margin-top: 20px;
  text-align: right;
}
.dimd-layer .recu-chk-wrap .recu-chk > ul > li .add-link a {
  color: #5539ff;
  font-size: 16px;
  text-decoration: underline;
}
.dimd-layer .recu-chk-wrap .recu-chk > ul > li .sel-form {
  margin-top: 8px;
}
.dimd-layer .recu-chk-wrap .recu-chk > p {
  margin-top: 32px;
  font-size: 16px;
  color: rgba(13, 18, 64, 0.6);
  line-height: 26px;
}
.dimd-layer .recu-chk-wrap .recu-chk > p em {
  color: #5539ff;
  font-weight: bold;
}
@media (min-width: 786px) {
  .dimd-layer .recu-chk-wrap {
    padding: 30px 0 40px;
  }
}
.dimd-layer .cho-list {
  padding-top: 16px;
  text-align: left;
}
.dimd-layer .cho-list li {
  position: relative;
  margin-top: 8px;
}
.dimd-layer .cho-list li label {
  display: block;
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #0d1240;
  font-size: 16px;
  line-height: 26px;
}
.dimd-layer .cho-list li label:before, .dimd-layer .cho-list li label:after {
  display: none !important;
}
.dimd-layer .cho-list li input:checked + label {
  padding: 15px;
  border: 1px solid #5539ff;
  color: #5539ff;
}
.dimd-layer .cho-list li input[type=text] {
  position: absolute;
  top: 0;
  left: 50px;
  width: calc(100% - 50px);
  background: none;
}
.dimd-layer .cho-list li.etc label {
  color: rgba(13, 18, 64, 0.4);
}
.dimd-layer .cho-list > p {
  margin-top: 24px;
  font-size: 16px;
}
.dimd-layer .cho-list > p a {
  color: #5539ff;
  text-decoration: underline;
}
.dimd-layer .sp-end {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 16px;
  font-size: 14px;
  border-top: 1px solid rgba(13, 18, 64, 0.05);
}
.dimd-layer .sp-end label, .dimd-layer .sp-end .tit {
  white-space: nowrap;
  color: #0d1240;
  font-size: 14px;
  font-weight: bold;
}
.dimd-layer .sp-end label span, .dimd-layer .sp-end .tit span {
  padding-left: 5px;
  font-size: 12px;
  color: rgba(13, 18, 64, 0.6);
}
.dimd-layer .sp-end > input {
  max-width: 87px;
  height: auto;
  margin-left: auto;
  padding: 0 8px;
  background: none;
  color: #5539ff;
  text-align: center;
  text-decoration: underline;
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
  box-sizing: border-box;
}
.dimd-layer .sp-end ul {
  min-width: 100%;
}
.dimd-layer .sp-end ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  color: rgba(13, 18, 64, 0.6);
}
.dimd-layer .sp-end ul li > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-left: auto;
}
.dimd-layer .sp-end ul li > div input {
  max-width: 90px;
  height: 26px;
  padding: 0 8px;
  text-align: right;
  text-decoration: underline;
  color: #5539ff;
  line-height: 26px;
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
  box-sizing: border-box;
}
.dimd-layer .sp-end ul li > div span {
  margin-left: 8px;
  font-size: 16px;
  color: rgba(13, 18, 64, 0.6);
  text-decoration: underline;
  line-height: 26px;
}
.dimd-layer .sp-end ul li > div p {
  position: absolute;
  z-index: 10;
  top: 25px;
  right: -15px;
  padding: 8px;
  color: #ff4e16;
  font-size: 12px;
  white-space: nowrap;
  border: 1px solid rgba(13, 18, 64, 0.05);
  background: #fff;
  line-height: 18px;
}
.dimd-layer .sp-end ul li > div.prg-work {
  margin-top: 3px;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
}
.dimd-layer .sp-end ul li > i {
  white-space: nowrap;
  color: #0d1240;
  font-weight: bold;
}
.dimd-layer .sp-end ul li > span {
  color: rgba(13, 18, 64, 0.6);
}
.dimd-layer .sp-end ul li textarea {
  min-width: 100%;
  height: 80px;
  margin-top: 3px;
  padding: 16px;
  font-size: 14px;
}
.dimd-layer .sp-end ul li:first-child {
  margin-top: 0;
}
.dimd-layer .sp-end ul.type2 li {
  margin-top: 0;
}
.dimd-layer .sp-end2 {
  margin-top: 24px;
  padding-top: 16px;
  font-size: 14px;
  border-top: 1px solid rgba(13, 18, 64, 0.05);
}
.dimd-layer .sp-end2 .tit {
  display: block;
  white-space: nowrap;
  color: #0d1240;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}
.dimd-layer .sp-end2 .tit span {
  padding-left: 5px;
  font-size: 12px;
  color: rgba(13, 18, 64, 0.6);
}
.dimd-layer .sp-end2 ul {
  min-width: 100%;
}
.dimd-layer .sp-end2 ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  color: rgba(13, 18, 64, 0.6);
}
.dimd-layer .sp-end2 ul li > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-left: auto;
}
.dimd-layer .sp-end2 ul li > div input {
  max-width: 90px;
  height: 26px;
  padding: 0 8px;
  text-align: right;
  text-decoration: underline;
  color: #5539ff;
  line-height: 26px;
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
  box-sizing: border-box;
}
.dimd-layer .sp-end2 ul li > div span {
  margin-left: 8px;
  font-size: 16px;
  color: rgba(13, 18, 64, 0.6);
  text-decoration: underline;
  line-height: 26px;
}
.dimd-layer .sp-end2 ul li > div p {
  position: absolute;
  z-index: 10;
  top: 25px;
  right: -15px;
  padding: 8px;
  color: #ff4e16;
  font-size: 12px;
  white-space: nowrap;
  border: 1px solid rgba(13, 18, 64, 0.05);
  background: #fff;
  line-height: 18px;
}
.dimd-layer .sp-end2 ul li > div.prg-work {
  margin-top: 3px;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
}
.dimd-layer .sp-end2 ul li > i {
  white-space: nowrap;
  color: #0d1240;
  font-weight: bold;
}
.dimd-layer .sp-end2 ul li > span {
  color: rgba(13, 18, 64, 0.6);
}
.dimd-layer .sp-end2 ul li textarea {
  min-width: 100%;
  height: 80px;
  margin-top: 3px;
  padding: 16px;
  font-size: 14px;
}
.dimd-layer .sp-end2 ul li:first-child {
  margin-top: 0;
}
.dimd-layer .sp-end2 ul.type2 li {
  margin-top: 0;
}
.dimd-layer .sp-end2 .price-w {
  width: 100%;
  margin-top: 0;
  background: none;
}
.dimd-layer .sp-end2 .price-w input {
  background: rgba(85, 57, 255, 0.03) !important;
  color: #5539ff;
}
.dimd-layer .sp-end2 .price-w input::placeholder {
  text-align: right;
}
.dimd-layer .sp-end2 .price-w span {
  padding-left: 6px;
}
.dimd-layer .sp-end2 .week-pay {
  display: flex;
  width: 100%;
  font-weight: bold;
}
.dimd-layer .sp-end2 .week-pay em {
  margin-left: auto;
  font-size: 16px;
}
.dimd-layer .sp-end2 .pay-result > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 23px;
  margin-top: 6px;
  color: rgba(13, 18, 64, 0.6);
}
.dimd-layer .sp-end2 .pay-result > div > em {
  color: #0d1240;
  font-weight: bold;
}
.dimd-layer .sp-end2 .pay-result > div > em span {
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
  font-weight: normal;
}
.dimd-layer .sp-end2 .pay-result > div > strong {
  color: #0d1240;
  font-size: 16px;
}
.dimd-layer .sp-end2 .pay-result > div > i {
  display: flex;
  align-items: center;
}
.dimd-layer .sp-end2 .pay-result > div > i:before {
  content: "";
  min-width: 14px;
  height: 18px;
  margin-right: 5px;
  background: url("../images/icon_rep.svg") no-repeat 0 0;
}
.dimd-layer .sp-end2 .pay-result > div.app-price {
  margin-top: 15px;
}
.dimd-layer .sp-end2 .pay-result > div.app-price.matching > em, .dimd-layer .sp-end2 .pay-result > div.app-price.matching > strong {
  color: #5539ff;
}
.dimd-layer .sp-end2 .pay-result > div.add-mius {
  color: rgba(85, 57, 255, 0.8);
}
.dimd-layer .sp-end2 .pay-result .desc-match {
  display: flex;
  align-items: center;
  margin-top: 2px;
  color: rgba(13, 18, 64, 0.6);
}
.dimd-layer .sp-end2 .pay-result .desc-match:before {
  content: "";
  min-width: 14px;
  height: 18px;
  margin-right: 5px;
  background: url("../images/icon_rep.svg") no-repeat 0 0;
}
.dimd-layer .desc-box {
  margin-top: 24px;
  padding: 16px;
  background-color: rgba(13, 18, 64, 0.02);
  border-radius: 4px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
  text-align: left;
  line-height: 22px;
}
.dimd-layer .desc-box ul li {
  display: flex;
}
.dimd-layer .desc-box ul li:before {
  content: "";
  min-width: 4px;
  height: 4px;
  margin: 8px 5px 0 0;
  background-color: rgba(13, 18, 64, 0.4);
  border-radius: 50%;
}
.dimd-layer .g-price {
  margin: 24px 0 0;
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
}
.dimd-layer .g-price p {
  color: #5539ff;
  font-size: 16px;
  font-weight: bold;
}
.dimd-layer .g-price p span {
  padding-left: 20px;
}
.dimd-layer .g-price + p {
  margin-top: 24px;
}
.dimd-layer .scenario-w h1 {
  font-size: 22px;
  font-weight: normal;
  text-align: center;
}
.dimd-layer .scenario-w ul {
  margin-top: 32px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  line-height: 26px;
}
.dimd-layer .scenario-w ul li {
  display: flex;
}
.dimd-layer .scenario-w ul li:before {
  content: "";
  min-width: 5px;
  height: 5px;
  margin: 9px 10px 0 0;
  background: rgba(13, 18, 64, 0.6);
  border-radius: 50%;
}
.dimd-layer .hope-date {
  margin-top: 16px;
  text-align: left;
}
.dimd-layer .hope-date .date-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 16px;
}
.dimd-layer .hope-date .date-w em {
  font-weight: bold;
}
.dimd-layer .hope-date .date-w span {
  color: rgba(13, 18, 64, 0.6);
  text-decoration: underline;
}
.dimd-layer .hope-date > p {
  margin-top: 24px;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.dimd-layer .ch-pw {
  text-align: left;
  line-height: 100%;
}
.dimd-layer .ch-pw > div {
  margin-top: 25px;
}
.dimd-layer .ch-pw > div label {
  display: block;
  padding-bottom: 8px;
  font-size: 14px;
}
.dimd-layer .ch-pw > div > span {
  display: block;
  position: relative;
}
.dimd-layer .ch-pw > div > span button {
  top: 18px;
}
.dimd-layer .cho-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.dimd-layer .cho-date > label {
  margin-right: 18px;
  font-size: 14px;
  font-weight: bold;
}
.dimd-layer .cho-date .d-w {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 14px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.dimd-layer .cho-date .d-w input {
  width: 135px;
  height: auto;
  padding: 0;
  font-size: 16px;
  background: none;
}
.dimd-layer .cho-date .d-w button.cal {
  width: 22px;
  height: 22px;
  background: url("../images/icon_calendar.svg") no-repeat 100% 0;
}
.dimd-layer .cho-date .d-w button.cal span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}

.join-sign .visual {
  display: none;
}
.join-sign .join-w,
.join-sign .join-end {
  padding: 130px 24px;
  box-sizing: border-box;
}
.join-sign .join-w .inner,
.join-sign .join-end .inner {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.join-sign .join-w .inner .link,
.join-sign .join-end .inner .link {
  display: inline-block;
  margin-top: 16px;
  color: #5539ff;
  text-decoration: underline;
}
.join-sign .join-w .inner h2 {
  font-size: 32px;
  text-align: center;
  line-height: 48px;
}
.join-sign .join-w .inner h2:after {
  content: "";
  display: block;
  position: relative;
  height: 27px;
  margin: -22px auto 0;
  background: url("../images/line_img.png") no-repeat 50% 0;
  background-size: auto 100%;
  transform: rotate(5deg);
  z-index: -1;
}
.join-sign .join-w .inner h2.noline:after {
  display: none;
}
.join-sign .join-w .inner h2.m-join {
  font-size: 22px;
  line-height: 34px;
}
.join-sign .join-w .inner h2.m-join:after {
  margin-top: -8px;
  background-width: 168px;
  background-height: 31px;
  background-image: url("../images/join_line.png");
  background-size: contain;
  transform: rotate(3deg);
}
@media (min-width: 786px) {
  .join-sign .join-w .inner h2.m-join {
    font-size: 32px;
  }
  .join-sign .join-w .inner h2.m-join:after {
    width: 190px;
    height: 39px;
    margin-right: -50px;
    transform: rotate(0);
  }
}
.join-sign .join-w .inner > p {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
  text-align: center;
  line-height: 26px;
}
.join-sign .join-w .inner > p.tit {
  margin-top: 0;
}
.join-sign .join-w .inner .chk-w {
  margin-top: 40px;
  padding: 28px;
  background: rgba(85, 57, 255, 0.05);
  border-radius: 4px;
}
.join-sign .join-w .inner .chk-w > p {
  margin-top: 24px;
  text-align: left;
}
.join-sign .join-w .inner .type-choice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;
}
.join-sign .join-w .inner .type-choice > span {
  padding: 8px 8px;
}
.join-sign .join-w .inner .type-choice > span label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 200px;
  padding: 0;
  background-color: rgba(85, 57, 255, 0.03);
  border-radius: 4px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  box-sizing: border-box;
}
.join-sign .join-w .inner .type-choice > span label:before {
  position: static;
  width: 100px;
  height: 100px;
  margin-bottom: 18px;
  background: url("../images/img_typechoice.png") no-repeat 0 0;
  background-size: auto 100%;
  border: none;
}
.join-sign .join-w .inner .type-choice > span label:after {
  display: none;
}
.join-sign .join-w .inner .type-choice > span input:checked + label {
  color: #5539ff;
  border: 1px solid #5539ff;
}
.join-sign .join-w .inner .type-choice > span.ch-memb label:before {
  background-position: 100% 0;
}
.join-sign .join-w .inner .btn-link {
  margin-top: 32px;
}
.join-sign .join-w .inner .btn-link button {
  color: #5539ff;
  text-decoration: underline;
}
.join-sign .join-end {
  text-align: center;
}
.join-sign .join-end strong {
  display: block;
  margin-top: 43px;
  font-size: 32px;
  line-height: 48px;
}
.join-sign .join-end strong span {
  position: relative;
}
.join-sign .join-end strong span:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: -39px;
  left: -4px;
  width: 56px;
  height: 84px;
  background: url("../images/line_cir.png") no-repeat 0 0;
  background-size: 100% auto;
}
.join-sign .join-end strong.type2 {
  font-size: 32px;
  line-height: 48px;
}
.join-sign .join-end p {
  margin-top: 23px;
  color: rgba(13, 18, 64, 0.6);
  line-height: 26px;
}
.join-sign .join-end .code-int {
  margin-top: 40px;
}
.join-sign .join-end .code-int p.err {
  margin-top: 5px;
  color: #ff4e16;
  font-size: 12px;
  text-align: left;
}
@media (min-width: 786px) {
  .join-sign {
    display: flex;
  }
  .join-sign > div {
    width: 50%;
  }
  .join-sign > div.visual {
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    background: #5539ff;
  }
  .join-sign > div.visual .inner {
    position: relative;
    max-width: 588px;
    margin: 0 auto;
    padding: 0 30px;
  }
  .join-sign > div.visual .inner img {
    width: 100%;
  }
  .join-sign > div.visual span {
    display: block;
    position: absolute;
  }
  .join-sign > div.visual span img {
    width: 100%;
  }
  .join-sign > div.visual span.j1 {
    right: 0;
    width: 240px;
  }
  .join-sign > div.visual span.j2 {
    top: calc(50% - 134px);
    width: 268px;
  }
  .join-sign > div.visual span.j3 {
    right: 0;
    bottom: 0;
    width: 305px;
  }
  .join-sign > div.join-w, .join-sign > div.join-end {
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    height: calc(100vh - 70px);
    padding-top: 90px;
    margin-top: 70px;
  }
  .join-sign > div.join-w .inner h2 {
    display: inline-block;
  }
  .join-sign > div.join-w .inner h2 br {
    display: none;
  }
  .join-sign > div.join-w .inner h2:after {
    margin-right: -20px;
    background-position: 100% 0;
  }
  .join-sign > div.join-w .inner > p {
    text-align: left;
  }
  .join-sign > div.join-w .inner .type-choice {
    flex-direction: row;
  }
  .join-sign > div.join-w .inner .type-choice > span label {
    width: 192px;
  }
  .join-sign > div.join-w .inner > p {
    line-height: 22px;
  }
  .join-sign > div.join-w .inner > p .only-mo {
    display: none;
  }
  .join-sign > div.join-end .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .join-sign > div.join-end .inner strong br {
    display: none;
  }
  .join-sign > div.join-end .inner strong.type2 {
    font-weight: normal;
    font-size: 22px;
  }
  .join-sign > div.join-end .inner p.invt {
    font-size: 16px;
  }
}
@media (min-width: 1400px) {
  .join-sign > div.join-w.arrtype:before {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 45px);
    left: 0;
    width: 163px;
    height: 90px;
    background: url("../images/join_arrow.png") no-repeat 0 0;
    background-size: cover;
  }
}

.chk-inc {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  padding: 17px 10px;
  background-color: #f9fdff;
}
.chk-inc p {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.chk-inc p:before {
  content: "";
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 8px;
  background: url("../images/icon_chk.svg") no-repeat 0 0;
}

.join-form {
  text-align: left;
}
.join-form h3 {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 13px;
  border-bottom: 1px solid #5539ff;
  color: #5539ff;
  font-size: 16px;
  font-weight: normal;
}
.join-form h3:before {
  content: "";
  width: 20px;
  height: 10px;
  margin-right: 8px;
  border-radius: 10px;
  background: #5539ff;
  transform: rotate(-45deg);
}
.join-form h3.noline {
  padding-bottom: 0;
  border: none;
  font-size: 12px;
}
.join-form h3.noline:before {
  width: 9px;
  height: 4px;
  margin-top: -4px;
  margin-right: 5px;
}
.join-form > p {
  margin-top: 16px;
  color: rgba(13, 18, 64, 0.6);
}
.join-form > p.tit {
  color: #0d1240;
  font-size: 14px;
}
.join-form .noti-box {
  margin-top: 24px;
  padding: 15px;
  background: #f9fdff;
  text-align: center;
}
.join-form .noti-box p {
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
}
.join-form ul.fm-list > li {
  position: relative;
  margin-top: 35px;
}
.join-form ul.fm-list > li > label,
.join-form ul.fm-list > li .tit {
  display: block;
  padding-bottom: 6px;
  font-size: 14px;
  font-weight: bold;
}
.join-form ul.fm-list > li > input {
  font-size: 14px;
}
.join-form ul.fm-list > li > select {
  width: 100%;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.join-form ul.fm-list > li > textarea {
  height: 112px;
  margin-top: 10px;
  padding: 17px 24px;
  font-size: 14px;
}
.join-form ul.fm-list > li .fm-inbtn {
  height: 48px;
  padding: 0 24px;
  border: 1px solid #5539ff;
  border-radius: 2px;
  color: #5539ff;
  line-height: 46px;
}
.join-form ul.fm-list > li .txt-count {
  position: absolute;
  top: 0;
  right: 0;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.join-form ul.fm-list > li .c-phone {
  display: flex;
  position: relative;
}
.join-form ul.fm-list > li .c-phone select {
  min-width: 100px;
  margin-right: 8px;
}
.join-form ul.fm-list > li .c-phone > button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 105px;
  height: 40px;
  background: #5539ff;
  color: #fff;
  border-radius: 2px;
  text-align: center;
}
.join-form ul.fm-list > li .c-phone > button:disabled {
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.4);
}
.join-form ul.fm-list > li .c-phone .link {
  color: #5539ff;
  text-decoration: underline;
}
.join-form ul.fm-list > li .c-phone.end select {
  background-color: rgba(13, 18, 64, 0.05);
}
.join-form ul.fm-list > li .c-phone.end input {
  background: rgba(13, 18, 64, 0.05);
}
.join-form ul.fm-list > li .e-mail {
  position: relative;
}
.join-form ul.fm-list > li .e-mail > button {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 40px;
  padding: 0 12px;
  background: #5539ff;
  color: #fff;
  border-radius: 2px;
}
.join-form ul.fm-list > li .e-mail input:disabled + button {
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.4);
}
.join-form ul.fm-list > li .e-mail .loading {
  position: absolute;
  top: 20px;
  right: 105px;
}
.join-form ul.fm-list > li .e-mail .loading img {
  width: 17px;
  height: 17px;
}
.join-form ul.fm-list > li .e-mail .time {
  position: absolute;
  top: -20px;
  right: 0;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
}
.join-form ul.fm-list > li .e-mail .time a, .join-form ul.fm-list > li .e-mail .time button {
  margin-left: 3px;
  color: #5539ff;
  text-decoration: underline;
  font-size: 12px;
}
.join-form ul.fm-list > li .inc-num {
  position: relative;
  margin-top: 8px;
  padding-left: 108px;
}
.join-form ul.fm-list > li .inc-num button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 105px;
  height: 40px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
  text-align: center;
}
.join-form ul.fm-list > li > p {
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
}
.join-form ul.fm-list > li > p.err {
  color: #f00;
}
.join-form ul.fm-list > li > p a {
  color: #5539ff;
  text-decoration: underline;
}
.join-form ul.fm-list > li .agree {
  margin-top: 8px;
  padding-top: 0;
}
.join-form ul.fm-list > li .re-inc {
  position: absolute;
  top: 5px;
  right: 0;
  color: #5539ff;
  font-size: 12px;
  text-decoration: underline;
}
.join-form ul.fm-list > li .time {
  position: absolute;
  top: 0;
  right: 0;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
}
.join-form ul.fm-list > li .time a, .join-form ul.fm-list > li .time button {
  margin-left: 3px;
  color: #5539ff;
  text-decoration: underline;
  font-size: 12px;
}
.join-form ul.fm-list > li .tex-info {
  margin-top: 9px;
  padding: 16px 24px;
  border-radius: 2px;
  background-color: rgba(13, 18, 64, 0.02);
}
.join-form ul.fm-list > li .tex-info p {
  color: rgba(13, 18, 64, 0.6);
  line-height: 22px;
}
.join-form ul.fm-list > li .tex-info a {
  display: inline-block;
  margin-top: 8px;
  color: #5539ff;
  text-decoration: underline;
}
.join-form ul.lifestyle-choice {
  padding-bottom: 25px;
}
.join-form ul.lifestyle-choice li {
  position: relative;
  margin-top: 15px;
}
.join-form ul.lifestyle-choice li:before {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  right: 14px;
  width: 117px;
  height: 90px;
  background: url("../images/icon_join.png") no-repeat 0 0;
  background-size: auto 100%;
}
.join-form ul.lifestyle-choice li label {
  display: flex;
  flex-direction: column;
  min-height: 120px;
  padding: 16px 14px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 4px rgba(63, 63, 63, 0.2);
  border: solid 1px rgba(85, 57, 255, 0.1);
  box-sizing: border-box;
}
.join-form ul.lifestyle-choice li label span {
  margin-top: auto;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.join-form ul.lifestyle-choice li label em {
  font-size: 16px;
  font-weight: bold;
}
.join-form ul.lifestyle-choice li label:before {
  top: 15px;
  left: 14px;
}
.join-form ul.lifestyle-choice li label:after {
  top: 18px;
  left: 17px;
}
.join-form ul.lifestyle-choice li input[type=checkbox]:checked + label {
  box-shadow: 2px 2px 10px 4px rgba(85, 57, 255, 0.2);
}
.join-form ul.lifestyle-choice li.m2:before {
  background-position: -117px 0;
}
.join-form ul.lifestyle-choice li.m3:before {
  background-position: -234px 0;
}
.join-form ul.lifestyle-choice li.m4:before {
  background-position: -351px 0;
}
.join-form ul.lifestyle-choice li.m5:before {
  background-position: -470px 0;
}
@media (min-width: 786px) {
  .join-form ul.lifestyle-choice {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .join-form ul.lifestyle-choice li {
    width: calc(50% - 10px);
  }
  .join-form ul.lifestyle-choice li:before {
    width: 91px;
    height: 70px;
  }
  .join-form ul.lifestyle-choice li label {
    min-height: 146px;
    line-height: 140%;
    box-shadow: 2px 2px 10px 4px rgba(63, 63, 63, 0.08);
  }
  .join-form ul.lifestyle-choice li label span {
    font-size: 12px;
  }
  .join-form ul.lifestyle-choice li label em {
    font-size: 14px;
  }
  .join-form ul.lifestyle-choice li.m2:before {
    background-position: -91px 0;
  }
  .join-form ul.lifestyle-choice li.m3:before {
    background-position: -182px 0;
  }
  .join-form ul.lifestyle-choice li.m4:before {
    background-position: -273px 0;
  }
  .join-form ul.lifestyle-choice li.m5:before {
    background-position: -357px 0;
  }
}
.join-form .job-sug {
  padding-top: 7px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
.join-form .job-sug > div {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.join-form .job-sug > div select {
  margin-right: 10px;
  font-size: 16px;
}
.join-form .job-sug > div select:first-child {
  width: 35%;
}
.join-form .job-sug > div select:nth-child(2) {
  flex: 1;
}
.join-form .job-sug > div > button {
  position: relative;
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin-left: auto;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
}
.join-form .job-sug > div > button:before, .join-form .job-sug > div > button:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 1px);
  width: 2px;
  height: 13px;
  background-color: rgba(13, 18, 64, 0.6);
}
.join-form .job-sug > div > button:before {
  transform: rotate(45deg);
}
.join-form .job-sug > div > button:after {
  transform: rotate(-45deg);
}
.join-form .job-sug > div > button span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.join-form .job-sug .j-add {
  width: 100%;
  margin-top: 30px;
  color: #5539ff;
  text-align: center;
}
@media (min-width: 786px) {
  .join-form .job-sug {
    padding-bottom: 15px;
  }
  .join-form .job-sug > div select {
    flex: 1;
  }
  .join-form .job-sug .j-add {
    margin-top: 15px;
    text-align: right;
    text-decoration: underline;
  }
}
.join-form h4 {
  margin-top: 35px;
  padding-bottom: 8px;
  font-size: 14px;
}
.join-form h4 > span {
  padding-left: 10px;
  color: rgba(13, 18, 64, 0.4);
  font-weight: normal;
}
.join-form .chk-box {
  padding: 24px;
  border-radius: 8px;
  background-color: rgba(85, 57, 255, 0.05);
}
.join-form .chk-box input + label {
  color: #0d1240;
}
.join-form .chk-box ul li {
  margin-top: 15px;
}
.join-form .chk-box ul li:first-child {
  margin-top: 0;
}
.join-form .chk-box > p {
  margin-top: 15px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
}
.join-form .chk-box.myp-type {
  background-color: rgba(85, 57, 255, 0.05);
  border: none;
  border-radius: 2px;
}
.join-form .chk-box.err {
  border: 1px solid #ff4e16;
  background-color: rgba(255, 78, 22, 0.05);
}
.join-form h3 + .chk-box {
  margin-top: 25px;
}
.join-form .agree {
  padding-top: 22px;
}
.join-form .agree li {
  display: flex;
  align-items: center;
  margin-top: 13px;
}
.join-form .agree li label a {
  text-decoration: underline;
}
.join-form .agree li > a {
  min-width: 26px;
  height: 26px;
  background: url("../images/icon_dirgo.svg") no-repeat 6px 50%;
  box-sizing: border-box;
}
.join-form .agree li > a > span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.join-form .agree li input.em ~ a {
  background-position: -15px 50%;
}
.join-form input.em + label {
  color: #ff4e16;
}
.join-form input.em + label:before {
  border-color: #ff4e16;
}
.join-form input.em + label a {
  color: #ff4e16;
}
.join-form .recom-sug h4 {
  margin-top: 30px;
}
.join-form .recom-sug .job-sug {
  padding-bottom: 0;
  border: none;
}

.w-choice {
  padding: 20px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.w-choice h5 {
  margin-top: 25px;
  font-weight: normal;
}
.w-choice h5:first-child {
  margin-top: 0;
}
.w-choice .ch-list {
  display: flex;
  flex-wrap: wrap;
}
.w-choice .ch-list > span {
  margin-top: 10px;
  margin-right: 10px;
}
.w-choice .ch-list > span label {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #5539ff;
  line-height: 120%;
}
.w-choice .ch-list > span label:before {
  display: none;
}
.w-choice .ch-list > span label:after {
  top: 18px;
  left: 12px;
  width: 10px;
  height: 5px;
  border-width: 0 0 1px 1px;
}
.w-choice .ch-list > span input[type=checkbox]:checked + label {
  padding-left: 30px;
  background: #5539ff;
  color: #fff;
}

.part-t {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.part-t > span {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 88px;
  width: 88px;
  height: 22px;
  margin-right: 8px;
  background: rgba(150, 255, 198, 0.05);
  border: 1px solid #96FFC6;
  border-radius: 6px;
}
.part-t.gh2 > span {
  background: rgba(224, 254, 82, 0.05);
  border-color: #E0FE52;
}
.part-t.gh3 > span {
  background: rgba(255, 180, 148, 0.05);
  border-color: #FFB494;
}

.mypage-w .header {
  padding: 120px 24px 40px;
  background: #5539ff;
}
.mypage-w .header .info-box {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 40px auto 0;
  padding: 60px 0 45px;
  background: #fff;
  text-align: center;
}
.mypage-w .header .info-box h2 {
  position: relative;
  font-size: 22px;
  font-weight: normal;
}
.mypage-w .header .info-box h2 span {
  position: relative;
}
.mypage-w .header .info-box h2:before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  left: calc(50% - 40px);
  width: 80px;
  height: 33px;
  background-image: linear-gradient(to left, #e0fe52, rgba(224, 254, 82, 0));
  border-radius: 30px;
  transform: rotate(-45deg);
}
.mypage-w .header .info-box > p {
  margin-top: 30px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
  line-height: 26px;
}
.mypage-w .header .info-box > p > span {
  display: none;
}
.mypage-w .header .info-box > span {
  display: block;
  margin-top: 16px;
  font-size: 14px;
  color: rgba(13, 18, 64, 0.6);
}
.mypage-w .header .info-box .in-btn a {
  display: block;
  position: relative;
  height: 64px;
  margin-top: 27px;
  background: #e0fe52;
  font-size: 16px;
  line-height: 64px;
}
.mypage-w .header .info-box .in-btn a:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 185px);
  width: 109px;
  height: 88px;
  background: url("../images/mypage_arrow.png") no-repeat 0 0;
  background-size: 100% auto;
}
.mypage-w .header.reject .info-box {
  padding: 50px 24px 24px;
}
.mypage-w .header.reject .info-box .res-cnts {
  margin-top: 35px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(13, 18, 64, 0.6);
}
.mypage-w .header.reject .info-box .res-cnts > strong {
  color: #000;
}
@media (min-width: 786px) {
  .mypage-w .header {
    overflow: hidden;
    position: relative;
    padding-bottom: 80px;
  }
  .mypage-w .header:before, .mypage-w .header:after {
    content: "";
    display: block;
    position: absolute;
  }
  .mypage-w .header:before {
    top: 135px;
    left: calc(50% - 490px);
    width: 220px;
    height: 253px;
    background: url("../images/mypageimg_left01.png") no-repeat 0 100%;
    background-size: 100% auto;
  }
  .mypage-w .header:after {
    bottom: -50px;
    left: calc(50% + 310px);
    width: 181px;
    height: 297px;
    background: url("../images/mypageimg_right01.png") no-repeat 0 0;
    background-size: 100% auto;
  }
  .mypage-w .header .info-box {
    position: relative;
  }
  .mypage-w .header .info-box > p br {
    display: none;
  }
  .mypage-w .header .info-box > p span {
    display: inline;
  }
  .mypage-w .header.reject:before {
    width: 236px;
    height: 242px;
    background-image: url("../images/mypageimg_left03.png");
  }
  .mypage-w .header.reject:after {
    left: calc(50% + 350px);
    width: 132px;
    height: 305px;
    background-image: url("../images/mypageimg_right03.png");
  }
  .mypage-w .header.end:before {
    width: 222px;
    height: 257px;
    background-image: url("../images/mypageimg_left02.png");
  }
  .mypage-w .header.end:after {
    left: calc(50% + 270px);
    width: 203px;
    height: 350px;
    background-image: url("../images/mypageimg_right04.png");
  }
}
.mypage-w .header-type2 {
  padding: 80px 0 30px;
  background: #5539ff;
}
.mypage-w .header-type2 .prg-pag {
  display: flex;
  flex-wrap: wrap;
  max-width: 1296px;
  min-height: 48px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}
.mypage-w .header-type2 .prg-pag button {
  display: flex;
  align-items: center;
  color: #fff;
}
.mypage-w .header-type2 .prg-pag button > span {
  overflow: hidden;
  min-width: 28px;
  height: 28px;
  margin-right: 5px;
  border-radius: 50%;
}
.mypage-w .header-type2 .prg-pag button > span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mypage-w .header-type2 .prg-pag button:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("../images/icon_prev.svg") no-repeat 0 0;
}
.mypage-w .header-type2 .prg-pag button.prev:before {
  margin-right: 7px;
}
.mypage-w .header-type2 .prg-pag button.next {
  flex-direction: row-reverse;
  margin-left: auto;
}
.mypage-w .header-type2 .prg-pag button.next:before {
  margin-left: 7px;
  transform: rotate(180deg);
}
.mypage-w .header-type2 .prg-pag .prg-paging {
  display: flex;
  justify-content: center;
  min-width: 100%;
  margin-top: 14px;
}
.mypage-w .header-type2 .prg-pag .prg-paging a {
  width: 6px;
  height: 6px;
  margin: 0 5px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}
.mypage-w .header-type2 .prg-pag .prg-paging a.selected {
  width: 22px;
  background: #fff;
}
.mypage-w .header-type2 .viewport {
  overflow: auto;
  display: flex;
  margin-top: -80px;
  padding-top: 60px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mypage-w .header-type2 .viewport::-webkit-scrollbar {
  display: none;
}
.mypage-w .header-type2 .viewport .pf-info {
  margin: 0 auto;
  padding: 0 9px;
  box-sizing: border-box;
}
.mypage-w .header-type2 .viewport .pf-info .inbox {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 311px;
  min-height: 305px;
  margin: 30px 0 0;
  padding: 0 16px 20px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .mem-w {
  display: flex;
  margin-top: 10px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .mem-w span {
  overflow: hidden;
  min-width: 36px;
  width: 36px;
  height: 36px;
  margin-right: -8px;
  border-radius: 50%;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .mem-w span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .mem-w .num {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #5539ff;
  font-size: 14px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .photo-name {
  position: relative;
  top: -60px;
  margin-bottom: -60px;
  text-align: center;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .photo-name .photo {
  overflow: hidden;
  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .photo-name .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .photo-name .name {
  display: block;
  margin-top: 15px;
  font-size: 22px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .info .hash a {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  color: rgba(85, 57, 255, 0.8);
  white-space: nowrap;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .info .info-j {
  margin-top: auto;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .info .info-j ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 16px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .info .info-j ul li {
  position: relative;
  margin-top: 3px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .info .info-j ul li i {
  margin-right: 5px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .info .info-j ul li:first-child {
  min-width: 100%;
  margin-bottom: 5px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .info .info-j ul li:last-child {
  margin-left: 10px;
  padding-left: 11px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .info .info-j ul li:last-child:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 1px;
  height: 14px;
  background: rgba(13, 18, 64, 0.1);
}
.mypage-w .header-type2 .viewport .pf-info .inbox .in-btn {
  margin-top: auto;
  padding-top: 13px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .in-btn a, .mypage-w .header-type2 .viewport .pf-info .inbox .in-btn button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  padding: 0 33px;
  background: #5539ff;
  color: #fff;
  box-sizing: border-box;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .in-btn a:disabled, .mypage-w .header-type2 .viewport .pf-info .inbox .in-btn a.disabled, .mypage-w .header-type2 .viewport .pf-info .inbox .in-btn button:disabled, .mypage-w .header-type2 .viewport .pf-info .inbox .in-btn button.disabled {
  background: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.4);
}
.mypage-w .header-type2 .viewport .pf-info .inbox .pf-modify {
  position: absolute;
  top: 15px;
  right: 15px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .pf-modify button {
  width: 23px;
  height: 22px;
  background: url("../images/icon_menu.svg") no-repeat 0 0;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .pf-modify button span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .pf-modify > div {
  display: none;
  position: absolute;
  top: 34px;
  right: 0;
  min-width: 130px;
  background: #fff;
  border: 1px solid #5539FF;
  border-radius: 2px;
  text-align: center;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .pf-modify > div a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  color: #5539ff;
  white-space: nowrap;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .pf-modify.selected > div {
  display: block;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .inner {
  display: flex;
  flex-direction: column;
  min-height: 285px;
  padding: 30px 0 0;
  text-align: left;
  box-sizing: border-box;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .inner h2 {
  font-size: 18px;
  font-weight: 700;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .inner h2 em {
  overflow: hidden;
  display: inline-block;
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .inner h2 span {
  display: block;
  position: relative;
  margin-top: 8px;
  margin-left: 10px;
  padding-left: 11px;
  font-size: 16px;
  font-weight: 400;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .inner h2 span:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 1px;
  height: 11px;
  background: rgba(13, 18, 64, 0.1);
}
.mypage-w .header-type2 .viewport .pf-info .inbox .inner .part i,
.mypage-w .header-type2 .viewport .pf-info .inbox .inner .mem-num i {
  display: block;
  padding-bottom: 7px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .inner .part {
  margin-top: auto;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .inner .mem-num {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.mypage-w .header-type2 .viewport .pf-info .inbox .inner .mem-num > div:last-child {
  text-align: right;
}
.mypage-w .header-type2 .viewport .pf-info .secondteam {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 311px;
  min-height: 305px;
  margin-top: 30px;
  padding: 20px 36px 20px 14px;
  background: url("../images/img_secondteam_mo.png") no-repeat 50% 50%;
  background-size: cover;
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff;
}
.mypage-w .header-type2 .viewport .pf-info .secondteam h2 {
  color: #fff;
  font-size: 18px;
  font-weight: normal;
}
.mypage-w .header-type2 .viewport .pf-info .secondteam p {
  margin-top: 5px;
  font-size: 16px;
  line-height: 26px;
}
.mypage-w .header-type2 .viewport .pf-info .secondteam p br {
  display: none;
}
.mypage-w .header-type2 .viewport .pf-info:first-child {
  margin-left: calc(50% - 155.5px);
}
.mypage-w .header-type2 .viewport .pf-info:last-child {
  margin-right: calc(50% - 155.5px);
}
@media (min-width: 786px) {
  .mypage-w .header-type2 {
    padding: 80px 0 30px;
  }
  .mypage-w .header-type2 .viewport {
    margin-top: -72px;
  }
  .mypage-w .header-type2 .viewport .pf-info {
    padding: 0 90px;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox {
    flex-direction: row;
    width: 720px;
    min-height: 140px;
    text-align: left;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .mem-w {
    position: absolute;
    top: 5px;
    right: 50px;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .photo-name {
    top: -30px;
    margin-bottom: -30px;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .photo-name .name {
    margin-top: 10px;
    font-size: 18px;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .info {
    padding: 25px 0 0 25px;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .info .info-j ul {
    justify-content: flex-start;
    font-size: 14px;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .in-btn {
    position: absolute;
    bottom: 25px;
    right: 27px;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .inner {
    min-height: auto;
    padding: 20px 15px 0;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .inner h2 {
    display: flex;
    align-items: center;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .inner h2 span {
    margin-top: 0;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .inner .pf-modify {
    top: 25px;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .inner .mem-num {
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    right: 25px;
    text-align: right;
  }
  .mypage-w .header-type2 .viewport .pf-info .inbox .inner .mem-num > div {
    margin-left: 0;
  }
  .mypage-w .header-type2 .viewport .pf-info .secondteam {
    justify-content: center;
    width: 720px;
    min-height: 140px;
    padding: 0;
    background-image: url("../images/img_secondteam.png");
  }
  .mypage-w .header-type2 .viewport .pf-info .secondteam h2 {
    font-size: 20px;
  }
  .mypage-w .header-type2 .viewport .pf-info .secondteam p {
    margin-top: 10px;
    font-size: 14px;
  }
  .mypage-w .header-type2 .viewport .pf-info .secondteam p br {
    display: inline;
  }
  .mypage-w .header-type2 .viewport .pf-info:first-child {
    margin-left: calc(50% - 450px);
  }
  .mypage-w .header-type2 .viewport .pf-info:last-child {
    margin-right: calc(50% - 450px);
  }
}
.mypage-w .conts-w {
  max-width: 600px;
  margin: 0 auto;
  padding: 160px 24px 80px;
}
.mypage-w .conts-w > h2 {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 13px;
  border-bottom: 1px solid #5539ff;
  color: #5539ff;
  font-size: 16px;
  font-weight: normal;
}
.mypage-w .conts-w > h2:before {
  content: "";
  width: 20px;
  height: 10px;
  margin-right: 8px;
  border-radius: 10px;
  background: #5539ff;
  transform: rotate(-45deg);
}
.mypage-w .conts-w > h2:first-child {
  margin-top: 0;
}
.mypage-w .conts-w > h2 span {
  margin-left: auto;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.mypage-w .conts-w .bank-in {
  padding-top: 16px;
}
.mypage-w .conts-w .bank-in .inbox {
  margin-top: 16px;
  padding: 24px 32px;
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
  box-sizing: border-box;
}
.mypage-w .conts-w .bank-in .inbox > em {
  font-weight: bold;
  font-size: 16px;
}
.mypage-w .conts-w .bank-in .inbox > p {
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
}
.mypage-w .conts-w .bank-in .inbox > strong {
  display: block;
  margin-top: 48px;
  font-size: 22px;
  font-weight: normal;
}
.mypage-w .conts-w .bank-in .inbox > strong.st {
  color: #5539ff;
}
@media (min-width: 786px) {
  .mypage-w .conts-w .bank-in {
    display: flex;
    justify-content: space-between;
  }
  .mypage-w .conts-w .bank-in .inbox {
    width: calc(50% - 8px);
  }
}
.mypage-w .conts-w .team-box {
  position: relative;
  margin-top: 32px;
  padding: 24px 32px 0;
  background-color: rgba(85, 57, 255, 0.03);
}
.mypage-w .conts-w .team-box h3 {
  font-size: 16px;
}
.mypage-w .conts-w .team-box > p {
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
}
.mypage-w .conts-w .team-box > em {
  position: absolute;
  top: 24px;
  right: 32px;
  height: 34px;
  padding: 0 16px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
  font-size: 12px;
  line-height: 34px;
}
.mypage-w .conts-w .team-box .total {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding-bottom: 16px;
  font-size: 16px;
}
.mypage-w .conts-w .team-box .total strong {
  font-weight: normal;
}
.mypage-w .conts-w .team-box .team-info {
  width: calc(100% + 64px);
  margin-left: -32px;
  padding-top: 24px;
  background-color: rgba(85, 57, 255, 0.03);
  border-bottom: 1px solid rgba(85, 57, 255, 0.05);
}
.mypage-w .conts-w .team-box .team-info .hgroup {
  margin-top: 33px;
  padding: 0 32px;
}
.mypage-w .conts-w .team-box .team-info .hgroup > em {
  display: inline-block;
  height: 34px;
  padding: 0 16px;
  border-radius: 50px;
  background-color: rgba(13, 18, 64, 0.05);
  font-size: 12px;
  line-height: 34px;
}
.mypage-w .conts-w .team-box .team-info .hgroup .total {
  margin-top: 16px;
  font-size: 14px;
}
.mypage-w .conts-w .team-box .team-info .hgroup:first-child {
  margin-top: 0;
}
.mypage-w .conts-w .team-box .team-info .hgroup.sid-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mypage-w .conts-w .team-box .team-info .hgroup.sid-type .total {
  margin-top: 0;
}
.mypage-w .conts-w .team-box .team-info .prd-list {
  margin-top: 18px;
}
.mypage-w .conts-w .team-box .team-info .prd-list li {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
}
.mypage-w .conts-w .team-box > button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 64px);
  height: 32px;
  margin-left: -32px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #5539ff;
  font-size: 12px;
  line-height: 32px;
}
.mypage-w .conts-w .team-box > button:after {
  content: "";
  width: 0;
  height: 0;
  margin-left: 7px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #5539ff;
}
.mypage-w .conts-w .team-box > button.btn-close:after {
  transform: rotate(180deg);
}
.mypage-w .conts-w .team-box.disb {
  background-color: rgba(13, 18, 64, 0.05);
}
.mypage-w .conts-w .team-box.disb > em {
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.6);
}
.mypage-w .conts-w .team-box.disb > button {
  background-color: rgba(13, 18, 64, 0.05);
  color: #0d1240;
}
.mypage-w .conts-w .team-box.disb > button:after {
  border-top-color: #0d1240;
}
.mypage-w .header + .conts-w {
  padding-top: 0;
}
.mypage-w .conts-w2 {
  max-width: 720px;
  margin: 0 auto;
  padding: 10px 24px 95px;
}
.mypage-w .conts-w2 h3 {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 13px;
  border-bottom: 1px solid #5539ff;
  color: #5539ff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 30px;
  padding-bottom: 0;
  border: none;
}
.mypage-w .conts-w2 h3:before {
  content: "";
  width: 20px;
  height: 10px;
  margin-right: 8px;
  border-radius: 10px;
  background: #5539ff;
  transform: rotate(-45deg);
}
.mypage-w .conts-w2 h3 span {
  margin-left: 7px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.mypage-w .conts-w2 .c-desc {
  margin-top: 8px;
  line-height: 26px;
}
.mypage-w .conts-w2 .pf-box {
  margin-top: 15px;
  padding: 20px;
  border: 1px solid rgba(13, 18, 64, 0.05);
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff;
}
.mypage-w .conts-w2 .pf-box h4 {
  margin-top: 25px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
  font-weight: normal;
}
.mypage-w .conts-w2 .pf-box h4:first-child {
  margin-top: 0;
}
.mypage-w .conts-w2 .pf-box > p {
  margin-top: 5px;
  font-size: 14px;
  line-height: 22px;
}
.mypage-w .conts-w2 .pf-box .introduce {
  margin-top: 0;
  line-height: 26px;
}
.mypage-w .conts-w2 .pf-box .skill-list ul {
  display: inline;
  font-size: 14px;
}
.mypage-w .conts-w2 .pf-box .skill-list ul li {
  display: inline-flex;
  align-items: center;
  height: 32px;
  margin-top: 7px;
  margin-right: 8px;
  padding: 0 10px;
  border-radius: 30px;
  background: rgba(85, 57, 255, 0.03);
}
.mypage-w .conts-w2 .pf-box .skill-list a.more {
  display: inline-block;
  margin-top: 8px;
  white-space: nowrap;
  color: #5539ff;
  text-decoration: underline;
}
.mypage-w .conts-w2 .pf-box .data-thumb {
  margin-top: 5px;
}
.mypage-w .conts-w2 .pf-box .data-thumb > a {
  display: block;
}
.mypage-w .conts-w2 .pf-box .data-thumb > a > span {
  color: #0057FF;
  font-size: 14px;
}
.mypage-w .conts-w2 .pf-box .data-thumb > a .inner {
  display: flex;
  margin-top: 10px;
}
.mypage-w .conts-w2 .pf-box .data-thumb > a .inner img {
  width: 121px;
  margin-right: 15px;
}
.mypage-w .conts-w2 .pf-box .data-thumb > a .inner p {
  overflow: hidden;
  display: -webkit-box;
  height: 89px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
  line-height: 22px;
  word-wrap: break-word;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.mypage-w .conts-w2 .pf-box .add-info {
  margin-top: 20px;
  padding-top: 13px;
  border-top: 1px solid rgba(13, 18, 64, 0.05);
}
.mypage-w .conts-w2 .pf-box .add-info .sns-info {
  display: flex;
  flex-wrap: wrap;
}
.mypage-w .conts-w2 .pf-box .add-info .sns-info span {
  display: flex;
  margin: 6px 12px 6px 0;
  padding: 8px 12px;
  border-radius: 2px;
  border: solid 1px rgba(85, 57, 255, 0.05);
  background-color: #faf9ff;
  font-size: 14px;
  white-space: nowrap;
}
.mypage-w .conts-w2 .pf-box .add-info .sns-info span:before {
  content: "";
  width: 18px;
  height: 18px;
  margin-right: 9px;
  border-radius: 4px;
  background: url("../images/icon_sns_p.svg") no-repeat 0 0;
}
.mypage-w .conts-w2 .pf-box .add-info .sns-info span.dribble:before {
  background-position: -32px 0;
}
.mypage-w .conts-w2 .pf-box .add-info .sns-info span.github:before {
  background-position: -64px 0;
}
.mypage-w .conts-w2 .pf-box .add-info .sns-info span.blog:before {
  background-position: -96px 0;
}
.mypage-w .conts-w2 .pf-box .add-info .sns-info span.behance:before {
  background-position: -128px 0;
}
.mypage-w .conts-w2 .pf-box .add-info .sns-info span.plus {
  flex-direction: row-reverse;
}
.mypage-w .conts-w2 .pf-box .add-info .sns-info span.plus:before {
  margin-left: 9px;
  margin-right: 0;
  background: url("../images/icon_dirgo.svg") no-repeat -40px 50%;
}
.mypage-w .conts-w2 .pf-box .donut-w {
  margin-top: 5px;
}
.mypage-w .conts-w2 .pf-box .donut-w ul {
  padding-top: 12px;
  font-size: 14px;
}
.mypage-w .conts-w2 .pf-box hr {
  display: block;
  margin: 20px 0;
  border: 0.5px solid rgba(13, 18, 64, 0.05);
}
.mypage-w .conts-w2 .pjt-box {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 300px;
  padding: 30px 20px 20px;
  margin-top: 15px;
  box-shadow: 0px 1px 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
}
.mypage-w .conts-w2 .pjt-box h4 {
  font-size: 18px;
}
.mypage-w .conts-w2 .pjt-box h4 span {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-weight: normal;
}
.mypage-w .conts-w2 .pjt-box .mem-w {
  display: flex;
  margin-top: 10px;
}
.mypage-w .conts-w2 .pjt-box .mem-w span {
  overflow: hidden;
  min-width: 36px;
  width: 36px;
  height: 36px;
  margin-right: -8px;
  border-radius: 50%;
}
.mypage-w .conts-w2 .pjt-box .mem-w span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mypage-w .conts-w2 .pjt-box .mem-w .num {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #5539ff;
  font-size: 14px;
}
.mypage-w .conts-w2 .pjt-box .part,
.mypage-w .conts-w2 .pjt-box .mem-num {
  display: flex;
  padding-top: 10px;
}
.mypage-w .conts-w2 .pjt-box .part i,
.mypage-w .conts-w2 .pjt-box .mem-num i {
  padding-bottom: 7px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.mypage-w .conts-w2 .pjt-box .part {
  flex-direction: column;
  margin-top: auto;
}
.mypage-w .conts-w2 .pjt-box .mem-num {
  justify-content: space-between;
}
.mypage-w .conts-w2 .pjt-box .mem-num > div {
  display: flex;
  flex-direction: column;
}
.mypage-w .conts-w2 .pjt-box .mem-num > div:last-child {
  align-items: flex-end;
}
.mypage-w .conts-w2 h3 + .pf-box,
.mypage-w .conts-w2 h3 + .pjt-box {
  margin-top: 12px;
}
.mypage-w .conts-w2 .pjt-add {
  margin-top: 15px;
  padding: 15px 25px;
  background: rgba(85, 57, 255, 0.03);
  border-radius: 10px;
  text-align: center;
}
.mypage-w .conts-w2 .pjt-add p {
  color: #5539ff;
  font-size: 12px;
  line-height: 18px;
}
.mypage-w .conts-w2 .pjt-add a {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 280px;
  height: 40px;
  margin: 8px auto 0;
  background: #5539ff;
  color: #fff;
  font-size: 14px;
}
.mypage-w .conts-w2 .rec-list ul {
  padding-bottom: 20px;
}
.mypage-w .conts-w2 .rec-list ul li {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.mypage-w .conts-w2 .rec-list ul li i {
  display: block;
  padding-bottom: 5px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.mypage-w .conts-w2 .rec-list ul li .pay {
  color: #5539ff;
}
.mypage-w .conts-w2 .rec-list ul li .time-box {
  display: inline-flex;
  align-items: center;
  height: 38px;
  margin-right: auto;
  padding: 0 12px;
  background: rgba(85, 57, 255, 0.04);
  font-size: 14px;
}
.mypage-w .conts-w2 .rec-list ul li:first-child {
  margin-top: 0;
}
.mypage-w .conts-w2 .time-list ul {
  display: flex;
  flex-wrap: wrap;
}
.mypage-w .conts-w2 .time-list ul li {
  display: flex;
  align-items: center;
  height: 38px;
  margin-top: 7px;
  margin-right: 7px;
  padding: 0 12px;
  background: rgba(85, 57, 255, 0.03);
  font-size: 14px;
  white-space: nowrap;
}
.mypage-w .conts-w2 .tit-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
}
.mypage-w .conts-w2 .tit-w h3 {
  margin-top: 0;
  margin-bottom: 8px;
}
.mypage-w .conts-w2 .chk-st {
  display: flex;
  flex-wrap: wrap;
}
.mypage-w .conts-w2 .chk-st > span {
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 12px;
}
.mypage-w .conts-w2 .chk-st > span span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.mypage-w .conts-w2 .chk-st > span:before {
  content: "";
  min-width: 18px;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  background: url("../images/icon_ref.svg") no-repeat 0 0;
}
.mypage-w .conts-w2 .chk-st > span.c2:before {
  background-position: -22px 0;
}
.mypage-w .conts-w2 .chk-st > span.c3:before {
  background-position: -44px 0;
}
.mypage-w .conts-w2 .refchk-w .refchk-box {
  margin-top: 8px;
  padding: 20px 14px;
  border: 1px solid rgba(13, 18, 64, 0.05);
  border-radius: 10px;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w {
  display: flex;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .photo {
  overflow: hidden;
  min-width: 60px;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 50%;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .name-w > div {
  display: flex;
  align-items: center;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .name-w > div em {
  margin-right: 10px;
  font-size: 14px;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .name-w > div em a {
  color: #5539ff;
  font-weight: bold;
  text-decoration: underline;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .name-w > div .chk-st span {
  margin-left: 2px;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .name-w > div .chk-st span:before {
  margin-right: 0;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .name-w > p {
  margin-top: 4px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .name-w > p span:before {
  content: "·";
  margin: 0 4px;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .name-w > p span:first-child:before {
  display: none;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .w-pjt {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  font-size: 14px;
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .w-pjt i {
  color: rgba(13, 18, 64, 0.6);
}
.mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .w-pjt a {
  color: #5539ff;
  text-decoration: underline;
}
.mypage-w .conts-w2 .refchk-w .refchk-box ul {
  line-height: 22px;
  font-size: 14px;
}
.mypage-w .conts-w2 .refchk-w .refchk-box ul li {
  position: relative;
  margin-top: 10px;
  padding-left: 15px;
}
.mypage-w .conts-w2 .refchk-w .refchk-box ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 7px;
  height: 4px;
  margin-right: 8px;
  border-radius: 10px;
  background: #5539ff;
  transform: rotate(-45deg);
}
.mypage-w .conts-w2 .refchk-w .refchk-box > p {
  margin-top: 6px;
  padding-left: 15px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 12px;
}
.mypage-w .conts-w2 .member-list .mem-c {
  display: block;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
  text-align: right;
}
.mypage-w .conts-w2 .member-list .p-list {
  overflow: auto;
  width: 100vw;
  margin-left: -24px;
  margin-top: 8px;
  padding-left: 24px;
  box-sizing: border-box;
}
.mypage-w .conts-w2 .member-list .p-list .viewport {
  width: 720px;
  margin-right: 24px;
  padding: 29px 20px 25px;
  background: rgba(85, 57, 255, 0.03);
  box-sizing: border-box;
  border-radius: 15px;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul {
  display: flex;
  text-align: center;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li {
  min-width: 140px;
  color: #0d1240;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li .photo {
  overflow: hidden;
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  box-sizing: border-box;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li .photo.no-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(85, 57, 255, 0.1);
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li .photo.no-photo:before {
  content: "";
  display: block;
  width: 48px;
  height: 48px;
  background: rgba(85, 57, 255, 0.05) url("../images/icon_no_mem.svg") no-repeat 50% 50%;
  border-radius: 50%;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li > em {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li > em a {
  color: #5539ff;
  font-weight: bold;
  text-decoration: underline;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li div {
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li div span {
  display: block;
  margin-top: 4px;
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li.no-mem {
  color: rgba(13, 18, 64, 0.4);
}
.mypage-w .conts-w2 .member-list .p-list .viewport ul li.no-mem div {
  color: rgba(13, 18, 64, 0.4);
}
.mypage-w .conts-w2 .member-list .no-member {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 195px;
  margin-top: 8px;
  border: 1px dashed rgba(85, 57, 255, 0.4);
  box-sizing: border-box;
  border-radius: 15px;
  font-size: 14px;
  text-align: center;
}
.mypage-w .conts-w2 .member-list .no-member p {
  line-height: 32px;
}
.mypage-w .conts-w2 .member-list .no-member p span {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 58px;
  height: 28px;
  margin-right: 4px;
  padding: 0 5px;
  background: #5539ff;
  color: #fff;
  font-size: 8px;
  border-radius: 20px;
  box-sizing: border-box;
}
.mypage-w .conts-w2 .member-list .no-member p span:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  right: 3px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff url("../images/icon_toggle.png") no-repeat 50% 50%;
  background-size: cover;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}
@media (min-width: 786px) {
  .mypage-w .conts-w2 .member-list {
    margin-top: -20px;
  }
  .mypage-w .conts-w2 .member-list .p-list {
    width: auto;
  }
  .mypage-w .conts-w2 .member-list .no-member {
    background: #fff;
  }
  .mypage-w .conts-w2 .member-list .no-member p br {
    display: none;
  }
}
.mypage-w .conts-w2 .with-member ul li {
  position: relative;
  min-height: 100px;
  margin-top: 10px;
  padding: 20px 14px;
  background: #fff;
  border: 1px solid rgba(13, 18, 64, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
}
.mypage-w .conts-w2 .with-member ul li .pf-w {
  width: 100%;
  padding-left: 70px;
  box-sizing: border-box;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .photo {
  overflow: hidden;
  display: block;
  position: absolute;
  top: 20px;
  left: 10px;
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 60px;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .info > div {
  display: flex;
  align-items: center;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .info > div em {
  margin-right: 10px;
  font-size: 14px;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .info > div em a {
  color: #5539ff;
  font-weight: bold;
  text-decoration: underline;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .info > div .chk-st > span {
  margin-left: 4px;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .info > div .chk-st > span:before {
  margin-right: 0;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .info > p {
  overflow: hidden;
  display: block;
  width: 80%;
  margin-top: 4px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .info > p span:before {
  content: "·";
  margin: 0 4px;
}
.mypage-w .conts-w2 .with-member ul li .pf-w .info > p span:first-child:before {
  display: none;
}
.mypage-w .conts-w2 .with-member ul li input[type=checkbox] + label {
  position: absolute;
  top: 36px;
  right: 14px;
  width: 58px;
  height: 28px;
  padding-left: 33px;
  background: rgba(13, 18, 64, 0.1);
  border-radius: 17px;
  box-sizing: border-box;
  font-size: 8px;
}
.mypage-w .conts-w2 .with-member ul li input[type=checkbox] + label:before {
  top: 3px;
  left: 3px;
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 50%;
  background-image: url("../images/icon_toggle.png");
  background-size: cover;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}
.mypage-w .conts-w2 .with-member ul li input[type=checkbox] + label:after {
  display: none;
}
.mypage-w .conts-w2 .with-member ul li input[type=checkbox] + label .add {
  color: rgba(13, 18, 64, 0.6);
}
.mypage-w .conts-w2 .with-member ul li input[type=checkbox] + label .chk {
  display: none;
}
.mypage-w .conts-w2 .with-member ul li input[type=checkbox]:checked + label {
  padding-left: 6px;
  background: #5539ff;
}
.mypage-w .conts-w2 .with-member ul li input[type=checkbox]:checked + label:before {
  left: auto;
  right: 3px;
  background-color: #fff;
}
.mypage-w .conts-w2 .with-member ul li input[type=checkbox]:checked + label .add {
  display: none;
}
.mypage-w .conts-w2 .with-member ul li input[type=checkbox]:checked + label .chk {
  display: block;
  color: #fff;
}
.mypage-w .conts-w2 .with-member .no-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 98px;
  margin-top: 10px;
  background: rgba(85, 57, 255, 0.03);
  border-radius: 15px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  text-align: center;
  line-height: 22px;
}
@media (min-width: 786px) {
  .mypage-w .conts-w2 .with-member ul li .pf-w {
    padding-left: 90px;
  }
  .mypage-w .conts-w2 .with-member ul li .pf-w .photo {
    left: 20px;
  }
  .mypage-w .conts-w2 .with-member .no-info p br {
    display: none;
  }
}
@media (min-width: 786px) {
  .mypage-w .conts-w2 {
    margin-top: 50px;
  }
  .mypage-w .conts-w2 h3 span {
    font-size: 12px;
  }
  .mypage-w .conts-w2 h3:first-child {
    margin-top: 0;
  }
  .mypage-w .conts-w2 .box-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mypage-w .conts-w2 .box-wrap .pf-box {
    width: calc(50% - 10px);
  }
  .mypage-w .conts-w2 .pjt-box {
    height: 140px;
    padding-top: 20px;
  }
  .mypage-w .conts-w2 .pjt-box h4 {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .mypage-w .conts-w2 .pjt-box h4 em {
    overflow: hidden;
    display: inline-block;
    max-width: 220px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .mypage-w .conts-w2 .pjt-box h4 span {
    position: relative;
    margin-top: 0;
    margin-left: 10px;
    padding-left: 11px;
    font-size: 14px;
  }
  .mypage-w .conts-w2 .pjt-box h4 span:before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 0;
    width: 1px;
    height: 11px;
    background: rgba(13, 18, 64, 0.1);
  }
  .mypage-w .conts-w2 .pjt-box .mem-w {
    position: absolute;
    top: 5px;
    right: 35px;
  }
  .mypage-w .conts-w2 .pjt-box .mem-num {
    position: absolute;
    right: 25px;
    bottom: 20px;
    text-align: right;
  }
  .mypage-w .conts-w2 .pjt-box .mem-num > div {
    margin-left: 72px;
  }
  .mypage-w .conts-w2 .pjt-add p br {
    display: none;
  }
  .mypage-w .conts-w2 .rec-info {
    display: flex;
    justify-content: space-between;
  }
  .mypage-w .conts-w2 .rec-info .posb {
    display: flex;
    align-items: center;
    margin-top: 25px;
    color: rgba(13, 18, 64, 0.6);
    font-size: 14px;
  }
  .mypage-w .conts-w2 .rec-info .posb:before {
    content: "";
    min-width: 16px;
    height: 16px;
    margin-top: -2px;
    margin-right: 5px;
    background: rgba(13, 18, 64, 0.4) url("../images/icon_x.svg") no-repeat 50% 50%;
    border-radius: 4px;
  }
  .mypage-w .conts-w2 .rec-info .posb.check:before {
    background-color: #5539ff;
    background-image: url("../images/icon_check.svg");
  }
  .mypage-w .conts-w2 .rec-info .posb ~ .posb {
    margin-top: 8px;
  }
  .mypage-w .conts-w2 .rec-info .pf-box:first-child {
    min-width: 300px;
    width: 300px;
  }
  .mypage-w .conts-w2 .rec-info .pf-box:last-child {
    width: 390px;
    margin-left: 30px;
  }
  .mypage-w .conts-w2 .refchk-w .refchk-box .pf-w {
    align-items: center;
  }
  .mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .mypage-w .conts-w2 .refchk-w .refchk-box .pf-w .info .w-pjt {
    align-items: flex-end;
    margin-top: 0;
  }
  .mypage-w .conts-w2 .refchk-w .refchk-box ul, .mypage-w .conts-w2 .refchk-w .refchk-box > p {
    margin-left: 57px;
  }
}
.mypage-w .dashboard-w {
  padding: 25px 20px 0;
}
.mypage-w .dashboard-w .profile-add {
  max-width: 700px;
  margin: 0 auto 30px;
  padding: 24px 10px 20px;
  background: #F7F5FF url("../images/img_pf_add.png") no-repeat 100% 0;
  background-size: auto 79px;
  border: 1px solid rgba(85, 57, 255, 0.1);
  border-radius: 8px;
  font-size: 14px;
}
.mypage-w .dashboard-w .profile-add h4 {
  color: #5539ff;
}
.mypage-w .dashboard-w .profile-add h4:before {
  content: "⚠️";
  margin-right: 5px;
}
.mypage-w .dashboard-w .profile-add h4 br {
  display: none;
}
.mypage-w .dashboard-w .profile-add p {
  margin-top: 6px;
  line-height: 22px;
}
.mypage-w .dashboard-w .profile-add ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  padding: 4px 7px 11px;
  background: #fff;
  border-radius: 6px;
}
.mypage-w .dashboard-w .profile-add ul li {
  display: flex;
  align-items: center;
  position: relative;
  width: calc(50% - 2px);
  height: 40px;
  margin-top: 5px;
  padding: 0 6px;
  background: rgba(85, 57, 255, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
}
.mypage-w .dashboard-w .profile-add ul li:before {
  margin-right: 5px;
  font-size: 12px;
}
.mypage-w .dashboard-w .profile-add ul li.m1:before {
  content: "📛";
}
.mypage-w .dashboard-w .profile-add ul li.m2:before {
  content: "⏱️";
}
.mypage-w .dashboard-w .profile-add ul li.m3:before {
  content: "💰";
}
.mypage-w .dashboard-w .profile-add ul li.m4:before {
  content: "💼";
}
.mypage-w .dashboard-w .profile-add ul li.chk {
  background: rgba(13, 18, 64, 0.02);
}
.mypage-w .dashboard-w .profile-add ul li.chk:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 8px);
  right: 12px;
  width: 16px;
  height: 16px;
  background-image: url("../images/icon_chk_p.svg");
}
.mypage-w .dashboard-w .profile-add .btn {
  margin-top: 15px;
}
.mypage-w .dashboard-w .dashboard-cnts .conts-w2 {
  margin-top: 0;
  padding: 0;
}
@media (min-width: 1366px) {
  .mypage-w .dashboard-w {
    display: flex;
    position: relative;
    max-width: 1330px;
    height: auto;
    margin: 0 auto;
    padding: 45px 20px 0;
  }
  .mypage-w .dashboard-w .profile-add {
    position: sticky;
    top: 100px;
    left: 0;
    min-width: 262px;
    width: 262px;
    margin: 0 0 auto;
    background-position: 93% 0;
  }
  .mypage-w .dashboard-w .profile-add h4 {
    line-height: 22px;
  }
  .mypage-w .dashboard-w .profile-add h4 br {
    display: inline;
  }
  .mypage-w .dashboard-w .profile-add h4:before {
    display: block;
  }
  .mypage-w .dashboard-w .profile-add ul {
    display: block;
  }
  .mypage-w .dashboard-w .profile-add ul li {
    width: 100%;
  }
  .mypage-w .dashboard-w .dashboard-cnts .conts-w2 {
    padding-left: 20px;
  }
}
.mypage-w .add-info-share {
  padding-top: 15px;
}
.mypage-w .add-info-share button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  margin-top: 15px;
  background-color: #5539ff;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  box-sizing: border-box;
}
.mypage-w .add-info-share button span {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.mypage-w .add-info-share button span:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: url("../images/icon_pjt.svg") no-repeat 0 0;
}
.mypage-w .add-info-share button.with {
  background-color: #0BCD64;
}
.mypage-w .add-info-share button.with span:before {
  background-position: -27px 0;
}
.mypage-w .add-info-share button.share {
  background-color: #fff;
  border: 2px solid #5539ff;
  color: #5539ff;
}
.mypage-w .add-info-share button.share span:before {
  background-position: -55px 0;
}
@media (min-width: 786px) {
  .mypage-w .add-info-share {
    display: flex;
  }
  .mypage-w .add-info-share button {
    width: auto;
    margin-right: 1px;
    padding: 0 24px;
  }
  .mypage-w .add-info-share button.share {
    margin-left: auto;
  }
}

.myp-incnts {
  width: calc(100% - 48px);
  max-width: 600px;
  margin: 0 auto;
  padding: 24px;
}
.myp-incnts select {
  font-size: 16px;
}
.myp-incnts > .tit {
  display: flex;
  align-items: center;
  margin-top: 60px;
  padding-bottom: 13px;
  border-bottom: 1px solid #5539ff;
  color: #5539ff;
  font-size: 16px;
  font-weight: normal;
}
.myp-incnts > .tit:before {
  content: "";
  width: 20px;
  height: 10px;
  margin-right: 8px;
  border-radius: 10px;
  background: #5539ff;
  transform: rotate(-45deg);
}
.myp-incnts > .tit:first-child {
  margin-top: 16px;
}
.myp-incnts h4.stit {
  margin-top: 28px;
  color: rgba(107, 109, 128, 0.4);
  font-size: 14px;
}
.myp-incnts .in-cnts {
  position: relative;
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid rgba(13, 18, 64, 0.05);
}
.myp-incnts .in-cnts textarea {
  height: 200px;
  margin-top: -15px;
}
.myp-incnts .in-cnts input[type=text] {
  background-color: rgba(85, 57, 255, 0.03);
}
.myp-incnts .in-cnts p {
  white-space: pre-wrap;
}
.myp-incnts .in-cnts h4, .myp-incnts .in-cnts h5,
.myp-incnts .in-cnts .tit {
  display: block;
  margin-top: 35px;
  font-size: 16px;
  font-weight: bold;
}
.myp-incnts .in-cnts h4:first-child, .myp-incnts .in-cnts h5:first-child,
.myp-incnts .in-cnts .tit:first-child {
  margin-top: 0;
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts h4 br, .myp-incnts .in-cnts h5 br,
  .myp-incnts .in-cnts .tit br {
    display: none;
  }
}
.myp-incnts .in-cnts .def-form input {
  margin-top: 10px;
}
.myp-incnts .in-cnts .career-w {
  margin-top: 24px;
}
.myp-incnts .in-cnts .career-w input, .myp-incnts .in-cnts .career-w select {
  margin: 0 16px;
}
.myp-incnts .in-cnts .career-w input {
  max-width: 110px;
  text-align: center;
}
.myp-incnts .in-cnts .career-w span {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 16px !important;
}
.myp-incnts .in-cnts .career-w span:first-child {
  margin-top: 0;
}
.myp-incnts .in-cnts .career-w span.sel-w {
  margin-top: 0;
}
.myp-incnts .in-cnts .career-w span.sel-w select {
  margin: 8px 8px 0 0;
}
.myp-incnts .in-cnts .comp-intu {
  position: relative;
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
  line-height: 26px;
}
.myp-incnts .in-cnts .comp-intu > span {
  position: absolute;
  top: -45px;
  right: 0;
  color: rgba(13, 18, 64, 0.6);
}
.myp-incnts .in-cnts .comp-intu > span.err {
  color: #ff4e16;
}
.myp-incnts .in-cnts .comp-intu input[type=text] {
  margin-top: -16px;
}
.myp-incnts .in-cnts .comp-intu > p {
  padding-top: 4px;
  font-size: 14px;
}
.myp-incnts .in-cnts .comp-intu > p.top-r {
  position: absolute;
  top: -50px;
  right: 0;
}
.myp-incnts .in-cnts .comp-intu > p.top-r a {
  color: #5539ff;
  font-size: 12px;
}
.myp-incnts .in-cnts .comp-intu > p.ok {
  color: #5539ff;
}
.myp-incnts .in-cnts .comp-intu > div {
  position: relative;
}
.myp-incnts .in-cnts .comp-intu > div .abs-cnts {
  position: absolute;
  top: 0;
  right: 24px;
  color: #0d1240;
  font-weight: normal;
}
.myp-incnts .in-cnts .toggle-chk {
  margin-top: 16px;
}
.myp-incnts .in-cnts .toggle-chk label {
  display: block;
  position: relative;
  width: 40px;
  height: 24px;
  padding: 0;
  border-radius: 50px;
  background-color: rgba(85, 57, 255, 0.05);
}
.myp-incnts .in-cnts .toggle-chk label > span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.myp-incnts .in-cnts .toggle-chk label:before {
  display: none;
}
.myp-incnts .in-cnts .toggle-chk label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
  background-color: rgba(13, 18, 64, 0.4);
  border-radius: 50%;
  border: none;
  transform: rotate(0);
}
.myp-incnts .in-cnts .toggle-chk input[type=checkbox]:checked + label:after {
  left: auto;
  right: 3px;
  background-color: #5539ff;
}
.myp-incnts .in-cnts .chk-box {
  margin-top: 8px;
  padding: 15px 24px 24px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.myp-incnts .in-cnts .chk-box span {
  display: block;
  margin-top: 9px;
}
.myp-incnts .in-cnts .chk-box span label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 8px 0;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
  box-sizing: border-box;
  color: rgba(85, 57, 255, 0.8);
}
.myp-incnts .in-cnts .chk-box span label:before, .myp-incnts .in-cnts .chk-box span label:after {
  display: none;
}
.myp-incnts .in-cnts .chk-box span label > i {
  margin-right: 5px;
  font-weight: bold;
}
.myp-incnts .in-cnts .chk-box span input:checked + label {
  background: #5539ff;
  color: #fff;
}
.myp-incnts .in-cnts .chk-box.altype2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.myp-incnts .in-cnts .chk-box.altype2 span {
  width: calc(50% - 4px);
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts .chk-box {
    display: flex;
  }
  .myp-incnts .in-cnts .chk-box span {
    flex: 1;
    margin-top: 0;
    margin-right: 9px;
  }
  .myp-incnts .in-cnts .chk-box span:last-child {
    margin-right: 0;
  }
  .myp-incnts .in-cnts .chk-box.altype2 span {
    flex: auto;
    margin: 12px 0 0;
    max-width: calc(25% - 7px);
  }
}
.myp-incnts .in-cnts .sel-form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}
.myp-incnts .in-cnts .sel-form select {
  min-width: 100%;
  padding: 0 24px;
}
.myp-incnts .in-cnts .sel-form select:disabled {
  background-image: none;
  color: rgba(13, 18, 64, 0.4);
}
.myp-incnts .in-cnts .sel-form select:last-child {
  margin-top: 8px;
}
.myp-incnts .in-cnts .sel-form input {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 0 24px;
  box-sizing: border-box;
}
.myp-incnts .in-cnts .sel-form input p {
  padding: 0 8px;
  color: rgba(13, 18, 64, 0.4);
}
.myp-incnts .in-cnts .sel-form > div {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.myp-incnts .in-cnts .sel-form > div select {
  min-width: auto;
  width: calc(50% - 3px);
  margin-top: 0 !important;
  box-sizing: border-box;
}
.myp-incnts .in-cnts .sel-form .fm-box {
  display: flex;
  justify-content: start;
  align-items: top;
  padding: 0 8px 8px !important;
}
.myp-incnts .in-cnts .sel-form .fm-box > span {
  margin-top: 8px;
}
.myp-incnts .in-cnts .sel-form .fm-box .in-sel input {
  min-height: 38px;
  background: none;
  box-sizing: border-box;
}
.myp-incnts .in-cnts .sel-form.type2 {
  display: flex;
}
.myp-incnts .in-cnts .sel-form.type2 select {
  min-width: auto;
  width: 124px;
}
.myp-incnts .in-cnts .sel-form.type2 input {
  flex: 1;
  margin-top: 0;
  margin-left: 10px;
  padding-left: 10px;
}
.myp-incnts .in-cnts .sel-form.type2 > div {
  position: relative;
}
.myp-incnts .in-cnts .sel-form.type2 > div input {
  padding-right: 60px;
  color: #5539ff;
}
.myp-incnts .in-cnts .sel-form.type2 > div button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
}
.myp-incnts .in-cnts .sel-form.type2 > div button:before, .myp-incnts .in-cnts .sel-form.type2 > div button:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 1px);
  width: 2px;
  height: 13px;
  background: #5539ff;
  border-radius: 2px;
}
.myp-incnts .in-cnts .sel-form.type2 > div button:before {
  transform: rotate(45deg);
}
.myp-incnts .in-cnts .sel-form.type2 > div button:after {
  transform: rotate(-45deg);
}
.myp-incnts .in-cnts .sel-form.type2 > div button span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts .sel-form {
    flex-wrap: nowrap;
  }
  .myp-incnts .in-cnts .sel-form select {
    width: 100%;
    min-width: auto;
    margin-right: 8px;
  }
  .myp-incnts .in-cnts .sel-form select:last-child {
    min-width: 392px;
    margin-top: 0;
  }
  .myp-incnts .in-cnts .sel-form .fm-box, .myp-incnts .in-cnts .sel-form input {
    min-height: 56px;
    margin-top: 0;
    padding: 0 8px;
  }
  .myp-incnts .in-cnts .sel-form > div {
    min-width: 60%;
  }
  .myp-incnts .in-cnts .sel-form > div select {
    min-width: auto !important;
  }
  .myp-incnts .in-cnts .sel-form > div + select {
    min-width: 40% !important;
  }
  .myp-incnts .in-cnts .sel-form.othtype {
    display: block;
  }
  .myp-incnts .in-cnts .sel-form.othtype input {
    margin-top: 8px;
    padding: 0 24px;
  }
  .myp-incnts .in-cnts .sel-form.type2 select, .myp-incnts .in-cnts .sel-form.type2 input,
  .myp-incnts .in-cnts .sel-form.type2 > div {
    min-width: auto;
    width: 50%;
    margin-right: 0;
  }
}
.myp-incnts .in-cnts .pjt-more {
  padding-bottom: 10px;
}
.myp-incnts .in-cnts .tag-box {
  margin-top: 16px;
  padding: 24px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.myp-incnts .in-cnts .tag-box > div {
  display: flex;
  flex-wrap: wrap;
}
.myp-incnts .in-cnts .tag-box > div > span {
  display: inline-flex;
  align-items: center;
  margin: 12px 9px 0 0;
  padding: 12px 16px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.myp-incnts .in-cnts .tag-box > div > span.selected {
  background: #5539ff;
  color: #fff;
}
.myp-incnts .in-cnts .tag-box > div > span button {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 8px;
}
.myp-incnts .in-cnts .tag-box > div > span button:before, .myp-incnts .in-cnts .tag-box > div > span button:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 50%;
  width: 1px;
  height: 16px;
  background: #fff;
}
.myp-incnts .in-cnts .tag-box > div > span button:before {
  transform: rotate(45deg);
}
.myp-incnts .in-cnts .tag-box > div > span button:after {
  transform: rotate(-45deg);
}
.myp-incnts .in-cnts .tag-box > div > span button span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.myp-incnts .in-cnts .tag-box > input {
  margin-top: 12px;
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts .tag-box > div > span {
    padding: 8px 24px;
  }
}
.myp-incnts .in-cnts .rcho-w {
  margin-top: 8px;
}
.myp-incnts .in-cnts .rcho-w > div {
  display: flex;
  align-items: center;
  height: 58px;
}
.myp-incnts .in-cnts .rcho-w > div label {
  min-width: 102px;
  padding-top: 3px;
  white-space: nowrap;
  line-height: 12px;
}
.myp-incnts .in-cnts .not-open {
  margin-top: 8px;
}
.myp-incnts .in-cnts .not-open label {
  font-size: 14px;
  color: rgba(13, 18, 64, 0.6);
}
.myp-incnts .in-cnts .key-w input {
  margin-top: 8px;
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts .key-w {
    display: flex;
    justify-content: space-between;
  }
  .myp-incnts .in-cnts .key-w input {
    width: calc(33.3% - 5px);
  }
}
.myp-incnts .in-cnts > dl dt {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
}
.myp-incnts .in-cnts > dl dd {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 4px;
}
.myp-incnts .in-cnts > dl dd > span, .myp-incnts .in-cnts > dl dd > a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  padding: 8px 16px;
  border-radius: 2px;
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
  white-space: nowrap;
  box-sizing: border-box;
  margin: 8px 8px 0 0;
}
.myp-incnts .in-cnts > dl dd > a {
  color: #5539ff;
}
.myp-incnts .in-cnts > dl dd textarea {
  height: 120px;
}
.myp-incnts .in-cnts > dl.form {
  margin-top: 8px;
  padding: 24px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.myp-incnts .in-cnts > dl.form dt {
  padding-top: 24px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
  line-height: 22px;
}
.myp-incnts .in-cnts > dl.form dt br {
  display: none;
}
.myp-incnts .in-cnts > dl.form dt:first-child {
  padding-top: 0;
  border-top: none;
}
.myp-incnts .in-cnts > dl.form dd {
  margin-top: 8px;
  padding: 0;
}
.myp-incnts .in-cnts > dl.form dd input {
  margin-top: 0;
}
.myp-incnts .in-cnts > dl.form dd textarea {
  margin-top: 0;
}
.myp-incnts .in-cnts > dl.form.in-sns dt input {
  width: calc(100% - 64px);
}
.myp-incnts .in-cnts > dl.form.in-sns dt .sns {
  display: flex;
  color: #0d1240;
}
.myp-incnts .in-cnts > dl.form.in-sns dt .sns:before {
  content: "";
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  background: url("../images/icon_sns.svg") no-repeat 0 0;
}
.myp-incnts .in-cnts > dl.form.in-sns dt .sns.drib:before {
  background-position: -30px 0;
}
.myp-incnts .in-cnts > dl.form.in-sns dt .sns.github:before {
  background-position: -60px 0;
}
.myp-incnts .in-cnts > dl.form.in-sns dt .sns.blog:before {
  background-position: -90px 0;
}
.myp-incnts .in-cnts > dl.form.in-sns dt .sns.behance:before {
  background-position: -120px 0;
}
.myp-incnts .in-cnts > dl.form.in-sns dt .sns.insta:before {
  background-position: -150px 0;
}
.myp-incnts .in-cnts > dl.form.in-sns dd {
  flex-wrap: nowrap;
}
.myp-incnts .in-cnts > dl.form.in-sns dd .btn-del {
  position: relative;
  min-width: 56px;
  height: 56px;
  top: 0;
  right: 0;
  margin-left: 8px;
  /* position: relative;
  min-width: 56px;
  height: 56px;
  margin-left: 8px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
  &:before,
  &:after {
      content: '';
      display: block;
      position: absolute;
      top: 21px;
      left: 50%;
      width: 2px;
      height: 13px;
      background-color: rgba(13, 18, 64, 0.6);;
      border-radius: 2px;
  }
  &:before {
      transform: rotate(-45deg);
  }
  &:after {
      transform: rotate(45deg);
  }
  span {
      @include hiddenText;
  } */
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts > dl.form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 4px;
  }
  .myp-incnts .in-cnts > dl.form dt {
    display: flex;
    align-items: center;
    width: 26%;
    min-height: 56px;
    margin: 0;
    padding: 24px 0;
    color: #0d1240;
  }
  .myp-incnts .in-cnts > dl.form dt br {
    display: inline;
  }
  .myp-incnts .in-cnts > dl.form dt:first-child {
    padding-top: 0;
  }
  .myp-incnts .in-cnts > dl.form dd {
    width: 74%;
    min-height: 56px;
    margin: 0;
    padding: 24px 0;
    border-top: 1px solid rgba(85, 57, 255, 0.05);
  }
  .myp-incnts .in-cnts > dl.form dd textarea {
    height: 56px;
    padding: 16px;
  }
  .myp-incnts .in-cnts > dl.form.in-sns dt input {
    width: calc(100% - 8px);
  }
  .myp-incnts .in-cnts > dl.form dt:first-child + dd {
    padding-top: 0;
    border-top: none;
  }
}
.myp-incnts .in-cnts .add-form {
  padding: 0 24px 24px;
  background-color: rgba(85, 57, 255, 0.03);
}
.myp-incnts .in-cnts .add-form .inner {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  border-top: 1px solid rgba(85, 57, 255, 0.03);
}
.myp-incnts .in-cnts .add-form .inner a, .myp-incnts .in-cnts .add-form .inner button {
  display: flex;
  align-items: center;
  height: 44px;
  margin-right: 8px;
  margin-top: 8px;
  padding: 0 12px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #5539ff;
  font-size: 14px;
}
.myp-incnts .in-cnts .add-form .inner a:before, .myp-incnts .in-cnts .add-form .inner button:before {
  content: "";
  width: 18px;
  height: 18px;
  margin-right: 9px;
  background: url("../images/icon_sns_p.svg") no-repeat 0 0;
}
.myp-incnts .in-cnts .add-form .inner a.linkedin:before, .myp-incnts .in-cnts .add-form .inner button.linkedin:before {
  background-position: 0 0;
}
.myp-incnts .in-cnts .add-form .inner a.drib:before, .myp-incnts .in-cnts .add-form .inner button.drib:before {
  background-position: -32px 0;
}
.myp-incnts .in-cnts .add-form .inner a.github:before, .myp-incnts .in-cnts .add-form .inner button.github:before {
  background-position: -64px 0;
}
.myp-incnts .in-cnts .add-form .inner a.blog:before, .myp-incnts .in-cnts .add-form .inner button.blog:before {
  background-position: -96px 0;
}
.myp-incnts .in-cnts .add-form .inner a.behance:before, .myp-incnts .in-cnts .add-form .inner button.behance:before {
  background-position: -128px 0;
}
.myp-incnts .in-cnts .add-form .inner a.insta:before, .myp-incnts .in-cnts .add-form .inner button.insta:before {
  background-position: -160px 0;
}
.myp-incnts .in-cnts .add-form .inner a.plus:before, .myp-incnts .in-cnts .add-form .inner button.plus:before {
  background-position: -187px 0;
}
.myp-incnts .in-cnts > p {
  margin-top: 9px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.myp-incnts .in-cnts > p label {
  font-size: 14px;
  color: rgba(13, 18, 64, 0.6);
}
.myp-incnts .in-cnts .time-choice {
  margin-top: 8px;
}
.myp-incnts .in-cnts .time-choice .in-form select {
  width: 100%;
}
.myp-incnts .in-cnts .time-choice .in-form .time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 0 24px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.myp-incnts .in-cnts .time-choice .in-form .time input {
  margin-right: 10px;
  padding: 0;
  background: none;
}
.myp-incnts .in-cnts .time-choice .in-form .time span {
  font-size: 16px;
  white-space: nowrap;
}
.myp-incnts .in-cnts .time-choice .desc {
  margin-top: 8px;
  padding: 16px 24px;
  border-radius: 2px;
  background-color: rgba(13, 18, 64, 0.02);
}
.myp-incnts .in-cnts .time-choice .desc p {
  color: rgba(13, 18, 64, 0.4);
  font-size: 16px;
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts .time-choice .in-form {
    display: flex;
  }
  .myp-incnts .in-cnts .time-choice .in-form select {
    width: 50%;
  }
  .myp-incnts .in-cnts .time-choice .in-form .time {
    width: 50%;
    margin-top: 0;
    margin-left: 8px;
  }
  .myp-incnts .in-cnts .time-choice .desc p {
    font-size: 14px;
  }
}
.myp-incnts .in-cnts .pjt-history {
  padding-top: 8px;
}
.myp-incnts .in-cnts .pjt-history > div {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 24px;
  background: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  box-sizing: border-box;
}
.myp-incnts .in-cnts .pjt-history > div > p {
  font-weight: bold;
}
.myp-incnts .in-cnts .pjt-history > div > em {
  margin-top: 16px;
  font-weight: bold;
}
.myp-incnts .in-cnts .pjt-history > div > em span {
  padding-right: 4px;
  font-weight: normal;
}
.myp-incnts .in-cnts .pjt-history > div .date {
  margin-top: 16px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 12px;
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts .pjt-history {
    display: flex;
    justify-content: space-between;
  }
  .myp-incnts .in-cnts .pjt-history > div {
    width: calc(50% - 4px);
  }
}
.myp-incnts .in-cnts .btn-calc {
  margin-top: 8px;
}
.myp-incnts .in-cnts .btn-calc button {
  color: #5539ff;
  text-decoration: underline;
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts .btn-calc {
    position: absolute;
    top: 32px;
    right: 0;
    margin-top: 0;
  }
}
.myp-incnts .in-cnts:first-child {
  padding-top: 0;
  border-top: none;
}
.myp-incnts .in-cnts.flx-type2 {
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;
}
.myp-incnts .in-cnts.flx-type2 .inner {
  width: 50%;
  margin-top: 24px;
}
@media (min-width: 786px) {
  .myp-incnts .in-cnts.flx-type2 {
    flex-wrap: nowrap;
  }
}
.myp-incnts h3.tit + .in-cnts {
  padding-top: 0;
  border-top: none;
}
.myp-incnts .pjt-list {
  padding-bottom: 24px;
}
.myp-incnts .pjt-list li {
  margin-top: 28px;
  font-size: 16px;
}
.myp-incnts .pjt-list li em {
  font-weight: bold;
}
.myp-incnts .pjt-list li p {
  margin-top: 10px;
  color: rgba(13, 18, 64, 0.6);
}
.myp-incnts .pjt-list.li3 {
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
@media (min-width: 786px) {
  .myp-incnts .pjt-list {
    padding-bottom: 32px;
  }
  .myp-incnts .pjt-list li {
    font-size: 14px;
  }
  .myp-incnts .pjt-list li p {
    margin-top: 16px;
  }
  .myp-incnts .pjt-list.li2 {
    display: flex;
    flex-wrap: wrap;
  }
  .myp-incnts .pjt-list.li2 li {
    width: 50%;
  }
  .myp-incnts .pjt-list.li2 li.wtype2 {
    width: 100%;
  }
  .myp-incnts .pjt-list.li3 {
    display: flex;
    flex-wrap: wrap;
  }
  .myp-incnts .pjt-list.li3 li {
    width: 33.3%;
  }
}
.myp-incnts .pjt-list .cate {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}
.myp-incnts .pjt-list .cate span {
  margin-top: 8px;
  margin-right: auto;
  padding: 8px 16px;
  border-radius: 2px;
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
  font-size: 16px;
}
.myp-incnts .pjt-list .cate span i {
  padding-right: 5px;
  font-weight: bold;
}
@media (min-width: 786px) {
  .myp-incnts .pjt-list .cate {
    flex-direction: row;
  }
  .myp-incnts .pjt-list .cate span {
    margin-right: 8px;
    padding: 8px 24px;
    font-size: 14px;
  }
}
.myp-incnts .pjt-box {
  overflow: hidden;
  margin-top: 24px;
  border-radius: 4px;
  border: 1px solid rgba(13, 18, 64, 0.05);
}
.myp-incnts .pjt-box .pjt-hgroup {
  padding: 24px 24px 16px;
  background-color: rgba(13, 18, 64, 0.02);
}
.myp-incnts .pjt-box .pjt-hgroup h4 {
  font-size: 16px;
}
.myp-incnts .pjt-box .pjt-hgroup p {
  margin-top: 10px;
}
.myp-incnts .pjt-box .pjt-hgroup p a {
  color: rgba(13, 18, 64, 0.6);
}
.myp-incnts .pjt-box .incnt-w {
  padding: 0 24px 24px;
}
.myp-incnts .pjt-box .incnt-w .in-cnts:first-child {
  padding-top: 0;
}
.myp-incnts .pjt-detail-box {
  margin-top: 16px;
  padding: 35px 24px 24px;
  border-radius: 4px;
  border: solid 1px rgba(13, 18, 64, 0.1);
}
.myp-incnts .pjt-detail-box .in-cnts:first-child {
  margin-top: 0;
}
.myp-incnts .pjt-detail-box .in-cnts .career-w {
  margin-top: 0;
}
.myp-incnts .pjt-add {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid rgba(13, 18, 64, 0.05);
}
.myp-incnts .pjt-add button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #5539ff;
}
.myp-incnts .pjt-add button:before {
  content: "";
  width: 22px;
  height: 22px;
  margin-right: 10px;
  background: url("../images/icon_sns02.svg") no-repeat -79px 50%;
}
.myp-incnts .pjt-add p {
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.4);
  text-align: center;
}
.myp-incnts .cho-rbox {
  margin-top: 16px;
  padding: 24px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.myp-incnts .cho-rbox ul li {
  margin-top: 16px;
}
.myp-incnts .cho-rbox ul li label {
  color: #000;
  font-weight: normal;
}
.myp-incnts .cho-rbox ul li:first-child {
  margin-top: 0;
}
.myp-incnts .cho-input {
  position: relative;
  margin-top: 140px;
}
.myp-incnts .cho-input:before {
  content: "";
  display: block;
  width: 100px;
  height: 1px;
  margin: 0 auto;
  background-color: rgba(13, 18, 64, 0.1);
}
.myp-incnts .cho-input .smtit {
  margin-top: 30px;
}
.myp-incnts .cho-input h5 {
  margin-top: 30px;
  font-weight: bold;
  font-size: 16px;
}
.myp-incnts .cho-input .chk-list {
  margin-left: 10px;
}
.myp-incnts .cho-input .chk-list li {
  margin-top: 25px;
}
.myp-incnts .cho-input .chk-list li label {
  padding-left: 35px;
  color: #0d1240;
  line-height: 22px;
}
.myp-incnts .cho-input .chk-list li label:before {
  width: 22px;
  height: 22px;
  border-width: 2px;
  border-color: #5539ff;
}
.myp-incnts .cho-input .chk-list li label:after {
  top: 6px;
  left: 6px;
}
.myp-incnts .cho-input .chk-list li label > span {
  display: block;
  color: rgba(13, 18, 64, 0.4);
}
@media (min-width: 786px) {
  .myp-incnts .cho-input .chk-list {
    margin-left: 4px;
  }
  .myp-incnts .cho-input .chk-list li {
    margin-top: 11px;
  }
  .myp-incnts .cho-input .chk-list li label {
    padding-left: 22px;
    line-height: 16px;
  }
  .myp-incnts .cho-input .chk-list li label:before {
    width: 16px;
    height: 16px;
    border-width: 1px;
  }
  .myp-incnts .cho-input .chk-list li label:after {
    top: 3px;
    left: 3px;
  }
  .myp-incnts .cho-input .chk-list li label > span {
    display: inline;
  }
}
.myp-incnts .cho-input .in-cnts {
  margin-top: 0;
  border: none;
}
.myp-incnts .cho-input textarea {
  height: 142px;
  padding: 17px 16px;
}
.myp-incnts .cho-input .desc-r {
  margin-top: 8px;
  text-align: right;
}
.myp-incnts .cho-input .desc-r:before {
  content: "*";
  margin-right: 3px;
}
@media (min-width: 786px) {
  .myp-incnts .cho-input .desc-r {
    font-size: 12px;
  }
}
.myp-incnts .btn.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 24px;
  background: #fff;
  box-shadow: 0 -1px 0 0 rgba(13, 18, 64, 0.1);
  box-sizing: border-box;
}
.myp-incnts .btn.sticky .inner {
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
}
.myp-incnts .btn.sticky .inner a, .myp-incnts .btn.sticky .inner button {
  margin-top: 0;
}
.myp-incnts.wtype2 {
  max-width: 500px;
}

.mypage-cnts {
  padding-bottom: 80px;
}
.mypage-cnts:before {
  content: "";
  display: block;
  height: 170px;
  background: #5539ff;
}
.mypage-cnts.intit:before {
  display: none;
}
@media (min-width: 786px) {
  .mypage-cnts:before {
    height: 200px;
  }
}
.mypage-cnts .top-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 48px);
  max-width: 600px;
  margin: -60px auto 0;
  padding: 0 24px;
  text-align: center;
}
.mypage-cnts .top-info .profile .photo {
  position: relative;
  width: 120px;
  margin: 0 auto;
}
.mypage-cnts .top-info .profile .photo > span {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 1px solid rgba(13, 18, 64, 0.05);
  box-sizing: border-box;
}
.mypage-cnts .top-info .profile .photo .photo-up label {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background: #5539ff url("../images/icon_write.svg") no-repeat 50% 50%;
  border: 2px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
}
.mypage-cnts .top-info .profile .photo .photo-up label span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.mypage-cnts .top-info .profile h2 {
  margin-top: 16px;
  font-size: 22px;
  font-weight: normal;
}
.mypage-cnts .top-info .profile .cate {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.mypage-cnts .top-info .profile .cate > span, .mypage-cnts .top-info .profile .cate > a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  margin: 0 4px;
  padding: 0 24px;
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  border-radius: 2px;
}
.mypage-cnts .top-info .profile .cate > span strong, .mypage-cnts .top-info .profile .cate > span em, .mypage-cnts .top-info .profile .cate > a strong, .mypage-cnts .top-info .profile .cate > a em {
  font-weight: bold;
}
.mypage-cnts .top-info .profile .cate > span strong, .mypage-cnts .top-info .profile .cate > a strong {
  color: #5539ff;
}
.mypage-cnts .top-info .profile .cate > a.homp {
  color: #5539ff;
}
.mypage-cnts .top-info .profile .cate > a.homp:after {
  content: "";
  width: 12px;
  height: 12px;
  margin-left: 14px;
  background: url("../images/icon_dirgo.svg") no-repeat 100% 0;
}
.mypage-cnts .top-info .profile .desc {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.6);
  line-height: 26px;
}
.mypage-cnts .top-info .profile .desc a {
  color: #5539ff;
}
@media (min-width: 786px) {
  .mypage-cnts .top-info .profile .desc br {
    display: none;
  }
}
.mypage-cnts .top-info .in-btn {
  width: 100%;
  margin-top: 24px;
}
.mypage-cnts .top-info .in-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #5539ff;
  color: #fff;
}
.mypage-cnts .top-info .in-btn a:before {
  content: "";
  width: 14px;
  height: 14px;
  margin-right: 10px;
  background: url("../images/icon_write.svg") no-repeat 0 0;
}
.mypage-cnts .top-info .in-btn a.sug:before {
  display: none;
}
.mypage-cnts .btn {
  padding-top: 0;
  /* width: calc(100% - 48px);
  max-width: 600px;
  margin: 16px auto 0;
  padding: 0 24px; */
}
.mypage-cnts .btn a, .mypage-cnts .btn button {
  margin-left: 8px;
}
.mypage-cnts .btn a:first-child, .mypage-cnts .btn button:first-child {
  margin-left: 0;
}
@media (min-width: 786px) {
  .mypage-cnts .btn {
    padding: 0;
  }
}
.mypage-cnts .myp-incnts + .btn {
  max-width: 648px;
  margin: 0 auto;
  padding: 0 24px;
}
.mypage-cnts .conts-w {
  width: calc(100% - 48px);
  max-width: 600px;
  margin: 0 auto;
}
.mypage-cnts .conts-w h3 {
  margin-top: 65px;
}
.mypage-cnts .conts-w .agree {
  padding-top: 39px;
}
.mypage-cnts .conts-w .btn {
  width: 100%;
  margin-top: 40px;
  padding: 0;
}
.mypage-cnts .modify-prog {
  padding: 40px 0 20px;
  background: #5539ff;
  color: #fff;
  text-align: center;
}
.mypage-cnts .modify-prog p {
  padding: 0 24px;
  font-size: 22px;
  line-height: 34px;
}
.mypage-cnts.modify {
  padding-top: 72px;
}
.mypage-cnts.modify:before {
  display: none;
}
.mypage-cnts.modify .top-info {
  margin-top: 24px;
}
.mypage-cnts.modify .top-info h2 {
  font-weight: bold;
  font-size: 16px;
}

.step {
  overflow: hidden;
  overflow-x: auto;
  width: 100vw;
  margin-top: 24px;
  padding: 0 24px;
  box-sizing: border-box;
  transition: all 0.5s;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.step::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.step ul {
  display: flex;
}
.step ul li {
  padding-right: 1px;
}
.step ul li a {
  display: block;
  position: relative;
  height: 42px;
  padding: 0 16px;
  background-color: rgba(255, 255, 255, 0.1);
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.6);
  line-height: 42px;
}
.step ul li a i {
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 6px;
  height: 6px;
  background: #ff4e16;
  border-radius: 50%;
}
.step ul li a i span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.step ul li:first-child a {
  border-radius: 5px 0 0 5px;
}
.step ul li:last-child a {
  border-radius: 0 5px 5px 0;
}
.step ul li.selected a {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-weight: bold;
}
@media (min-width: 786px) {
  .step ul {
    justify-content: center;
  }
  .step ul li a {
    padding: 0 32px;
  }
}

.email-inc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  min-height: 100%;
  margin: 0 auto;
  text-align: center;
}
.email-inc h1 {
  font-size: 32px;
  font-weight: normal;
}
.email-inc h1:after {
  content: "";
  display: block;
  width: 196px;
  height: 196px;
  margin-top: 18px;
  background: url("../images/img_email_inc.png") no-repeat 0 0;
  background-size: auto 100%;
}
.email-inc strong {
  display: block;
  margin-top: 18px;
  font-size: 22px;
  font-weight: normal;
}
.email-inc p {
  margin-top: 16px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  line-height: 22px;
}
.email-inc p em {
  font-weight: bold;
}
.email-inc .btn {
  width: 100%;
}
.email-inc.end h1:after {
  background-position: 100% 0;
}

.mem-price {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 24px;
  text-align: center;
}
.mem-price .info-box {
  margin-bottom: 8px;
  padding: 24px;
  border-radius: 2px;
  border: solid 1px rgba(13, 18, 64, 0.05);
}
.mem-price .info-box > strong {
  font-size: 24px;
  line-height: 34px;
}
.mem-price .info-box > strong b {
  color: #5539ff;
}
.mem-price .info-box > p {
  margin-top: 16px;
  color: rgba(13, 18, 64, 0.6);
  line-height: 26px;
}
.mem-price .info-box > p.desc {
  margin-top: 8px;
  font-size: 14px;
  line-height: 22px;
}
.mem-price .info-box .in-btn {
  margin-top: 24px;
}
.mem-price .info-box .in-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #5539ff;
  color: #fff;
  font-size: 14px;
}
.mem-price .info-box .in-btn a.disb {
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.4);
}
.mem-price > .link a {
  color: #5539ff;
  font-size: 14px;
  text-decoration: underline;
}
.mem-price .update-cnts {
  margin-top: 40px;
}
.mem-price .update-cnts h3 {
  padding-bottom: 18px;
  font-size: 16px;
  text-align: left;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
.mem-price .update-cnts .viewport {
  overflow: hidden;
  overflow-x: auto;
  width: 100vw;
  margin-top: 24px;
  margin-left: -24px;
  padding-left: 24px;
  box-sizing: border-box;
}
.mem-price .update-cnts .viewport table {
  width: 600px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.mem-price .update-cnts .viewport table th {
  height: 48px;
  background-color: rgba(13, 18, 64, 0.05);
}
.mem-price .update-cnts .viewport table td {
  height: 68px;
  background-color: rgba(13, 18, 64, 0.02);
}
.mem-price .update-cnts .no-update {
  margin-top: 24px;
  padding: 20px 0;
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.4);
  font-size: 16px;
  text-align: center;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.no-data:before {
  content: "";
  width: 200px;
  height: 200px;
  background: url("../images/no_data.png") no-repeat 0 0;
  background-size: cover;
}
.no-data p {
  margin-top: 24px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 16px;
}
@media (min-width: 786px) {
  .no-data {
    margin-top: 24px;
  }
  .no-data p {
    font-size: 22px;
  }
}

.project-recruit {
  max-width: 800px;
  padding: 40px 20px;
  margin: 0 auto;
}
.project-recruit .hgroup > h3 {
  font-size: 16px;
}
.project-recruit .hgroup .prog {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  font-size: 16px;
}
.project-recruit .hgroup .prog > em {
  color: #5539ff;
}
.project-recruit .hgroup .prog > em span:before {
  content: "·";
  margin: 0 5px;
}
.project-recruit .hgroup .prog > em span:first-child:before {
  display: none;
}
.project-recruit .hgroup .prog > em span i {
  display: none;
}
.project-recruit .hgroup .prog > p {
  color: rgba(13, 18, 64, 0.4);
}
.project-recruit .hgroup .prog > p span {
  margin-left: 5px;
}
.project-recruit .hgroup .prog > p:before {
  content: "|";
  margin: 0 10px;
}
.project-recruit > ul {
  margin-top: 16px;
  font-size: 14px;
}
.project-recruit > ul li {
  margin-top: 16px;
}
.project-recruit > ul li a {
  display: block;
  border-radius: 4px;
  border: solid 1px rgba(85, 57, 255, 0.05);
  background-color: rgba(85, 57, 255, 0.03);
}
.project-recruit > ul li a .info-w {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.project-recruit > ul li a .info-w .info {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  color: rgba(13, 18, 64, 0.6);
}
.project-recruit > ul li a .info-w .info strong {
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.project-recruit > ul li a .info-w .info span {
  margin-top: 5px;
}
.project-recruit > ul li a .info-w .not {
  padding: 8px 16px;
  border-radius: 2px;
  background-color: rgba(255, 78, 22, 0.05);
  color: #ff4e16;
}
.project-recruit > ul li a .info-w .not i {
  display: none;
}
.project-recruit > ul li a .prog-condi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  padding: 16px 24px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
}
.project-recruit > ul li a .prog-condi .nick {
  overflow: hidden;
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
}
.project-recruit > ul li a .prog-condi .nick span {
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.project-recruit > ul li a .prog-condi .nick .info {
  position: relative;
  padding-left: 16px;
}
.project-recruit > ul li a .prog-condi .nick .info em {
  display: block;
  width: 100%;
  font-weight: bold;
}
.project-recruit > ul li a .prog-condi .condi {
  margin-left: auto;
}
.project-recruit > ul li a .prog-condi .condi span {
  display: block;
  padding: 8px 16px;
  border-radius: 2px;
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
  white-space: nowrap;
  box-sizing: border-box;
}
.project-recruit > ul li a .prog-condi .condi span.ing {
  color: #5539ff;
}
.project-recruit > ul li a .prog-condi .condi span.end {
  color: rgba(13, 18, 64, 0.4);
}
.project-recruit > ul li a.end {
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
}
.project-recruit .paging {
  margin-top: 40px;
}
@media (min-width: 786px) {
  .project-recruit .hgroup {
    display: flex;
    justify-content: space-between;
  }
  .project-recruit .hgroup .prog {
    margin-top: 0;
  }
  .project-recruit .hgroup .prog > em span i {
    display: inline;
  }
  .project-recruit .hgroup .prog > em span.end {
    color: rgba(13, 18, 64, 0.4);
  }
  .project-recruit > ul li a {
    display: flex;
    position: relative;
  }
  .project-recruit > ul li a .info-w {
    min-width: 27%;
    width: 27%;
  }
  .project-recruit > ul li a .info-w .not {
    position: absolute;
    right: 152px;
    font-size: 12px;
  }
  .project-recruit > ul li a .info-w .not i {
    display: inline;
  }
  .project-recruit > ul li a .prog-condi {
    width: 73%;
    border-top: none;
    box-sizing: border-box;
  }
  .project-recruit > ul li a .prog-condi .nick .info {
    width: calc(100% - 200px);
  }
  .project-recruit > ul li a .prog-condi .nick .info em {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .project-recruit > ul li a .prog-condi .condi {
    position: absolute;
    right: 24px;
  }
  .project-recruit > ul li a .prog-condi .condi span {
    width: 120px;
    font-size: 12px;
    text-align: center;
  }
}

.total-price {
  margin-top: 16px;
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
}
.total-price .price-info {
  display: flex;
  padding: 17px 24px;
}
.total-price .price-info > div {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.total-price .price-info > div i {
  font-size: 12px;
}
.total-price .price-info > div em {
  margin-top: 4px;
  font-weight: bold;
  line-height: 26px;
}
.total-price .price-info > div em span {
  font-weight: normal;
}
.total-price > p {
  padding: 12px 0;
  text-align: center;
  font-size: 14px;
}
.total-price > p a {
  color: rgba(13, 18, 64, 0.6);
}
.total-price > p a:after {
  content: ">";
  margin-left: 10px;
}
.total-price.type2 {
  text-align: center;
}
@media (min-width: 786px) {
  .total-price {
    display: flex;
  }
  .total-price .price-info {
    width: 60%;
  }
  .total-price .price-info > div em {
    font-size: 16px;
  }
  .total-price > p {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: auto;
    padding-right: 24px;
  }
  .total-price.type2 {
    display: block;
    padding-bottom: 16px;
  }
  .total-price.type2 .price-info {
    display: block;
    width: auto;
  }
  .total-price.type2 .price-info > div {
    display: block;
    width: auto;
    font-size: 16px;
  }
  .total-price.type2 > p {
    display: block;
    padding: 0;
  }
}

.admin-sp {
  margin-top: 24px;
  border-top: 1px solid rgba(13, 18, 64, 0.05);
}
.admin-sp .hgroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.admin-sp .hgroup h3 {
  font-size: 16px;
}
.admin-sp .hgroup > div {
  display: flex;
  align-items: center;
}
.admin-sp .hgroup > div > p {
  padding-right: 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
}
.admin-sp .hgroup > div > p.end {
  color: rgba(13, 18, 64, 0.4);
}
.admin-sp .hgroup > div > span {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
}
.admin-sp .hgroup > div > span.cel {
  background-color: rgba(255, 78, 22, 0.05);
  color: #ff4e16;
  font-size: 12px;
}
.admin-sp .spt-wrap .inbox {
  position: relative;
  margin-top: 16px;
  border-radius: 4px;
  border: solid 1px rgba(85, 57, 255, 0.05);
  background-color: rgba(85, 57, 255, 0.03);
}
.admin-sp .spt-wrap .inbox input {
  height: 22px;
  padding: 0;
  background: none;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  text-decoration: underline;
}
.admin-sp .spt-wrap .inbox .noti {
  display: none;
  position: absolute;
  top: 22px;
  left: 0;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px rgba(13, 18, 64, 0.05);
  background: #fff;
  color: #ff4e16;
  font-size: 12px;
  white-space: nowrap;
}
.admin-sp .spt-wrap .inbox input:focus ~ .noti {
  display: block;
}
.admin-sp .spt-wrap .inbox .prog-type {
  display: none;
}
.admin-sp .spt-wrap .inbox > strong {
  display: block;
  padding: 22px 24px 16px;
  font-size: 14px;
  white-space: nowrap;
}
.admin-sp .spt-wrap .inbox .date {
  display: flex;
  padding: 16px 24px;
}
.admin-sp .spt-wrap .inbox .date > div {
  position: relative;
  width: 50%;
  padding-right: 15px;
}
.admin-sp .spt-wrap .inbox .date > div .tit, .admin-sp .spt-wrap .inbox .date > div > label {
  display: block;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
}
.admin-sp .spt-wrap .inbox .date > div > span {
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.admin-sp .spt-wrap .inbox .date > div > em {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(13, 18, 64, 0.6);
}
.admin-sp .spt-wrap .inbox .date > div .noti {
  top: 35px;
}
.admin-sp .spt-wrap .inbox .sp-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 24px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
}
.admin-sp .spt-wrap .inbox .sp-price .int-price {
  display: flex;
  align-items: center;
  position: relative;
  height: 22px;
}
.admin-sp .spt-wrap .inbox .sp-price .int-price input {
  width: 70px;
  margin-right: 3px;
  text-align: right;
}
.admin-sp .spt-wrap .inbox .sp-price .int-price span {
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.admin-sp .spt-wrap .inbox .sp-price .int-price i {
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.admin-sp .spt-wrap .inbox .condi > span {
  display: block;
  min-width: 94px;
  max-width: 94px;
  margin: 0 auto;
  padding: 8px 12px;
  background: #5539ff;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 2px;
  box-sizing: border-box;
  text-align: center;
}
.admin-sp .spt-wrap .inbox .condi > span.sett-ok {
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
  text-align: center;
  color: rgba(13, 18, 64, 0.6);
}
.admin-sp .spt-wrap .inbox .condi > span.cel {
  background: #0d1240;
}
.admin-sp .spt-wrap .inbox .condi > p {
  margin-top: 6px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 10px;
}
.admin-sp .spt-wrap .inbox.end {
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
}
.admin-sp .weekly-adj {
  margin-top: 16px;
  color: rgba(13, 18, 64, 0.4);
}
.admin-sp .weekly-adj .pre-str {
  padding: 10px 0 10px 24px;
  color: #0d1240;
}
.admin-sp .weekly-adj .pre-str em {
  padding: 0 5px;
  color: #5539ff;
}
.admin-sp .weekly-adj .w-inner {
  display: flex;
  flex-direction: column;
  min-height: 130px;
  border-top: solid 1px rgba(85, 57, 255, 0.05);
  background: rgba(85, 57, 255, 0.03);
}
.admin-sp .weekly-adj .w-inner:first-child {
  border: none;
}
.admin-sp .weekly-adj .w-inner .h-w {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(85, 57, 255, 0.05);
}
.admin-sp .weekly-adj .w-inner .h-w strong {
  display: block;
  color: #0d1240;
  font-weight: bold;
}
.admin-sp .weekly-adj .w-inner .h-w span {
  display: block;
}
.admin-sp .weekly-adj .w-inner .condi {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 39px 0 24px;
}
.admin-sp .weekly-adj .w-inner .condi .w-price {
  color: rgba(13, 18, 64, 0.6);
}
.admin-sp .weekly-adj .w-inner .condi .w-price i {
  display: block;
  font-size: 12px;
}
.admin-sp .weekly-adj .w-inner .condi .w-price em {
  display: block;
  margin-top: 5px;
  font-weight: bold;
}
.admin-sp .weekly-adj .w-inner .condi p.tw {
  font-size: 12px;
}
.admin-sp .weekly-adj .w-inner .condi p.tw em {
  padding: 0 3px;
  color: #0d1240;
}
.admin-sp .weekly-adj .w-inner .condi > span {
  display: block;
  min-width: 95px;
  margin: auto 0 auto auto;
  padding: 8px 17px;
  border: solid 1px rgba(13, 18, 64, 0.05);
  background-color: rgba(13, 18, 64, 0.02);
  font-size: 12px;
  border-radius: 2px;
  box-sizing: border-box;
  text-align: center;
}
.admin-sp .weekly-adj .w-inner .condi > span i {
  display: block;
  margin-bottom: 4px;
}
.admin-sp .weekly-adj .w-inner .condi > span a {
  display: block;
  margin-top: 4px;
  color: rgba(13, 18, 64, 0.4);
  text-decoration: underline;
}
.admin-sp .weekly-adj .w-inner .condi > span.p-type {
  background: #5539ff;
  color: #fff;
}
.admin-sp .weekly-adj .w-inner .condi > span.ing {
  background-color: rgba(13, 18, 64, 0.05);
}
.admin-sp .weekly-adj .w-inner .condi > button {
  position: absolute;
  right: 9px;
  width: 30px;
  height: 30px;
}
.admin-sp .weekly-adj .w-inner .condi > button:before {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 7px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid blue;
}
.admin-sp .weekly-adj .w-inner .condi > button span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.admin-sp .weekly-adj .w-inner.end {
  background-color: rgba(13, 18, 64, 0.02);
}
@media (min-width: 786px) {
  .admin-sp {
    margin-top: 32px;
  }
  .admin-sp .spt-wrap .inbox {
    display: flex;
    align-items: center;
    min-height: 82px;
    padding: 19px 24px;
    box-sizing: border-box;
  }
  .admin-sp .spt-wrap .inbox .prog-type {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    height: 34px;
    border-radius: 2px;
    background-color: rgba(13, 18, 64, 0.02);
    font-size: 12px;
  }
  .admin-sp .spt-wrap .inbox .prog-type.ing {
    color: #5539ff;
  }
  .admin-sp .spt-wrap .inbox .prog-type.end {
    color: rgba(13, 18, 64, 0.4);
  }
  .admin-sp .spt-wrap .inbox > strong {
    padding: 0 24px;
  }
  .admin-sp .spt-wrap .inbox .date {
    padding: 0;
  }
  .admin-sp .spt-wrap .inbox .date > div {
    width: 96px;
    margin: 0 24px;
  }
  .admin-sp .spt-wrap .inbox .date > div > em {
    display: block;
    margin-top: 4px;
  }
  .admin-sp .spt-wrap .inbox .sp-price {
    flex: auto;
    padding: 0;
    border: none;
  }
  .admin-sp .spt-wrap .inbox .sp-price .int-price {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 115px;
    height: auto;
  }
  .admin-sp .spt-wrap .inbox .sp-price .int-price > i {
    min-width: 100%;
    color: rgba(13, 18, 64, 0.6);
    font-size: 12px;
  }
  .admin-sp .spt-wrap .inbox .sp-price .int-price .noti {
    top: calc(50% + 10px);
  }
  .admin-sp .spt-wrap .inbox .sp-price .int-price > i + .noti {
    top: calc(50% + 3px);
  }
  .admin-sp .weekly-adj .w-inner {
    flex-direction: row;
    align-items: center;
    min-height: 82px;
  }
  .admin-sp .weekly-adj .w-inner .h-w {
    display: block;
    border: none;
  }
  .admin-sp .weekly-adj .w-inner .h-w span {
    margin-top: 5px;
  }
}

.settle-wrap {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 24px;
}
.settle-wrap .pjt-info {
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
  font-size: 14px;
}
.settle-wrap .pjt-info .in-btn {
  display: flex;
}
.settle-wrap .pjt-info .in-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
  height: 48px;
  padding: 0 24px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
  text-align: center;
  box-sizing: border-box;
}
.settle-wrap .pjt-info .in-btn a.btn-pf {
  max-width: 77px;
  white-space: nowrap;
}
.settle-wrap .pjt-info .in-btn a.commu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background: #5539ff;
  color: #fff;
}
.settle-wrap .pjt-info .in-btn a.commu:before {
  content: "";
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background: url("../images/icon_commu.svg") no-repeat 0 0;
}
.settle-wrap .pjt-info .profile .nick {
  display: flex;
  align-items: center;
  padding: 24px;
}
.settle-wrap .pjt-info .profile .nick > span {
  min-width: 64px;
  height: 64px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.settle-wrap .pjt-info .profile .nick .info {
  overflow: hidden;
  flex: 1;
  margin-left: 16px;
}
.settle-wrap .pjt-info .profile .nick .info > div {
  display: flex;
  /* strong {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  } */
}
.settle-wrap .pjt-info .profile .nick .info > div i {
  margin-right: 3px;
  font-weight: bold;
}
.settle-wrap .pjt-info .profile .nick .info > div span {
  margin-top: 4px;
  color: rgba(13, 18, 64, 0.6);
}
.settle-wrap .pjt-info .profile .nick .info > div.cname {
  font-weight: bold;
}
.settle-wrap .pjt-info .profile .nick .info p {
  margin-top: 4px;
  color: rgba(13, 18, 64, 0.4);
}
.settle-wrap .pjt-info .profile .part {
  padding: 15px 24px 24px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
  color: rgba(13, 18, 64, 0.6);
  line-height: 22px;
}
.settle-wrap .pjt-info .profile .part > p {
  overflow: hidden;
  color: rgba(13, 18, 64, 0.6);
  white-space: nowrap;
  text-overflow: ellipsis;
}
.settle-wrap .pjt-info .profile .part > span {
  color: rgba(13, 18, 64, 0.4);
}
.settle-wrap .pjt-info .profile .part > em {
  font-weight: bold;
}
.settle-wrap .pjt-info .profile .part .in-btn {
  margin-top: 16px;
}
.settle-wrap .pjt-info .price {
  padding: 24px 24px 0;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
}
.settle-wrap .pjt-info .price > p {
  color: rgba(13, 18, 64, 0.4);
}
.settle-wrap .pjt-info .price table {
  margin-top: 8px;
}
.settle-wrap .pjt-info .price table th, .settle-wrap .pjt-info .price table td {
  padding: 8px 0;
}
.settle-wrap .pjt-info .price table th {
  text-align: left;
  font-weight: bold;
}
.settle-wrap .pjt-info .price table td {
  color: rgba(13, 18, 64, 0.6);
  text-align: right;
}
.settle-wrap .pjt-info .price .in-btn {
  width: 100%;
  margin-top: 8px;
  margin-left: -24px;
  padding: 24px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
}
@media (min-width: 786px) {
  .settle-wrap .pjt-info {
    display: flex;
    padding: 0;
  }
  .settle-wrap .pjt-info > div {
    min-width: 50%;
    box-sizing: border-box;
  }
  .settle-wrap .pjt-info > div.price {
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    border-top: none;
    border-left: 1px solid rgba(85, 57, 255, 0.05);
  }
  .settle-wrap .pjt-info > div.price .in-btn {
    margin-top: auto;
  }
}

.adj-w {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
@media (min-width: 786px) {
  .adj-w {
    padding-bottom: 32px;
    margin-bottom: 32px;
  }
}

.cnts-hgroup {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
.cnts-hgroup h3 {
  display: none;
}
.cnts-hgroup .tab ul {
  display: flex;
  align-items: center;
}
.cnts-hgroup .tab ul li {
  color: rgba(13, 18, 64, 0.4);
}
.cnts-hgroup .tab ul li a {
  color: rgba(13, 18, 64, 0.4);
}
.cnts-hgroup .tab ul li:before {
  content: "·";
  margin: 0 5px;
}
.cnts-hgroup .tab ul li:first-child:before {
  display: none;
}
.cnts-hgroup .tab ul li.selected a {
  color: #5539ff;
}
@media (min-width: 786px) {
  .cnts-hgroup {
    display: flex;
  }
  .cnts-hgroup h3 {
    display: block;
    font-size: 14px;
  }
  .cnts-hgroup .tab {
    margin-left: auto;
    font-size: 14px;
  }
}

.cnts-hgroup2 {
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
.cnts-hgroup2 .tab ul {
  display: flex;
  align-items: center;
}
.cnts-hgroup2 .tab ul li {
  color: rgba(13, 18, 64, 0.4);
}
.cnts-hgroup2 .tab ul li a {
  color: rgba(13, 18, 64, 0.4);
}
.cnts-hgroup2 .tab ul li:before {
  content: "·";
  margin: 0 5px;
}
.cnts-hgroup2 .tab ul li:first-child:before {
  display: none;
}
.cnts-hgroup2 .tab ul li.selected a {
  color: #5539ff;
}
.cnts-hgroup2 .tab ul {
  display: flex;
  align-items: center;
}
.cnts-hgroup2 .tab ul li {
  color: rgba(13, 18, 64, 0.4);
}
.cnts-hgroup2 .tab ul li a {
  color: rgba(13, 18, 64, 0.4);
}
.cnts-hgroup2 .tab ul li:before {
  content: "·";
  margin: 0 5px;
}
.cnts-hgroup2 .tab ul li:first-child:before {
  display: none;
}
.cnts-hgroup2 .tab ul li.selected a {
  color: #5539ff;
}

.recu-status {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 24px;
}
.recu-status .status-detail .inbox {
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  padding-bottom: 24px;
  border-radius: 4px;
  border: solid 1px rgba(85, 57, 255, 0.05);
  background-color: rgba(85, 57, 255, 0.03);
}
.recu-status .status-detail .inbox .tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background: #fff;
  border-radius: 4px 4px 0 0;
}
.recu-status .status-detail .inbox .tit h4 {
  overflow: hidden;
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.recu-status .status-detail .inbox .tit span {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  height: 34px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #5539ff;
  font-size: 12px;
  font-weight: bold;
}
.recu-status .status-detail .inbox .tit span.ing {
  background-color: rgba(85, 57, 255, 0.1);
}
.recu-status .status-detail .inbox .tit span.rej {
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.4);
}
.recu-status .status-detail .inbox .tit span.ok {
  background-color: #e0fe52;
  color: #0d1240;
}
.recu-status .status-detail .inbox .profile {
  display: flex;
  align-items: center;
  padding: 16px 24px;
}
.recu-status .status-detail .inbox .profile .photo {
  min-width: 64px;
  height: 64px;
  margin-right: 16px;
  background-size: cover;
}
.recu-status .status-detail .inbox .profile .info {
  flex: 1;
  width: calc(100% - 110px);
  font-size: 14px;
}
.recu-status .status-detail .inbox .profile .info .name {
  display: flex;
  flex-direction: column;
}
.recu-status .status-detail .inbox .profile .info .name span {
  margin-top: 4px;
  color: rgba(13, 18, 64, 0.6);
}
.recu-status .status-detail .inbox .profile .info p {
  overflow: hidden;
  width: 100%;
  margin-top: 4px;
  margin-right: auto;
  white-space: nowrap;
  color: rgba(13, 18, 64, 0.4);
  text-overflow: ellipsis;
}
.recu-status .status-detail .inbox .profile .btn-view {
  margin-left: auto;
}
.recu-status .status-detail .inbox .profile .btn-view a {
  display: block;
  width: 32px;
  height: 32px;
  background: url("../images/icon_arrow02.svg") no-repeat 100% 0;
}
.recu-status .status-detail .inbox .profile .btn-view a span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.recu-status .status-detail .inbox .profile .btn-view a.suj {
  width: auto;
  padding-right: 32px;
  color: #5539ff;
  font-size: 12px;
  font-weight: bold;
  text-indent: -9999px;
  line-height: 32px;
}
.recu-status .status-detail .inbox .agr-info {
  position: relative;
  padding: 16px 24px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
}
.recu-status .status-detail .inbox .agr-info table {
  font-size: 14px;
}
.recu-status .status-detail .inbox .agr-info table caption {
  overflow: visible;
  position: static;
  font-size: 14px;
  font-weight: bold;
  color: rgba(13, 18, 64, 0.6);
  text-indent: 0;
  line-height: 22px;
}
.recu-status .status-detail .inbox .agr-info table th, .recu-status .status-detail .inbox .agr-info table td {
  padding: 7px 0;
  color: rgba(13, 18, 64, 0.6);
}
.recu-status .status-detail .inbox .agr-info table th {
  text-align: left;
}
.recu-status .status-detail .inbox .agr-info table td {
  font-weight: bold;
  text-align: right;
}
.recu-status .status-detail .inbox .agr-info .btn-sug {
  position: absolute;
  top: 16px;
  right: 24px;
}
.recu-status .status-detail .inbox .agr-info .btn-sug a, .recu-status .status-detail .inbox .agr-info .btn-sug button {
  color: #5539ff;
  font-size: 12px;
  text-decoration: underline;
}
.recu-status .status-detail .inbox .desc-box {
  padding: 16px 24px;
  border-top: 1px solid rgba(85, 57, 255, 0.05);
  color: rgba(13, 18, 64, 0.4);
  font-size: 12px;
  line-height: 18px;
}
.recu-status .status-detail .inbox .desc-box .ans {
  overflow: auto;
  height: 88px;
  margin: 8px 0 0;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid rgba(13, 18, 64, 0.05);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
}
.recu-status .status-detail .inbox .desc-box .desc {
  margin-top: 8px;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: rgba(13, 18, 64, 0.02);
}
.recu-status .status-detail .inbox .desc-box .desc p {
  color: rgba(13, 18, 64, 0.6);
}
.recu-status .status-detail .inbox .in-btn {
  display: flex;
  margin-top: auto;
  padding: 0 24px;
}
.recu-status .status-detail .inbox .in-btn * {
  margin: 0 4px;
}
.recu-status .status-detail .inbox .in-btn *:first-child {
  margin-left: 0;
}
.recu-status .status-detail .inbox .in-btn *:last-child {
  margin-right: 0;
}
.recu-status .status-detail .inbox .in-btn > a {
  position: relative;
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 2px;
  background: rgba(85, 57, 255, 0.05) url("../images/icon_cmt.svg") no-repeat 50% 50%;
}
.recu-status .status-detail .inbox .in-btn > a span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.recu-status .status-detail .inbox .in-btn > a i {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 6px;
  height: 6px;
  background: #ff4e16;
  border-radius: 50%;
}
.recu-status .status-detail .inbox .in-btn p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 48px;
  border-radius: 2px;
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.recu-status .status-detail .inbox .in-btn p a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: 0;
  background-color: #f2efff;
  color: #5539ff;
}
.recu-status .status-detail .inbox .in-btn p a:hover {
  background-color: #5539ff;
  color: #fff;
}
.recu-status .status-detail .inbox .in-btn p.drop {
  max-width: 88px;
  background: #f2efff;
  color: #5539ff;
}
.recu-status .status-detail .inbox .in-btn p.actv {
  position: relative;
  background: #5539ff;
  color: #fff;
}
.recu-status .status-detail .inbox .in-btn p.actv a {
  color: #fff;
  background: #5539ff;
}
.recu-status .status-detail .inbox .in-btn p.actv > span {
  position: absolute;
  top: 56px;
  padding: 11px 24px;
  background: #0d1240;
  border-radius: 4px;
  color: #fff;
}
.recu-status .status-detail .inbox .in-btn p.actv > span:before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  background: #0d1240;
  transform: rotate(45deg);
}
@media (min-width: 786px) {
  .recu-status .status-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .recu-status .status-detail .inbox {
    width: calc(50% - 12px);
  }
  .recu-status .status-detail .inbox .profile .btn-view a.suj {
    text-indent: 0;
  }
  .recu-status .status-detail .inbox .desc-box .ans {
    height: 56px;
    font-size: 12px;
  }
}
.recu-status .paging {
  margin-top: 65px;
}
@media (min-width: 786px) {
  .recu-status {
    padding-top: 64px;
  }
}

.pjt-detail {
  max-width: 800px;
  margin: 40px auto 0;
  padding: 0 24px;
}
.pjt-detail .company-info {
  display: flex;
  align-items: center;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid rgba(85, 57, 255, 0.05);
  background-color: rgba(85, 57, 255, 0.03);
}
.pjt-detail .company-info .logo {
  min-width: 64px;
  height: 64px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 50%;
}
.pjt-detail .company-info strong {
  padding-left: 16px;
  font-size: 14px;
}
.pjt-detail .company-info .btn-view {
  margin-left: auto;
}
.pjt-detail .company-info .btn-view a {
  display: block;
  width: 32px;
  height: 32px;
  background: url("../images/icon_arrow02.svg") no-repeat 0 0;
}
.pjt-detail .company-info .btn-view a span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.pjt-detail .inner .pjt-info {
  padding: 0;
}
.pjt-detail .inner .pjt-info .agree-cnts {
  margin-top: 24px;
  line-height: 26px;
}
.pjt-detail .inner .pjt-info .agree-cnts > p {
  color: rgba(13, 18, 64, 0.6);
}
.pjt-detail .inner .pjt-info .agree-cnts > p em {
  color: #5539ff;
}
.pjt-detail .inner .pjt-info .agree-cnts > div {
  margin-top: 24px;
  padding: 13px 20px;
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
  text-align: center;
}
.pjt-detail .inner aside {
  margin-top: 40px;
}
.pjt-detail .inner aside .send-message {
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(13, 18, 64, 0.02);
  font-size: 14px;
}
.pjt-detail .inner aside .send-message p {
  color: rgba(13, 18, 64, 0.6);
  line-height: 22px;
}
.pjt-detail .inner aside .send-message a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin-top: 10px;
  border: 1px solid #5539ff;
  color: #5539ff;
  background: #fff;
}
.pjt-detail .inner aside .send-message a:before {
  content: "";
  width: 21px;
  height: 20px;
  margin-right: 10px;
  background: url("../images/icon_cmt.svg") no-repeat 0 0;
  background-size: cover;
}
.pjt-detail .inner aside .condi-box {
  overflow: hidden;
  margin-top: 17px;
  border-radius: 4px;
  background-color: rgba(85, 57, 255, 0.03);
}
.pjt-detail .inner aside .condi-box h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  background-color: rgba(85, 57, 255, 0.03);
  color: #5539ff;
  font-size: 14px;
}
.pjt-detail .inner aside .condi-box h4.ing {
  background-color: rgba(85, 57, 255, 0.1);
}
.pjt-detail .inner aside .condi-box h4.reg {
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.4);
}
.pjt-detail .inner aside .condi-box h4.ok {
  background: #e0fe52;
  color: #0d1240;
}
.pjt-detail .inner aside .condi-box .in-cnts {
  padding: 16px 24px;
}
.pjt-detail .inner aside .condi-box .in-cnts > strong {
  display: block;
  font-size: 16px;
  text-align: center;
}
.pjt-detail .inner aside .condi-box .in-cnts > p {
  margin-top: 16px;
  color: #000;
  font-size: 14px;
  line-height: 22px;
}
.pjt-detail .inner aside .condi-box .in-cnts > p em {
  font-weight: bold;
}
.pjt-detail .inner aside .condi-box .in-cnts ul.chk-li li {
  margin-top: 16px;
}
.pjt-detail .inner aside .condi-box .in-cnts ul.chk-li li label {
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  line-height: 22px;
}
.pjt-detail .inner aside .condi-box .in-cnts ul.chk-li li label em {
  font-weight: bold;
}
.pjt-detail .inner aside .condi-box .in-cnts ul.s-list {
  font-size: 14px;
}
.pjt-detail .inner aside .condi-box .in-cnts ul.s-list li {
  margin-top: 16px;
}
.pjt-detail .inner aside .condi-box .in-cnts ul.s-list li i {
  font-weight: bold;
}
.pjt-detail .inner aside .condi-box .in-cnts ul.s-list li p {
  margin-top: 10px;
  color: rgba(13, 18, 64, 0.6);
}
.pjt-detail .inner aside .condi-box .in-cnts .desc {
  margin-top: 16px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  line-height: 22px;
}
.pjt-detail .inner aside .condi-box .in-cnts .desc em {
  font-weight: bold;
}
.pjt-detail .inner aside .condi-box .in-cnts .in-btn {
  display: flex;
  margin-top: 16px;
}
.pjt-detail .inner aside .condi-box .in-cnts .in-btn a, .pjt-detail .inner aside .condi-box .in-cnts .in-btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
  height: 48px;
  margin: 0 4px;
  background: #5539ff;
  border-radius: 2px;
  color: #fff;
}
.pjt-detail .inner aside .condi-box .in-cnts .in-btn a:first-child, .pjt-detail .inner aside .condi-box .in-cnts .in-btn button:first-child {
  margin-left: 0;
}
.pjt-detail .inner aside .condi-box .in-cnts .in-btn a:last-child, .pjt-detail .inner aside .condi-box .in-cnts .in-btn button:last-child {
  margin-right: 0;
}
.pjt-detail .inner aside .condi-box .in-cnts .in-btn a:disabled, .pjt-detail .inner aside .condi-box .in-cnts .in-btn button:disabled {
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.4);
}
.pjt-detail .inner aside .condi-box .in-cnts .in-btn a.def, .pjt-detail .inner aside .condi-box .in-cnts .in-btn button.def {
  max-width: 100px;
  background-color: rgba(85, 57, 255, 0.05);
  color: #5539ff;
}
@media (min-width: 786px) {
  .pjt-detail .inner {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-bottom: 50px;
  }
  .pjt-detail .inner .pjt-info {
    flex: 1;
  }
  .pjt-detail .inner aside {
    position: sticky;
    top: 100px;
    min-width: 292px;
    max-width: 292px;
    margin-left: auto;
    padding-left: 40px;
  }
  .pjt-detail .inner aside .btn-sticky {
    margin-top: 16px;
  }
}

.message-wrap {
  height: 100%;
  position: relative;
  top: 74px;
  background-color: rgba(85, 57, 255, 0.03);
}
.message-wrap .prog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 64px;
  height: 34px;
  background-color: rgba(85, 57, 255, 0.03);
  border-radius: 2px;
  color: #5539ff;
  font-size: 12px;
  font-weight: bold;
}
.message-wrap .prog.ing {
  background-color: rgba(85, 57, 255, 0.1);
}
.message-wrap .prog.ok {
  background-color: #e0fe52;
  color: #0d1240;
}
.message-wrap .prog.rej {
  background-color: rgba(13, 18, 64, 0.07);
  color: #000;
}
.message-wrap .per-list {
  display: flex;
  flex-direction: column;
}
.message-wrap .per-list .tit {
  padding: 16px 28px;
  background: #fff;
  border-bottom: 1px solid rgba(85, 57, 255, 0.05);
}
.message-wrap .per-list .tit h2 {
  font-size: 22px;
  font-weight: normal;
}
.message-wrap .per-list .tit > p {
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
}
.message-wrap .per-list > ul {
  overflow: auto;
  height: calc(100% - 182px);
  padding: 0 24px;
  background: #fff;
}
.message-wrap .per-list > ul li {
  position: relative;
  padding: 16px 0;
  border-bottom: 1px solid rgba(85, 57, 255, 0.05);
}
.message-wrap .per-list > ul li a {
  display: block;
}
.message-wrap .per-list > ul li a .new {
  display: block;
  position: absolute;
  bottom: 31px;
  right: 0;
  width: 8px;
  height: 8px;
  margin-top: 15px;
  background: #ff4e16;
  border-radius: 50%;
}
.message-wrap .per-list > ul li a .new span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.message-wrap .per-list > ul li a > em {
  color: rgba(13, 18, 64, 0.4);
  font-weight: bold;
  font-size: 12px;
}
.message-wrap .per-list > ul li a > div {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.message-wrap .per-list > ul li a > div .photo {
  min-width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 50%;
}
.message-wrap .per-list > ul li a > div .info {
  padding-left: 12px;
}
.message-wrap .per-list > ul li a > div .info strong {
  font-size: 14px;
}
.message-wrap .per-list > ul li a > div .info p {
  color: rgba(13, 18, 64, 0.4);
  font-size: 14px;
  line-height: 22px;
}
.message-wrap .per-list > ul li a .prog {
  position: absolute;
  right: 24px;
  bottom: 19px;
}
.message-wrap .per-list > ul li a .time {
  position: absolute;
  top: 16px;
  right: 0;
  color: rgba(13, 18, 64, 0.4);
  font-size: 12px;
}
.message-wrap .per-list > ul li.reject {
  opacity: 0.3;
}
.message-wrap .per-list > ul li.reject a {
  color: rgba(13, 18, 64, 0.5);
}
.message-wrap .per-list .msg-guide {
  padding: 16px;
  background-color: rgba(13, 18, 64, 0.02);
  color: rgba(13, 18, 64, 0.6);
}
.message-wrap .per-list .msg-guide h4 {
  font-size: 11px;
  font-weight: normal;
  text-align: center;
}
.message-wrap .per-list .msg-guide ul {
  padding-top: 8px;
  font-size: 9px;
  line-height: 16px;
}
.message-wrap .per-list .msg-guide ul li {
  margin-top: 4px;
}
.message-wrap .mesg-box {
  width: 100vw;
  min-height: calc(100vh - 185px);
}
.message-wrap .mesg-box .pjt-tit {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  padding: 8px 24px 8px 8px;
  background: #fff;
  box-shadow: 0 1px 0 0 rgba(85, 57, 255, 0.05);
  box-sizing: border-box;
}
.message-wrap .mesg-box .pjt-tit .btn-prev {
  margin-right: 8px;
}
.message-wrap .mesg-box .pjt-tit .btn-prev a {
  display: block;
  width: 32px;
  height: 32px;
  background: url("../images/icon_arrow_back_def.svg") no-repeat 0 0;
}
.message-wrap .mesg-box .pjt-tit .btn-prev a span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.message-wrap .mesg-box .pjt-tit h3 {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 16px;
  font-size: 14px;
  text-overflow: ellipsis;
}
.message-wrap .mesg-box .pjt-tit a {
  margin-left: auto;
  padding-left: 8px;
  color: #5539ff;
  font-size: 14px;
  text-decoration: underline;
  white-space: nowrap;
}
@media (min-width: 786px) {
  .message-wrap .mesg-box .pjt-tit .btn-prev {
    display: none;
  }
}
.message-wrap .mesg-box .no-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 190px);
}
.message-wrap .mesg-box .no-list:before {
  content: "";
  width: 120px;
  height: 120px;
  background: url("../images/img_nolist.png") no-repeat 0 0;
  background-size: cover;
}
.message-wrap .mesg-box .no-list p {
  margin-top: 16px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 16px;
}
.message-wrap.sub-w {
  padding: 0;
}
.message-wrap.sub-w .per-list {
  display: none;
}
.message-wrap.sub-w .mesg-box .hgroup {
  position: relative;
  min-height: 48px;
  padding: 11px 0;
  background: #5539ff;
  text-align: center;
  box-sizing: border-box;
}
.message-wrap.sub-w .mesg-box .hgroup .btn-prev {
  position: absolute;
  top: 4px;
  left: 24px;
}
.message-wrap.sub-w .mesg-box .hgroup h3 {
  overflow: hidden;
  padding: 0 40px 0 70px;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.message-wrap.sub-w .mesg-box .mesg-cnts {
  position: relative;
  height: calc(100vh - 74px);
  padding: 66px 24px 105px;
  box-sizing: border-box;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner {
  overflow: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg {
  display: flex;
  margin-top: 16px;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg .photo {
  min-width: 32px;
  height: 32px;
  margin: 8px 8px 0 0;
  border-radius: 50%;
  background-size: cover;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg > div {
  flex: 1;
  position: relative;
  padding: 16px;
  background-color: rgba(85, 57, 255, 0.05);
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg > div em {
  font-weight: bold;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg > div p {
  margin-top: 8px;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg > div .time {
  position: absolute;
  top: 16px;
  right: 16px;
  color: rgba(13, 18, 64, 0.4);
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg:first-child {
  margin-top: 0;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg.comp > div {
  background: #5539ff;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg.comp > div a {
  color: #fff;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .inner .in-mesg.comp > div .time {
  color: rgba(255, 255, 255, 0.6);
}
.message-wrap.sub-w .mesg-box .mesg-cnts .no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .no-data:before {
  content: "";
  width: 120px;
  height: 120px;
  background: url("../images/img_nomesg.png") no-repeat 0 0;
  background-size: cover;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .no-data p {
  margin-top: 16px;
  color: rgba(13, 18, 64, 0.4);
  font-size: 16px;
}
.message-wrap.sub-w .mesg-box .mesg-cnts .no-data.start:before {
  background-image: url("../images/img_start.png");
}
.message-wrap.sub-w .mesg-box .mesg-cnts fieldset {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 24px;
  width: calc(100% - 48px);
  height: 70px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid rgba(13, 18, 64, 0.05);
  background-color: #fff;
  box-sizing: border-box;
}
.message-wrap.sub-w .mesg-box .mesg-cnts fieldset textarea {
  height: 100%;
  padding: 0;
  background: none;
  color: #0d1240;
  font-size: 14px;
  line-height: 22px;
}
.message-wrap.sub-w .mesg-box .mesg-cnts fieldset button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 58px;
  height: 38px;
  margin-left: 8px;
  background: #5539ff;
  border-radius: 2px;
  color: #fff;
}
.message-wrap.sub-w .mesg-box .hgroup + .mesg-cnts {
  height: calc(100vh - 128px);
}
@media (min-width: 786px) {
  .message-wrap {
    overflow: hidden;
    display: flex;
    top: 96px;
    max-width: 800px;
    height: calc(100vh - 120px);
    margin: 0 auto 24px;
    border-radius: 4px;
    border: 1px solid rgba(13, 18, 64, 0.1);
    box-sizing: border-box;
  }
  .message-wrap .per-list {
    min-width: 299px;
    background: #fff;
  }
  .message-wrap .mesg-box {
    border-left: 1px solid rgba(85, 57, 255, 0.05);
  }
  .message-wrap.sub-w {
    width: 100%;
  }
  .message-wrap.sub-w .per-list {
    display: flex;
    width: 299px;
  }
  .message-wrap.sub-w .per-list .tit {
    padding: 24px;
  }
  .message-wrap.sub-w .per-list > ul {
    overflow: auto;
    margin-top: 0;
    padding: 0;
  }
  .message-wrap.sub-w .per-list > ul li a {
    padding: 0 24px;
  }
  .message-wrap.sub-w .per-list > ul li a .new {
    right: 8px;
  }
  .message-wrap.sub-w .per-list > ul li a .time {
    right: 24px;
  }
  .message-wrap.sub-w .per-list > ul li.selected {
    background-color: rgba(85, 57, 255, 0.03);
  }
  .message-wrap.sub-w .mesg-box {
    flex: 1;
    min-height: auto;
  }
  .message-wrap.sub-w .mesg-box .hgroup {
    display: none;
  }
  .message-wrap.sub-w .mesg-box .mesg-cnts {
    position: relative;
    height: 100%;
  }
}

.pay-box {
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  background-color: rgba(13, 18, 64, 0.02);
}
.pay-box > div {
  width: 50%;
  padding: 16px 24px;
  color: rgba(13, 18, 64, 0.6);
  box-sizing: border-box;
}
.pay-box > div i {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
}
.pay-box > div em {
  font-size: 16px;
}
.pay-box > button {
  width: calc(100% - 48px);
  margin: 16px 24px;
  padding: 13px 24px;
  background: #5539ff;
  border-radius: 2px;
  color: #fff;
  text-align: center;
}
.pay-box > p {
  width: 100%;
  padding: 12px 0;
  font-size: 14px;
  text-align: center;
}
.pay-box > p a {
  color: #5539ff;
  text-decoration: underline;
}
@media (min-width: 786px) {
  .pay-box {
    align-items: center;
    flex-wrap: nowrap;
    height: 82px;
  }
  .pay-box > div {
    width: 200px;
  }
  .pay-box > button, .pay-box > p {
    width: auto;
    margin-left: auto;
  }
  .pay-box > p {
    padding-right: 24px;
  }
}

.pay-wrap {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 24px;
}
.pay-wrap .cnts-hgroup {
  margin-top: 24px;
}
@media (min-width: 786px) {
  .pay-wrap .cnts-hgroup {
    margin-top: 32px;
  }
}
.pay-wrap .pay-list {
  position: relative;
  /* .scroll {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 110px;
      height: 6px;
      border-radius: 3px;
      background-color: rgba(85, 57, 255, 0.4);
  } */
}
.pay-wrap .pay-list .viewport {
  overflow: hidden;
  overflow-x: auto;
  width: 100vw;
  margin-left: -24px;
  padding-left: 24px;
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.pay-wrap .pay-list .viewport::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.pay-wrap .pay-list table {
  width: 800px;
  margin-right: 24px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
}
.pay-wrap .pay-list table thead th {
  padding: 11px 0;
  color: #0d1240;
  font-size: 12px;
}
.pay-wrap .pay-list table tbody td {
  padding: 19px 16px 15px;
  background: rgba(13, 18, 64, 0.02);
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.6);
}
.pay-wrap .pay-list table tbody td .sbj strong {
  overflow: hidden;
  display: block;
  width: 152px;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}
.pay-wrap .pay-list table tbody td .sbj span {
  display: block;
  margin-top: 4px;
  color: #0d1240;
}
.pay-wrap .pay-list table tbody td .profile {
  display: flex;
  align-items: center;
}
.pay-wrap .pay-list table tbody td .profile .thumb {
  min-width: 24px;
  height: 24px;
  background-size: cover;
  border-radius: 50%;
}
.pay-wrap .pay-list table tbody td .profile .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}
.pay-wrap .pay-list table tbody td .profile .info em {
  overflow: hidden;
  width: 100px;
  color: #0d1240;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pay-wrap .pay-list table tbody td .profile .info span {
  margin-top: 4px;
  font-size: 12px;
}
.pay-wrap .pay-list table tbody td > em {
  color: #5539ff;
}
.pay-wrap .pay-list table tbody td.cate {
  text-align: center;
}
.pay-wrap .pay-list table tbody td.cate .condi {
  display: inline-block;
  min-width: 55px;
  padding: 8px 10px;
  border-radius: 2px;
  background-color: rgba(13, 18, 64, 0.02);
  box-sizing: border-box;
}
.pay-wrap .pay-list table tbody td.td-price {
  color: #0d1240;
  text-align: right;
}
.pay-wrap .pay-list table tbody td:first-child {
  padding-left: 24px;
  padding-right: 24px;
}
.pay-wrap .pay-list table tbody td:nth-child(2) {
  padding: 19px 0 15px;
}
.pay-wrap .pay-list table tbody tr.ing td {
  background: rgba(85, 57, 255, 0.03);
}
.pay-wrap .pay-list table tbody tr.ing td.cate .condi {
  color: #5539ff;
  background-color: rgba(85, 57, 255, 0.03);
}
@media (min-width: 786px) {
  .pay-wrap .pay-list {
    /* .scroll {
        display: none;
    } */
  }
  .pay-wrap .pay-list .viewport {
    width: auto;
    margin: 0;
    padding: 0;
  }
}
.pay-wrap .no-data {
  margin-top: 24px;
}
@media (min-width: 786px) {
  .pay-wrap .no-data {
    margin-top: 64px;
  }
}

.pjt-adm-detail {
  margin: 24px auto 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(13, 18, 64, 0.05);
}
.pjt-adm-detail .pjt-def-info {
  font-size: 16px;
  text-align: center;
  line-height: 26px;
}
.pjt-adm-detail .pjt-def-info:before {
  content: "";
  display: block;
  width: 160px;
  height: 160px;
  margin: 0 auto;
  background: url("../images/img_pjt_detail.png") no-repeat 0 0;
  background-size: cover;
}
.pjt-adm-detail .pjt-def-info strong {
  display: block;
  margin-top: 24px;
}
.pjt-adm-detail .pjt-def-info > a {
  display: block;
  height: 48px;
  margin-top: 24px;
  background: #5539ff;
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
  line-height: 48px;
}
.pjt-adm-detail .pjt-def-info.type2:before {
  background-image: url("../images/img_pjt_detail02.png");
}
.pjt-adm-detail .rec-info {
  margin-top: 24px;
  padding: 24px;
  background: rgba(13, 18, 64, 0.02);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(13, 18, 64, 0.4);
}
.pjt-adm-detail .rec-info ul li {
  position: relative;
  padding-left: 22px;
}
.pjt-adm-detail .rec-info ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 4px;
  height: 4px;
  background: rgba(13, 18, 64, 0.4);
  border-radius: 50%;
}
@media (min-width: 786px) {
  .pjt-adm-detail {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.poll-wrap {
  max-width: 500px;
  margin: 0 auto;
  padding: 110px 24px 120px;
}
.poll-wrap input[type=radio] + label,
.poll-wrap input[type=checkbox] + label {
  padding-left: 35px;
  color: #0d1240;
  font-size: 16px;
  line-height: 24px;
}
.poll-wrap input[type=radio] + label:before,
.poll-wrap input[type=checkbox] + label:before {
  width: 22px;
  height: 22px;
  border-width: 2px;
  border-color: #5539ff;
}
.poll-wrap input[type=radio]:checked + label:after {
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
}
.poll-wrap .p-step {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 240px;
  margin: 0 auto;
  font-size: 12px;
}
.poll-wrap .p-step strong, .poll-wrap .p-step span {
  width: 36px;
  position: relative;
  text-align: center;
}
.poll-wrap .p-step strong:after, .poll-wrap .p-step span:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin: 4px auto 0;
  border-radius: 50%;
  background: #e7e7ec;
  box-sizing: border-box;
}
.poll-wrap .p-step strong.check, .poll-wrap .p-step span.check {
  color: #5539ff;
}
.poll-wrap .p-step strong.check:after, .poll-wrap .p-step span.check:after {
  background: #5539ff;
}
.poll-wrap .p-step strong.check:before, .poll-wrap .p-step span.check:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 9px;
  left: 15px;
  z-index: 2;
  width: 6px;
  height: 3px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #fff;
  transform: rotate(-45deg);
}
.poll-wrap .p-step strong {
  color: #5539ff;
  font-weight: normal;
}
.poll-wrap .p-step strong:after {
  border: 2px solid #5539ff;
  background: #fff;
}
.poll-wrap .p-step:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: calc(50% - 111px);
  bottom: 7px;
  width: 223px;
  height: 6px;
  background: #e7e7ec;
}
.poll-wrap h2 {
  margin-top: 30px;
  font-size: 28px;
  font-weight: normal;
  text-align: center;
}
.poll-wrap .cnts {
  padding-top: 15px;
  line-height: 26px;
}
.poll-wrap .cnts p {
  margin-top: 15px;
}
.poll-wrap .cnts p em {
  color: #5539ff;
}
.poll-wrap .noti-box {
  margin-top: 30px;
  padding: 16px 14px;
  background-color: rgba(13, 18, 64, 0.02);
  font-size: 14px;
  line-height: 22px;
}
.poll-wrap .ques-li {
  position: relative;
  margin-top: 30px;
  line-height: 26px;
}
.poll-wrap .ques-li:before {
  content: "";
  display: block;
  width: 100px;
  height: 1px;
  margin: 0 auto;
  background-color: rgba(13, 18, 64, 0.1);
}
.poll-wrap .ques-li ul {
  margin-top: 40px;
}
.poll-wrap .ques-li ul li {
  margin-top: 25px;
}
.poll-wrap .ques-li ul li > p {
  font-weight: bold;
}
.poll-wrap .ques-li ul li .ans {
  display: flex;
  flex-direction: column;
  padding-top: 4px;
}
.poll-wrap .ques-li ul li .ans > span {
  width: auto;
  margin-top: 4px;
}
.poll-wrap .ques-li ul li .ans > span label > span {
  padding-left: 3px;
  color: rgba(13, 18, 64, 0.4);
}
.poll-wrap .ques-li ul li .ans.type2 {
  flex-direction: column;
}
.poll-wrap .ques-li ul li .ans.type2 > span {
  margin-top: 25px;
}
.poll-wrap .ques-li ul li .ans.type2 > span:first-child {
  margin-top: 0;
}
.poll-wrap .portfolio-input {
  position: relative;
  margin-top: 30px;
  padding-top: 20px;
  line-height: 26px;
}
.poll-wrap .portfolio-input:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 50px);
  width: 100px;
  height: 1px;
  margin: 0 auto;
  background-color: rgba(13, 18, 64, 0.1);
}
.poll-wrap .portfolio-input h3 {
  display: flex;
  align-items: center;
  color: #5539ff;
  font-size: 12px;
  font-weight: normal;
}
.poll-wrap .portfolio-input h3:before {
  content: "";
  display: block;
  width: 4px;
  height: 8px;
  margin-right: 5px;
  background: #5539ff;
  border-radius: 8px;
  transform: rotate(45deg);
}
.poll-wrap .portfolio-input .desc {
  font-size: 14px;
}
.poll-wrap .portfolio-input .fm-w {
  margin-top: 30px;
}
.poll-wrap .portfolio-input .fm-w .tit {
  display: block;
  padding-bottom: 8px;
  font-weight: bold;
}
.poll-wrap .portfolio-input .fm-w .sel-w {
  display: flex;
  margin-top: 20px;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w, .poll-wrap .portfolio-input .fm-w .sel-w .input-w,
.poll-wrap .portfolio-input .fm-w .sel-w > select {
  width: 50%;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w:first-child, .poll-wrap .portfolio-input .fm-w .sel-w .input-w:first-child,
.poll-wrap .portfolio-input .fm-w .sel-w > select:first-child {
  margin-right: 10px;
}
.poll-wrap .portfolio-input .fm-w .sel-w select {
  flex: 1;
  color: rgba(13, 18, 64, 0.4);
  font-size: 16px;
}
.poll-wrap .portfolio-input .fm-w .sel-w input:disabled {
  background-color: rgba(13, 18, 64, 0.02);
}
.poll-wrap .portfolio-input .fm-w .sel-w input.actv {
  color: #5539ff;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w {
  position: relative;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 15px;
  border-radius: 2px;
  background: rgba(85, 57, 255, 0.03) url("../images/icon_arrow.svg") no-repeat 100% 50%;
  color: rgba(13, 18, 64, 0.4);
  box-sizing: border-box;
  font-size: 16px;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w button.actv {
  color: #0d1240;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w .option-li {
  display: none;
  position: absolute;
  z-index: 5;
  top: 56px;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #5539ff;
  background: #fff;
  box-sizing: border-box;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w .option-li i {
  padding: 7px 0;
  color: rgba(13, 18, 64, 0.4);
  font-size: 12px;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w .option-li ul li {
  padding: 4px 0;
  font-size: 14px;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w .option-li ul li.selected {
  color: #5539ff;
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w .option-li ul li.etc {
  margin-top: 7px;
  padding-top: 12px;
  border-top: 1px solid rgba(13, 18, 64, 0.1);
}
.poll-wrap .portfolio-input .fm-w .sel-w .select-w.selected .option-li {
  display: block;
}
.poll-wrap .portfolio-input .fm-w .sel-w .input-w {
  position: relative;
}
.poll-wrap .portfolio-input .fm-w .sel-w .input-w .recmd-li {
  display: none;
  overflow: auto;
  position: absolute;
  top: 43px;
  left: 16px;
  width: 100%;
  height: 120px;
  max-width: 200px;
  padding: 8px;
  border-radius: 2px;
  box-shadow: 4px 8px 16px 0 rgba(13, 18, 64, 0.15);
  background-color: #fff;
  color: rgba(13, 18, 64, 0.6);
  font-size: 14px;
  box-sizing: border-box;
}
.poll-wrap .portfolio-input .fm-w .sel-w .input-w .recmd-li ul li {
  padding: 5px 8px;
  line-height: 140%;
}
.poll-wrap .portfolio-input .fm-w .sel-w .input-w .recmd-li ul li.selected {
  border-radius: 2px;
  background-color: rgba(85, 57, 255, 0.03);
}
.poll-wrap .portfolio-input .fm-w .sel-w .input-w .btn-del:before, .poll-wrap .portfolio-input .fm-w .sel-w .input-w .btn-del:after {
  background: #5539ff;
}
.poll-wrap .portfolio-input .fm-w .sel-w .input-w.selected .recmd-li {
  display: block;
}
.poll-wrap .portfolio-input .fm-w label + .sel-w {
  margin-top: 0;
}
.poll-wrap .portfolio-input .fm-w .chk-li {
  display: flex;
  flex-direction: column;
  line-height: 22px;
}
.poll-wrap .portfolio-input .fm-w .chk-li span {
  margin-top: 35px;
}
.poll-wrap .portfolio-input .fm-w .chk-li span input[type=checkbox] + label:before {
  top: 0;
}
.poll-wrap .portfolio-input .fm-w .chk-li span input[type=checkbox]:checked + label:after {
  left: 7px;
}
.poll-wrap .portfolio-input .fm-w .chk-li span:first-child {
  margin-top: 6px;
}
.poll-wrap .portfolio-input .fm-w .chk-w .inner {
  display: flex;
  margin-top: 10px;
}
.poll-wrap .portfolio-input .fm-w .chk-w .inner > label {
  flex: 1;
}
.poll-wrap .portfolio-input .fm-w .chk-w .inner > label span {
  overflow: hidden;
  display: inline-block;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  text-align: left;
}
.poll-wrap .portfolio-input .fm-w .chk-w .inner > label:before {
  top: 18px;
}
.poll-wrap .portfolio-input .fm-w .chk-w .inner > label.type-h {
  max-width: 0;
}
.poll-wrap .portfolio-input .fm-w .chk-w .inner input[type=radio]:checked + label:after {
  top: 22px;
}
.poll-wrap .portfolio-input .fm-w .chk-w .inner .file-w {
  flex: 1;
  position: relative;
}
.poll-wrap .portfolio-input .fm-w .chk-w .inner .file-w label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 10px;
  width: 107px;
  height: 48px;
  border-radius: 2px;
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.6);
}
.poll-wrap .portfolio-input .fm-w .chk-w > p {
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
  text-align: right;
}
.poll-wrap .portfolio-input .fm-w .link {
  margin-top: 15px;
  text-align: right;
}
.poll-wrap .portfolio-input .fm-w .link a {
  color: #5539ff;
  text-decoration: underline;
}
.poll-wrap .portfolio-input .fm-w textarea {
  height: 142px;
}
.poll-wrap .portfolio-input .fm-w .sel-form .opt-li {
  height: 120px;
}
.poll-wrap .portfolio-input .fm-w .comp-intu + .sel-form {
  margin-top: 20px;
}
.poll-wrap .portfolio-input .fm-w .int-form .file-up {
  background-color: rgba(13, 18, 64, 0.05);
  color: rgba(13, 18, 64, 0.6);
}
.poll-wrap .portfolio-input .fm-w .f-desc {
  margin-top: 8px;
  color: rgba(13, 18, 64, 0.6);
  font-size: 12px;
  text-align: right;
}
.poll-wrap .portfolio-input .fm-w .f-desc:before {
  content: "*";
}
.poll-wrap .portfolio-input .ques-li:before {
  display: none;
}
.poll-wrap .btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 24px 50px;
  box-sizing: border-box;
  background: #fff;
}
.poll-wrap .btn .sub {
  width: 93px;
}
.poll-wrap.end {
  padding-top: 135px;
}
.poll-wrap.end:before {
  content: "";
  display: block;
  width: 253px;
  height: 160px;
  margin: 0 auto;
  background: url("../images/poll_end.png") no-repeat 50% 50%;
  background-size: contain;
}
@media (min-width: 786px) {
  .poll-wrap input[type=radio] + label,
  .poll-wrap input[type=checkbox] + label {
    padding-left: 25px;
    font-size: 14px;
    line-height: 18px;
  }
  .poll-wrap input[type=radio] + label:before,
  .poll-wrap input[type=checkbox] + label:before {
    width: 16px;
    height: 16px;
    border-width: 1px;
  }
  .poll-wrap input[type=radio]:checked + label:after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
  }
  .poll-wrap .p-step {
    width: 210px;
  }
  .poll-wrap .p-step strong:after, .poll-wrap .p-step span:after {
    width: 16px;
    height: 16px;
  }
  .poll-wrap .p-step strong.check:before, .poll-wrap .p-step span.check:before {
    bottom: 7px;
    left: 14px;
  }
  .poll-wrap .p-step:after {
    left: calc(50% - 80px);
    bottom: 6px;
    width: 160px;
    height: 4px;
  }
  .poll-wrap h2 {
    margin-top: 40px;
    font-size: 22px;
  }
  .poll-wrap h2 br {
    display: none;
  }
  .poll-wrap .cnts {
    line-height: 22px;
  }
  .poll-wrap .cnts.type2 p ~ p {
    margin-top: 0;
  }
  .poll-wrap .noti-box {
    font-size: 12px;
    line-height: 18px;
  }
  .poll-wrap .ques-li {
    line-height: 22px;
  }
  .poll-wrap .portfolio-input .fm-w .sel-w select, .poll-wrap .portfolio-input .fm-w .sel-w .select-w button {
    font-size: 14px;
  }
  .poll-wrap .portfolio-input .fm-w .chk-li {
    line-height: 18px;
  }
  .poll-wrap .portfolio-input .fm-w .chk-li span {
    margin-top: 13px;
  }
  .poll-wrap .portfolio-input .fm-w .chk-li span input[type=checkbox]:checked + label:after {
    top: 3px;
    left: 4px;
  }
  .poll-wrap .portfolio-input .fm-w textarea {
    padding: 17px 16px;
    font-size: 14px;
  }
  .poll-wrap .portfolio-input .fm-w .chk-w .inner input[type=radio]:checked + label:after {
    top: 21px;
  }
  .poll-wrap .portfolio-input .fm-w .chk-w .inner .file-w label {
    top: 8px;
    width: 92px;
    height: 40px;
  }
  .poll-wrap .btn {
    position: static;
    width: 300px;
    margin: 40px auto 0;
    padding: 0;
  }
  .poll-wrap .btn a.sub {
    min-width: 190px;
  }
  .poll-wrap .btn.type2 {
    width: 100%;
  }
  .poll-wrap.end {
    padding-top: 155px;
  }
  .poll-wrap.end:before {
    width: 299px;
    height: 188px;
  }
  .poll-wrap.end .cnts p ~ p {
    margin-top: 0;
  }
}

.recommend-w {
  padding-bottom: 28px;
  color: #26262A;
  font-family: "Pretendard", "SFUIDisplay", "NotoSansCJKkr", "Roboto", "Droid Sans", "Malgun Gothic", "Helvetica", "Apple-Gothic", "애플고딕", "Tahoma", dotum, "돋움", gulim, "굴림", sans-serif;
  -webkit-font-smoothing: antialiased;
}
.recommend-w h3, .recommend-w a {
  color: #26262A;
}
.recommend-w strong {
  font-weight: 700;
}
@media (min-width: 786px) {
  .recommend-w {
    padding-bottom: 105px;
  }
}
.recommend-w .top-v {
  padding: 90px 30px 40px;
  background: #F5EBFF;
}
.recommend-w .top-v .inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
}
.recommend-w .top-v .inner:before {
  content: "";
  display: block;
  width: 148px;
  height: 148px;
  background: url("../images/recomd_img.png") no-repeat 50% 50%;
  background-size: contain;
}
.recommend-w .top-v .inner p {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.recommend-w .top-v .inner p br {
  display: none;
}
.recommend-w .top-v .inner a {
  display: inline-block;
  padding: 12px 16px;
  background: #5539ff;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 20px;
}
@media (min-width: 786px) {
  .recommend-w .top-v .inner a {
    padding: 16px 20px;
    font-size: 20px;
  }
}
@media (min-width: 786px) {
  .recommend-w .top-v {
    padding-top: 130px;
    padding-bottom: 70px;
  }
  .recommend-w .top-v .inner {
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
  .recommend-w .top-v .inner:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 216px;
    height: 216px;
  }
  .recommend-w .top-v .inner p {
    font-size: 28px;
    line-height: 40px;
  }
  .recommend-w .top-v .inner p br {
    display: inline;
  }
}
.recommend-w .progrs {
  padding: 30px 30px 0;
}
.recommend-w .progrs h3 {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 28px;
}
.recommend-w .progrs h3:before {
  content: "✍";
  display: block;
  margin-bottom: 3px;
  font-size: 36px;
}
.recommend-w .progrs ul {
  padding-top: 10px;
}
.recommend-w .progrs ul li {
  margin-top: 30px;
}
.recommend-w .progrs ul li strong {
  font-weight: 700;
}
.recommend-w .progrs ul li p {
  margin-top: 12px;
  color: #494949;
  font-size: 14px;
  line-height: 24px;
}
.recommend-w .progrs ul li p em {
  font-weight: 700;
}
@media (min-width: 786px) {
  .recommend-w .progrs {
    padding-top: 60px;
  }
  .recommend-w .progrs .inner {
    display: flex;
    max-width: 1024px;
    margin: 0 auto;
  }
  .recommend-w .progrs .inner h3 {
    width: 360px;
    font-size: 28px;
    line-height: 40px;
  }
  .recommend-w .progrs .inner h3:before {
    font-size: 48px;
    margin-bottom: 8px;
  }
  .recommend-w .progrs .inner ul {
    flex: 1;
    max-width: 560px;
  }
  .recommend-w .progrs .inner ul li {
    margin-top: 40px;
  }
  .recommend-w .progrs .inner ul li strong {
    font-size: 18px;
  }
  .recommend-w .progrs .inner ul li p {
    margin-top: 12px;
    font-size: 16px;
    line-height: 28px;
  }
  .recommend-w .progrs .inner ul li:first-child {
    margin-top: 0;
  }
}
.recommend-w .faq-w {
  padding: 60px 30px 0;
}
.recommend-w .faq-w h3 {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 28px;
}
.recommend-w .faq-w h3:before {
  content: "📃";
  display: block;
  margin-bottom: 3px;
  font-size: 36px;
}
.recommend-w .faq-w ul {
  padding-top: 28px;
  font-size: 14px;
}
.recommend-w .faq-w ul li {
  margin-top: 12px;
  padding: 16px 24px;
  border: 1px solid #E2E3E6;
  border-radius: 20px;
}
.recommend-w .faq-w ul li > a {
  display: block;
  position: relative;
  padding: 10px 30px 10px 0;
  font-weight: 700;
  line-height: 20px;
}
.recommend-w .faq-w ul li > a:after {
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  right: 0;
  width: 9px;
  height: 9px;
  border-width: 0 0 3px 3px;
  border-style: solid;
  border-color: #323232;
  border-radius: 2px;
  box-sizing: border-box;
  transform: rotate(-45deg);
}
.recommend-w .faq-w ul li > div {
  display: none;
  margin-top: 2px;
  color: #494949;
  line-height: 24px;
}
.recommend-w .faq-w ul li.selected > a:after {
  top: 17px;
  transform: rotate(135deg);
}
.recommend-w .faq-w ul li.selected > div {
  display: block;
}
@media (min-width: 786px) {
  .recommend-w .faq-w {
    padding-top: 130px;
  }
  .recommend-w .faq-w .inner {
    display: flex;
    max-width: 1024px;
    margin: 0 auto;
  }
  .recommend-w .faq-w .inner h3 {
    width: 360px;
    font-size: 28px;
    line-height: 40px;
  }
  .recommend-w .faq-w .inner h3:before {
    font-size: 48px;
    margin-bottom: 8px;
  }
  .recommend-w .faq-w .inner ul {
    flex: 1;
    padding-top: 0;
    font-size: 16px;
  }
  .recommend-w .faq-w .inner ul li {
    margin-top: 16px;
    padding: 19px 32px;
  }
  .recommend-w .faq-w .inner ul li div {
    line-height: 28px;
  }
  .recommend-w .faq-w .inner ul li:first-child {
    margin-top: 0;
  }
}

